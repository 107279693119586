import React, { ReactNode } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useNotify } from "react-admin";

type MediaStatusAlertProps = {
  ready: boolean;
  error?: string;
  desc: string | JSX.Element;
  icon?: ReactNode;
  openLinks: Array<
    | { label: string; url: string; onClick?: undefined }
    | { label: string; url?: undefined; onClick: () => void }
  >;
  actions: Array<{ label: string; onClick: () => Promise<void> | void }>;
};

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    minHeight: "85px",
    marginTop: theme.spacing(2),
    borderRadius: 15
  },
  link: {
    marginLeft: 10
  }
}));

const ActionButton = ({
  label,
  onClick
}: {
  label: string;
  onClick: () => Promise<void> | void;
}) => {
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  const onClickWrapped = () => {
    const onClickResult = onClick();
    if (onClickResult instanceof Promise) {
      setLoading(true);
      onClickResult
        .catch(e => {
          notify(`${label} failed: ${e.message || e.toString()}`, "error");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Button
      key={label}
      onClick={onClickWrapped}
      disabled={loading}
      variant="outlined"
      size="small"
      endIcon={loading ? <CircularProgress size={20} /> : null}
    >
      {label}
    </Button>
  );
};

const MediaStatusAlert = ({
  ready,
  error,
  desc,
  openLinks,
  actions,
  icon
}: MediaStatusAlertProps) => {
  const classes = useStyles();
  return (
    <Alert
      icon={icon}
      severity={ready ? "success" : !error ? "info" : "error"}
      className={classes.alert}
      action={
        <Box display="flex" flexDirection="column">
          {actions.map(({ label, onClick }, index) => (
            <ActionButton key={index} label={label} onClick={onClick} />
          ))}
        </Box>
      }
    >
      <AlertTitle>{ready ? "Ready" : "Not Ready"}</AlertTitle>
      <Typography variant="body2">{desc}</Typography>
      {ready && openLinks?.length > 0 ? (
        <Box display="flex" flexDirection="row" alignItems="center">
          Open:{" "}
          {openLinks.map(({ label, url, onClick }) => (
            <Link
              key={label}
              href={url}
              onClick={onClick}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {label}
            </Link>
          ))}
        </Box>
      ) : null}
    </Alert>
  );
};

export default MediaStatusAlert;
