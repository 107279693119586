import {
  getGridStringOperators,
  GridFilterItem,
  GridFilterModel
} from "@mui/x-data-grid";

export const operatorParamsToModel = new Map([["contains", "contains"]]);

export const operatorModelToParams = new Map(
  Array.from(operatorParamsToModel, entry => [entry[1], entry[0]])
);

export const operatorTest = /(.+)_(\w+)$/;

export const defaultConvertFilterValuesToFilterModel = (
  filterValues: any
): GridFilterItem[] =>
  Object.keys(filterValues).map(name => {
    const match = name.match(operatorTest);
    if (!match) {
      return {
        columnField: name,
        operatorValue: "equals",
        value: filterValues[name]
      };
    }
    const [, realName, operator] = match;
    if (operatorParamsToModel.has(operator)) {
      return {
        columnField: realName,
        operatorValue: operatorParamsToModel.get(operator),
        value: filterValues[name]
      };
    } else {
      return {
        columnField: name,
        operatorValue: "equals",
        value: filterValues[name]
      };
    }
  });

export const defaultConvertFilterModelToFilterValues = (
  filterModel: GridFilterModel
): any =>
  filterModel.items.reduce<any>((acc, item) => {
    if (typeof item.columnField !== "undefined" && item.operatorValue) {
      acc[item.columnField] = item.value;
    }
    return acc;
  }, {});

export const simpleFilterOperator = getGridStringOperators().filter(
  (operator: any) => operator.value === "contains"
);
