import React from "react";
import {
  IUserMediaItem,
  IUserMediaStatus,
  VideoSearchResult
} from "@booyaltd/core";
import { Avatar, Box } from "@material-ui/core";
import MediaStatusAlert from "./MediaStatusAlert";

type ThumbManagerMediaItemSummaryProps = {
  video: VideoSearchResult;
  mediaItem: IUserMediaItem | undefined;
  onBeginUpload: () => void;
};

const mediaItemStatusMessage = (status?: IUserMediaStatus): string => {
  switch (status) {
    case "error":
    case undefined:
      return "Previous upload failed. Please try again.";
    case "pending-upload":
    case "processing":
      return "Processing uploaded image";
    case "ready":
      return "Image ready for use";
    case "deleted":
      return "Deleted";
    default:
      return "";
  }
};

const ThumbManagerMediaItemSummary = ({
  video,
  mediaItem,
  onBeginUpload
}: ThumbManagerMediaItemSummaryProps) => {
  const { thumbnail, thumbnailThumb } = video;

  const isUploaded = mediaItem?.status === "ready";

  return (
    <Box display="flex" flexDirection="column" mt={1}>
      <MediaStatusAlert
        icon={
          isUploaded && thumbnailThumb ? (
            <Avatar src={thumbnailThumb} />
          ) : (
            undefined
          )
        }
        ready={isUploaded}
        desc={mediaItemStatusMessage(mediaItem?.status)}
        openLinks={
          isUploaded && thumbnail && thumbnailThumb
            ? [
                { label: "Small", url: thumbnailThumb },
                { label: "Large", url: thumbnail }
              ]
            : []
        }
        actions={[
          {
            label: mediaItem ? "Re-Upload" : "Upload",
            onClick: onBeginUpload
          }
        ]}
        error={
          mediaItem?.status === "error"
            ? mediaItem.statusReason || "Unknown Error"
            : undefined
        }
      />
    </Box>
  );
};

export default ThumbManagerMediaItemSummary;
