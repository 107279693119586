import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  DateField,
  ReferenceField,
  FunctionField
} from "react-admin";
import icon from "@material-ui/icons/LocalOffer";
import {
  NumberGreaterEqualFilter,
  NumberLessEqualFilter,
  TextContainsFilter
} from "../components/filters/TextFilters";
import { UserProfile } from "@booyaltd/core";

const VideoSearchFilter = (props: {}) => (
  <Filter {...props}>
    <TextContainsFilter label="Phrase" source="search.phrase" alwaysOn />
    <NumberGreaterEqualFilter label="Hits >=" source="hits" alwaysOn />
    <NumberLessEqualFilter label="Hits <=" source="hits" alwaysOn />
  </Filter>
);

export const VideoSearchDataGrid = () => (
  <Datagrid>
    <ReferenceField label="User" source="userId" reference="member" link="show">
      <FunctionField<UserProfile>
        source="firstName"
        sortable={false}
        render={(record?: UserProfile) => (
          <Fragment>
            {record ? `${record.firstName} ${record.lastName}` : ""}
          </Fragment>
        )}
      />
    </ReferenceField>
    <TextField source="phrase" sortable={false} />
    <TextField source="region" sortable={false} />

    <ReferenceField
      label="Channel"
      source="channelId"
      reference="channel"
      link="show"
    >
      <TextField source="name" sortable={false} />
    </ReferenceField>

    <TextField source="hits" sortable={false} />
    <DateField source="created" />
  </Datagrid>
);

export const VideoSearchList = (props: {}) => {
  return (
    <Fragment>
      <List
        filters={<VideoSearchFilter />}
        sort={{ field: "created", order: "DESC" }}
        perPage={25}
        bulkActionButtons={false}
        {...props}
      >
        <VideoSearchDataGrid />
      </List>
    </Fragment>
  );
};

export const VideoSearchIcon = icon;
