import Provider from "../Provider";

export default class EmailDataProvider extends Provider {
  testEmailUrl(): string {
    return "/auth/staff/test-email";
  }

  sendEmail(email: string, template: string): Promise<any> {
    return this.axios
      .post(this.testEmailUrl(), { email: email, template: template })
      .then(response => ({ data: response.data }));
  }
}
