import { useField } from "react-final-form";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

export type DateInputProps = {
  label: string;
  source: string;
  defaultValue?: Date;
  emptyLabel?: string;
  onChangeAdditional?: (event: React.ChangeEvent<Date> | any) => void;
  fullWidth?: boolean;
  dateOnly?: boolean;
};

const DateInput = ({
  source,
  label,
  emptyLabel,
  onChangeAdditional,
  dateOnly,
  ...props
}: DateInputProps) => {
  const {
    input: { onChange: onChangeValue, value }
  } = useField<Date>(source);

  const onChange = (event: React.ChangeEvent<Date> | any) => {
    onChangeValue(event);

    if (onChangeAdditional) {
      onChangeAdditional(event);
    }
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {dateOnly ? (
          <DatePicker
            clearable
            label={label}
            value={value ? value : null}
            emptyLabel={emptyLabel}
            onChange={onChange}
            {...props}
          />
        ) : (
          <DateTimePicker
            clearable
            label={label}
            value={value ? value : null}
            emptyLabel={emptyLabel}
            onChange={onChange}
            {...props}
          />
        )}
      </MuiPickersUtilsProvider>
    </>
  );
};

export default DateInput;
