import { PlayerProps } from "./types";
import React, { useCallback, useRef } from "react";
import throttle from "lodash/throttle";

// @ts-ignore
import ReactPlayerLoader from "@brightcove/react-player-loader";
import videojs from "video.js";

const BrightcovePlayer = ({
  video,
  type,
  onStart: onStartProp,
  onProgress: onProgressProp
}: PlayerProps) => {
  const [started, setStarted] = React.useState(false);
  const innerPlayerRef = useRef<any>();
  const { id, progress } = video;

  const onLoaded = useCallback(() => {
    if (!innerPlayerRef.current?.player || !progress) return;

    console.log("onLoaded", progress);

    innerPlayerRef.current?.player?.currentTime(parseFloat(String(progress)));
  }, [innerPlayerRef, progress]);

  const onStart = useCallback(() => {
    if (!id || !onStartProp || started) {
      return;
    }

    setStarted(true);
    onStartProp();
  }, [id, onStartProp, started]);

  const onProgress = useCallback(
    throttle(
      (ref: any) => {
        if (!onProgressProp || !ref?.target?.player) return;

        const currentTime = ref?.target?.player?.currentTime?.();
        if (currentTime) {
          onProgressProp(currentTime);
          return;
        }
      },
      2000,
      { leading: true, trailing: true }
    ),
    []
  );

  const onLoadBrightcoveVideo = (evt: { ref: videojs.Player }) => {
    const player = evt.ref;

    if (!player) return;

    player.off("timeupdate", onProgress);
    player.off("loadstart", onLoaded);
    player.off("play", onStart);

    player.on("timeupdate", onProgress);
    player.on("loadstart", onLoaded);
    player.on("play", onStart);
  };

  return (
    <ReactPlayerLoader
      ref={innerPlayerRef}
      accountId="6016595164001"
      playerId="ApRmbQKX3Y"
      videoId={
        type === "master" ? video.masterProviderId : video.previewProviderId
      }
      onSuccess={onLoadBrightcoveVideo}
      embedOptions={{ responsive: true }}
    />
  );
};

export default BrightcovePlayer;
