import React, { PropsWithChildren } from "react";
import {
  Create,
  Edit,
  CreateProps,
  EditProps,
  FormWithRedirect,
  SelectInput,
  required,
  SaveButton,
  DeleteButton
} from "react-admin";
import { Box, Toolbar, Typography } from "@material-ui/core/";
import DateInput from "../../components/inputs/DateInput";
import TemplateInput from "../../components/inputs/TemplateInput";
import TemplateDataInput from "../../components/inputs/TemplateDataInput";
import MemberSegmentInput from "../../components/inputs/MemberSegmentInput";

const ScheduledNotificationForm = (props: {} = {}) => (
  <FormWithRedirect
    {...props}
    render={(formProps: any) => {
      return (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Typography variant="h6" gutterBottom>
                Scheduled Notification
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" mb="1em">
              <Box pt="0.75em" display="flex" flexDirection="row">
                <SelectInput
                  source="type"
                  validate={[required()]}
                  choices={[
                    { id: "email", name: "Email" },
                    { id: "push", name: "Push" },
                    { id: "sms", name: "SMS" }
                  ]}
                />
                <TemplateInput />
              </Box>
              <Box>
                <MemberSegmentInput />
              </Box>
              <Box>
                <TemplateDataInput />
              </Box>
              <Box>
                <DateInput source="queueTime" label="Queue Time" />
                <DateInput source="scheduledTime" label="Scheduled Time" />
              </Box>
            </Box>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect="list"
                />
                <DeleteButton
                  record={formProps.record}
                  resource={formProps.resource}
                />
              </Box>
            </Toolbar>
          </Box>
        </form>
      );
    }}
  />
);

export const ScheduledNotificationCreate = (
  props: PropsWithChildren<CreateProps>
) => (
  <Create {...props}>
    <ScheduledNotificationForm />
  </Create>
);

export const ScheduledNotificationEdit = (
  props: PropsWithChildren<EditProps>
) => (
  <Edit {...props}>
    <ScheduledNotificationForm />
  </Edit>
);
