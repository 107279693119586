import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  DateField,
  DeleteButton,
  Edit,
  SaveButton,
  SelectInput,
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  Filter,
  BooleanInput,
  FormWithRedirect,
  ReferenceInput,
  ReferenceField,
  FunctionField,
  BulkDeleteButton,
  ResourceComponentPropsWithId
} from "react-admin";

import icon from "@material-ui/icons/CardGiftcard";

import { Giftcard } from "../entities/Giftcard";
import { Box, Typography, Toolbar } from "@material-ui/core";
import { Product } from "../entities/Subscription";
import { Profile } from "../entities/Profile";

// Giftcard Title
const GiftcardTitle = ({ record }: { record?: Giftcard }) => {
  return (
    <span>
      Giftcard:{" "}
      {record && record.giftcardCode
        ? `${record.giftcardCode}`
        : "Create Giftcard"}
    </span>
  );
};

// Giftcard Filters
const GiftcardFilter = (props: {}) => (
  <Filter {...props}>
    <TextInput label="Search for Code" source="giftcardCode" alwaysOn />
    <TextInput label="Shopify Reference" source="shopifyReference" alwaysOn />
    <TextInput label="Notes" source="notes" alwaysOn />
    <SelectInput
      alwaysOn
      label="Status"
      source="status"
      choices={[
        { id: "Generated", name: "Generated" },
        { id: "Sent_To_Printer", name: "Sent to Printer" },
        { id: "Cancelled", name: "Cancelled" },
        { id: "Sold_Wholesale", name: "Sold Wholesale" },
        { id: "Sold_Shopify", name: "Sold via Shopify" },
        { id: "Redeemed", name: "Redeemed" },
        { id: "Expired", name: "Expired" }
      ]}
    />
    <ReferenceInput
      label="Plan"
      source="productId"
      reference="product"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const PostBulkActionButtons = (props: {}) => (
  <Fragment>
    <BulkDeleteButton {...props} />
  </Fragment>
);

// @ts-ignore
export const GiftcardList = (props: {}) => (
  <List
    filters={<GiftcardFilter />}
    perPage={50}
    bulkActionButtons={<PostBulkActionButtons />}
    filterDefaultValues={{ status: "generated" }}
    {...props}
  >
    <Datagrid>
      <TextField source="giftcardCode" label="Giftcard" />
      <ReferenceField
        label="Plan"
        source="productId"
        reference="product"
        linkType="show"
        sortable={false}
      >
        <TextField label="Plan" source="name" />
      </ReferenceField>
      <ReferenceField
        label="Value"
        source="productId"
        reference="product"
        linkType="show"
        sortable={false}
      >
        <FunctionField<Product>
          label="Value"
          render={(product?: Product) =>
            `${product?.currency} ${product?.cost}`
          }
        />
      </ReferenceField>
      <TextField source="status" label="Status" />

      <TextField label="Shopify Reference" source="shopifyReference" />

      <ReferenceField
        label="Redeemed By"
        source="redeemedUserId"
        reference="member"
        linkType="show"
        sortable={false}
      >
        <FunctionField<Profile>
          label="Name"
          render={(profile?: Profile) =>
            `${profile?.firstName} ${profile?.lastName}`
          }
        />
      </ReferenceField>

      <DateField source="redeemedOn" />

      <DateField showTime={false} source="expiryDate" />

      <TextField source="notes" label="Notes" />
      {/* <DateField source="created" />
      <DateField source="updated" /> */}
      <EditButton />
    </Datagrid>
  </List>
);

export const GiftcardEdit = (props: ResourceComponentPropsWithId) => {
  return (
    <Edit {...props} title={<GiftcardTitle />}>
      <SimpleForm>
        <TextField label="Giftcard Code" source="giftcardCode" />
        <ReferenceField
          label="Plan"
          source="productId"
          reference="product"
          // @ts-ignore
          sort={{ field: "name", order: "ASC" }}
        >
          <TextField source="name" />
        </ReferenceField>
        <SelectInput
          source="status"
          choices={[
            { id: "Generated", name: "Generated" },
            { id: "Sent_To_Printer", name: "Sent to Printer" },
            { id: "Cancelled", name: "Cancelled" },
            { id: "Sold_Wholesale", name: "Sold Wholesale" },
            { id: "Sold_Shopify", name: "Sold via Shopify" },
            { id: "Redeemed", name: "Redeemed" },
            { id: "Expired", name: "Expired" }
          ]}
        />
        <TextInput
          label="Shopify Reference"
          source="shopifyReference"
          maxLength={255}
        />
        <TextInput
          multiline
          label="Notes"
          source="notes"
          maxLength={1000}
          rows={4}
        />
      </SimpleForm>
    </Edit>
  );
};

export const GiftcardCreate = (props: {}) => (
  <Create {...props} title={<GiftcardTitle />}>
    <GiftcardForm />
  </Create>
);

const GiftcardForm = (props: any = {}) => (
  <FormWithRedirect
    {...props}
    render={(formProps: any = {}) => {
      return (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h5" gutterBottom>
                  Create Giftcard(s)
                </Typography>
              </Box>
            </Box>

            <Typography variant="h6">Membership Duration</Typography>

            <Box display="flex" mb="1em">
              <ReferenceInput
                label="Product"
                source="productId"
                reference="product"
                sort={{ field: "name", order: "ASC" }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Box>

            <Typography variant="h6">Quantity</Typography>

            <Box display="flex" mb="1em">
              <Box flex={0.5}>
                <NumberInput
                  label="Quantity"
                  fullWidth
                  initialValue="1"
                  source="quantity"
                  helperText={"Quantity to create"}
                />
              </Box>
              <Box flex={0.5} ml="1em" pt="0.75em">
                <BooleanInput
                  label="Active"
                  source="active"
                  defaultValue={true}
                  fullWidth
                />
              </Box>
            </Box>

            <Typography variant="h6">Notes</Typography>

            <Box display="flex" mb="1em">
              <Box flex={1}>
                <TextInput label="Notes" source="notes" fullWidth />
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                redirect="list"
              />
              <DeleteButton
                record={formProps.record}
                resource={formProps.resource}
              />
            </Box>
          </Toolbar>
        </form>
      );
    }}
  />
);

export const GiftcardIcon = icon;
