import React, { cloneElement, ReactElement } from "react";
import { usePermissions } from "react-admin";
import { Role } from "../../providers/auth/AuthProvider";

const PermissionGuard = ({
  role = "admin",
  children,
  ...props
}: {
  role: Role | Role[];
  children: ReactElement;
}) => {
  const { permissions } = usePermissions();

  const hasPermission = Array.isArray(role)
    ? role.some(r => permissions.includes(r))
    : permissions === role;

  if (!hasPermission) {
    return null;
  }

  return <>{cloneElement(children, props)}</>;
};

export default PermissionGuard;
