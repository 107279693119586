import React from "react";
import { Edit, SimpleForm } from "react-admin";
import InboxNotificationForm, {
  InboxNotificationTitle
} from "./InboxNotificationForm";

const InboxNotificationEdit = (props: any) => (
  <Edit {...props} title={<InboxNotificationTitle />}>
    <SimpleForm resource="inbox-notification" redirect="list">
      <InboxNotificationForm />
    </SimpleForm>
  </Edit>
);

export default InboxNotificationEdit;
