import React, { useEffect, useMemo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  Identifier,
  useDataProvider,
  useGetList,
  usePermissions
} from "react-admin";
import dayjs, { Dayjs } from "dayjs";
import { UserProfileSegmentSnapshot } from "@booyaltd/core";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer
} from "victory";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";

// eslint-disable-next-line
export default () => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes("admin");

  const [dateRangeFrom, setDateRangeFrom] = React.useState<Dayjs>(
    dayjs()
      .subtract(1, "month")
      .startOf("day")
  );
  const [dateRangeTo, setDateRangeTo] = React.useState<Dayjs>(
    dayjs().endOf("day")
  );

  const [
    selectedProfileSegmentId,
    setSelectedProfileSegmentId
  ] = React.useState<Identifier>();
  const [selectedLoading, setSelectedLoading] = React.useState(false);
  const [latestSegmentSnapshots, setLatestSegmentSnapshots] = React.useState<
    UserProfileSegmentSnapshot[]
  >();
  const [
    selectedSegmentSnapshots,
    setSelectedSegmentSnapshots
  ] = React.useState<UserProfileSegmentSnapshot[]>();
  const dataProvider = useDataProvider();

  const {
    data: profileSegments,
    ids: profileSegmentIds,
    loading: loadingSegments
  } = useGetList(
    "profile-segment",
    { page: 1, perPage: 100 },
    { field: "name", order: "ASC" },
    undefined,
    { enabled: isAdmin }
  );

  useEffect(() => {
    if (!profileSegmentIds || profileSegmentIds.length <= 0) {
      return;
    }

    setSelectedLoading(true);
    dataProvider
      .getList<UserProfileSegmentSnapshot>("profile-segment-snapshot", {
        pagination: {
          page: 1,
          perPage: profileSegmentIds.length
        },
        sort: { field: "timestamp", order: "DESC" },
        filter: { userProfileSegmentId__in: profileSegmentIds }
      })
      .then(({ data }) => {
        setLatestSegmentSnapshots(data);
      })
      .finally(() => {
        setSelectedLoading(false);
      });
  }, [profileSegmentIds]);

  useEffect(() => {
    if (!selectedProfileSegmentId || !dateRangeFrom || !dateRangeTo) {
      return;
    }

    dataProvider
      .getList<UserProfileSegmentSnapshot>("profile-segment-snapshot", {
        pagination: {
          page: 1,
          perPage: 1000
        },
        sort: { field: "timestamp", order: "ASC" },
        filter: {
          userProfileSegmentId: selectedProfileSegmentId,
          timestamp__between: `${dateRangeFrom.toISOString()},${dateRangeTo.toISOString()}`
        }
      })
      .then(({ data }) => {
        setSelectedSegmentSnapshots(data);
      });
  }, [selectedProfileSegmentId, dateRangeFrom, dateRangeTo]);

  const dateFormatter = (date: number) => {
    return dayjs(date).format("DD/MM/YY HH:mm");
  };

  const latestCounts = useMemo<Record<Identifier, number>>(() => {
    if (
      !latestSegmentSnapshots ||
      !profileSegmentIds ||
      profileSegmentIds.length === 0
    ) {
      return {};
    }

    const sortedSnapshots = latestSegmentSnapshots.sort((a, b) => {
      const aTime = dayjs(a.timestamp);
      const bTime = dayjs(b.timestamp);

      if (aTime.isBefore(bTime)) return -1;
      if (aTime.isAfter(bTime)) return 1;
      return 0;
    });

    return profileSegmentIds.reduce<Record<Identifier, number>>((acc, id) => {
      const snapshot = sortedSnapshots.find(s => s.userProfileSegmentId === id);
      if (snapshot) {
        acc[id] = snapshot.value;
      }
      return acc;
    }, {});
  }, [latestSegmentSnapshots, profileSegmentIds]);

  const onChangeDateRangeFrom = (date: MaterialUiPickersDate) => {
    if (!date) return;

    setDateRangeFrom(dayjs(date.toISOString()).startOf("day"));
  };

  const onChangeDateRangeTo = (date: MaterialUiPickersDate) => {
    if (!date) return;

    setDateRangeTo(dayjs(date.toISOString()).endOf("day"));
  };

  if (!isAdmin) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">FarmFLiX Admin</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">Audience Overview</Typography>
      </Grid>
      <Grid item xs={12}>
        {loadingSegments ? (
          <Typography variant="h3">Loading...</Typography>
        ) : (
          <Grid container>
            {profileSegments
              ? Object.values(profileSegments).map(segment => (
                  <Grid
                    item
                    xs={3}
                    lg={2}
                    key={segment.id}
                    onClick={() => {
                      setSelectedProfileSegmentId(segment.id);
                    }}
                    style={{
                      cursor: "pointer",
                      margin: "5px",
                      borderWidth: "1px",
                      borderRadius: "4px",
                      borderColor: "black",
                      borderStyle: "solid",
                      textAlign: "center",
                      backgroundColor:
                        selectedProfileSegmentId === segment.id
                          ? "rgba(0, 0, 0, 0.1)"
                          : "transparent"
                    }}
                  >
                    <Typography variant="h2">
                      {latestSegmentSnapshots === undefined
                        ? "~"
                        : latestCounts[segment.id] || 0}
                    </Typography>
                    <Typography variant="subtitle2">{segment.name}</Typography>
                  </Grid>
                ))
              : null}
          </Grid>
        )}
      </Grid>
      {selectedProfileSegmentId ? (
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box flex={1} flexDirection="column" justifyContent="flex-start">
              <DatePicker
                clearable={false}
                label="Date From"
                format="dd/MM/yy"
                value={dateRangeFrom}
                onChange={onChangeDateRangeFrom}
              />
              <DatePicker
                clearable={false}
                label="Date To"
                format="dd/MM/yy"
                value={dateRangeTo}
                onChange={onChangeDateRangeTo}
              />
            </Box>
          </MuiPickersUtilsProvider>
          {selectedSegmentSnapshots === undefined || selectedLoading ? (
            <Typography variant="h3">Loading Snapshots...</Typography>
          ) : (
            <VictoryChart
              theme={VictoryTheme.material}
              width={500}
              height={250}
              scale="linear"
              containerComponent={
                <VictoryVoronoiContainer
                  labels={({ datum }) =>
                    `${dateFormatter(datum.x)}: ${datum.y}`
                  }
                />
              }
            >
              <VictoryAxis tickFormat={Math.round} dependentAxis={true} />
              <VictoryAxis tickFormat={dateFormatter} />
              <VictoryLine
                style={{
                  data: { stroke: "#c43a31" },
                  parent: { border: "1px solid #ccc" }
                }}
                data={selectedSegmentSnapshots.map(({ value, timestamp }) => ({
                  x: dayjs(timestamp).valueOf(),
                  y: value
                }))}
                labelComponent={<VictoryTooltip />}
              />
            </VictoryChart>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};
