import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  List,
  ListProps,
  TextField,
  ShowButton
} from "react-admin";

export const ScheduledNotificationDataList = () => (
  <Datagrid>
    <TextField source="name" />
    <TextField source="type" />
    <TextField source="status" />
    <TextField source="failureReason" />
    <DateField showTime source="scheduledTime" />
    <DateField showTime source="created" />
    <ShowButton />
  </Datagrid>
);

const ScheduledNotificationListFilters = (
  props: Omit<FilterProps, "children">
) => (
  <Filter {...props}>
    <AutocompleteInput
      alwaysOn
      source="type"
      choices={[
        { id: "email", name: "Email" },
        { id: "push", name: "Push" },
        { id: "sms", name: "SMS" }
      ]}
    />
    <AutocompleteInput
      alwaysOn
      source="template"
      choices={[
        { id: "latest-episode", name: "latest-episode" },
        { id: "inactivity-notification", name: "inactivity-notification" }
      ]}
    />
    <AutocompleteInput
      alwaysOn
      source="status"
      choices={[
        { id: "pending", name: "Pending" },
        { id: "sent", name: "Sent" },
        { id: "opened", name: "Opened" },
        { id: "failed", name: "Failed" },
        { id: "bounced", name: "Bounced" },
        { id: "complaint", name: "Complaint" }
      ]}
    />
  </Filter>
);

const ScheduledNotificationList = (props: ListProps) => (
  <List
    {...props}
    filters={<ScheduledNotificationListFilters />}
    sort={{ field: "scheduledTime", order: "DESC" }}
    bulkActionButtons={false}
    exporter={false}
  >
    <ScheduledNotificationDataList />
  </List>
);

export default ScheduledNotificationList;
