import React, { Fragment } from "react";
import { CardActions } from "@material-ui/core";
import {
  List,
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  SingleFieldList,
  TextField
} from "react-admin";
import { VideoSearchResult } from "@booyaltd/core";
import { useStyles } from "./styles";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core/";
import {
  AutocompleteContainsFilter,
  SelectContainsFilter,
  SelectEqualsFilter,
  TextContainsFilter
} from "../../components/filters/TextFilters";

const VideoListActions = ({ basePath }: { basePath?: string }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

const VideoFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <TextContainsFilter label="Name" source="name" alwaysOn={true} />
    <ReferenceInput
      label="Channel"
      source="channelId"
      reference="channel"
      alwaysOn={true}
    >
      <SelectEqualsFilter source="channelId" optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Playlist"
      source="playlistIds__contains"
      reference="playlist"
      alwaysOn={true}
    >
      <SelectContainsFilter source="playlistIds" optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Search Terms"
      source="searchTerms__contains"
      reference="tag"
      filter={{ type: "search-term" }}
      alwaysOn={true}
    >
      <AutocompleteContainsFilter
        source="searchTerms"
        optionText="tag"
        optionValue="tag"
      />
    </ReferenceInput>
    <ReferenceInput
      label="Soft Links"
      source="softLinks__contains"
      reference="tag"
      filter={{ type: "soft-link" }}
      alwaysOn={true}
    >
      <AutocompleteContainsFilter
        source="softLinks"
        optionText="tag"
        optionValue="tag"
      />
    </ReferenceInput>
  </Filter>
);

export const VideoDataGrid = (props: {}) => {
  const classes = useStyles();
  return (
    <Datagrid {...props}>
      <FunctionField<VideoSearchResult>
        render={(record: VideoSearchResult | undefined) => {
          if (!record || !record.thumbnailThumb) return null;

          return (
            <img src={record.thumbnailThumb} className={classes.imageField} />
          );
        }}
      />
      <DateField
        source="masterReleaseDate"
        label="Release Date"
        sortable={true}
      />
      <DateField source="captureDate" label="Capture Date" sortable={true} />
      <TextField source="name" sortable={true} />
      <FunctionField<VideoSearchResult>
        render={(record: VideoSearchResult | undefined) => {
          if (!record) return null;

          const masterReady = !(
            !record.masterProvider ||
            !record.masterProviderId ||
            !record.masterProviderReady
          );

          const previewReady = !(
            !record.previewProvider ||
            !record.previewProviderId ||
            !record.previewProviderReady
          );

          return (
            <Box flexDirection="row" display="flex">
              <Box
                mx={1}
                flexDirection="column"
                justifyContent="center"
                display="flex"
              >
                <Typography>Master</Typography>
                {masterReady ? (
                  <CheckCircle
                    style={{ alignSelf: "center", color: "green" }}
                  />
                ) : (
                  <Cancel style={{ alignSelf: "center", color: "red" }} />
                )}
              </Box>
              <Box
                mx={1}
                flexDirection="column"
                justifyContent="center"
                display="flex"
              >
                <Typography>Preview</Typography>
                {previewReady ? (
                  <CheckCircle
                    style={{ alignSelf: "center", color: "green" }}
                  />
                ) : (
                  <Cancel style={{ alignSelf: "center", color: "red" }} />
                )}
              </Box>
            </Box>
          );
        }}
      />
      <ReferenceField label="Channel" source="channelId" reference="channel">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField
        label="Playlists"
        reference="playlist"
        source="playlistIds"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ShowButton />
    </Datagrid>
  );
};

export const VideoList = (props: {}) => {
  return (
    <Fragment>
      <List
        actions={<VideoListActions />}
        filters={<VideoFilter />}
        sort={{ field: "masterReleaseDate", order: "DESC" }}
        perPage={20}
        bulkActionButtons={false}
        {...props}
      >
        <VideoDataGrid />
      </List>
    </Fragment>
  );
};
