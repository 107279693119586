import React, { useCallback, useState, MouseEvent, useEffect } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import {
  useGetList,
  useLoading,
  useNotify,
  useRefresh,
  useUpdateLoading,
  useUnselectAll
} from "react-admin";
import { GiftcardApplicationDataProvider } from "../../providers/data/BasicCrudDataProviders";
import { Product } from "../../entities/Subscription";
import { Autocomplete } from "@material-ui/lab";
import GiftcardApplication from "../../entities/Giftcard";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dayjs from "dayjs";

const DEFAULT_PRODUCT_ID = "voucher-2-month";

const REDEEMABLE_BY_OPTIONS = [
  { value: "non-active", label: "Non Active Members" },
  { value: "everyone", label: "Everyone" }
];

const GiftcardApplicationApproveButton = (props: any) => {
  const [results, setResults] = useState<GiftcardApplication[]>();
  const [modalOpen, setModalOpen] = useState(false);
  const [productId, setProductId] = useState<string | null>(DEFAULT_PRODUCT_ID);
  const [redeemableBy, setRedeemableBy] = useState<string>(
    REDEEMABLE_BY_OPTIONS[0].value
  );
  const [giftcardExpiryDate, setGiftcardExpiryDate] = useState<Date>(
    dayjs()
      .set("month", 9)
      .set("date", 5)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .set("milliseconds", 0)
      .toDate()
  );
  const notify = useNotify();
  const refresh = useRefresh();
  const loading = useLoading();
  const unselectAll = useUnselectAll(props.resource);
  const { startLoading, stopLoading } = useUpdateLoading();

  const { data: products } = useGetList<Product>(
    "product",
    { page: 1, perPage: 1000 },
    { field: "id", order: "ASC" },
    undefined,
    { enabled: modalOpen }
  );

  useEffect(() => {
    if (
      !loading &&
      products &&
      Object.keys(products).includes(DEFAULT_PRODUCT_ID)
    ) {
      setProductId(DEFAULT_PRODUCT_ID);
    }
  }, [loading]);

  const giftcardApplicationIds: string[] = props.selectedIds;

  const approve = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (loading || !productId || !redeemableBy) return;

      const dataProvider = new GiftcardApplicationDataProvider();
      startLoading();
      dataProvider
        .approveMany(
          giftcardApplicationIds,
          productId,
          redeemableBy,
          giftcardExpiryDate
        )
        .then(response => {
          setResults(response);
          stopLoading();
          notify(
            `Successfully approved ${giftcardApplicationIds.length} giftcard applications`,
            "success"
          );
        })
        .catch(e => {
          console.error(
            "Error approving giftcard applications",
            JSON.stringify(e, null, 2)
          );
          notify(
            `Error approving one or more giftcard applications: ${JSON.stringify(
              e,
              null,
              2
            )}`,
            "error"
          );
        });
    },
    [loading, productId, giftcardApplicationIds]
  );

  const onOpen = () => {
    setModalOpen(true);
    setResults(undefined);
  };

  const onClose = () => {
    setModalOpen(false);
    setResults(undefined);
    unselectAll();
    refresh();
  };

  if (giftcardApplicationIds.length <= 0) return null;

  return (
    <>
      <Button onClick={onOpen} disabled={loading}>
        Approve
      </Button>
      <Dialog onClose={onClose} open={modalOpen} maxWidth={"md"}>
        <DialogTitle>
          Approve {giftcardApplicationIds.length} giftcard application(s)
        </DialogTitle>
        {results ? (
          <DialogContent>
            <Typography variant="body2">Approval Results</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map(
                  ({
                    id,
                    name,
                    countryCode,
                    phoneNumber,
                    email,
                    status,
                    denyReason,
                    errorReason
                  }) => (
                    <TableRow key={id}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        {countryCode} {phoneNumber}
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{status}</TableCell>
                      <TableCell>
                        {errorReason || denyReason || "SMS sent successfully"}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </DialogContent>
        ) : (
          <DialogContent>
            {!products || Object.keys(products).length <= 0 ? (
              <CircularProgress color="inherit" />
            ) : (
              <DialogContentText>
                <Typography variant="body2">Giftcard Product</Typography>
                <Autocomplete<string>
                  id="productId"
                  defaultValue={DEFAULT_PRODUCT_ID}
                  onChange={(e, value) => setProductId(value)}
                  options={Object.keys(products)}
                  getOptionLabel={option => products[option].name}
                  style={{ width: 300 }}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
                <Typography variant="body2">Giftcard Redeemable By</Typography>
                <Autocomplete<{ value: string; label: string }>
                  id="redeemableBy"
                  defaultValue={REDEEMABLE_BY_OPTIONS[0]}
                  onChange={(e, value) =>
                    setRedeemableBy(
                      value ? value.value : REDEEMABLE_BY_OPTIONS[0].value
                    )
                  }
                  options={REDEEMABLE_BY_OPTIONS}
                  getOptionLabel={option => option.label}
                  style={{ width: 300 }}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
                <Typography variant="body2">
                  Giftcard Redeemable Until
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    clearable
                    format="EEE do MMM yy"
                    value={giftcardExpiryDate ? giftcardExpiryDate : null}
                    emptyLabel={"Pick an optional expiry date for the giftcard"}
                    onChange={val => setGiftcardExpiryDate(val as Date)}
                  />
                </MuiPickersUtilsProvider>
              </DialogContentText>
            )}
          </DialogContent>
        )}
        <DialogActions>
          {results ? (
            <Button onClick={onClose} disabled={loading}>
              Close
            </Button>
          ) : (
            <Button onClick={approve} disabled={loading || !productId}>
              Approve
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GiftcardApplicationApproveButton;
