/* eslint-disable react/display-name */
import React, { useCallback, useMemo, useState } from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { Tag } from "../../../entities/Catalog";
import { useStyles } from "./styles";

export const renderEditSearchTerms = (tags: Record<string, Tag>) => (
  params: GridRenderEditCellParams
) => {
  const classes = useStyles();
  const [filterText, setFilterText] = useState<string>();
  const [open, setOpen] = useState(false);
  const [searchTerms, setSearchTerms] = useState<string[]>(
    ((params.value as unknown) as string[]) || []
  );
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const onSave = () => {
    params.api.setEditCellValue({
      id: params.id,
      field: "searchTerms",
      value: searchTerms
    });
    onClose();
  };

  const tagGroups = useMemo(
    () =>
      Object.keys(tags).reduce<Record<string, Tag[]>>(
        (carry, tagId: string): Record<string, Tag[]> => {
          const tag = tags[tagId];
          const group =
            tag.group && tag.group.length > 0
              ? tag.group.toLowerCase()
              : "other";

          if (!carry[group]) {
            carry[group] = [];
          }

          if (
            !filterText ||
            tag.tag.toLowerCase().includes(filterText.toLowerCase())
          ) {
            carry[group].push(tag);
          }

          return carry;
        },
        {}
      ),
    [tags, filterText]
  );

  const onClickTag = (tag: string) => {
    if (searchTerms.includes(tag)) {
      setSearchTerms(searchTerms.filter(t => t !== tag));
    } else {
      setSearchTerms([...searchTerms, tag]);
    }
  };

  const TagView = useCallback(
    ({ tag }: { tag: string }) => {
      const isAdded = searchTerms.includes(tag);
      return (
        <Chip
          style={{ width: "fit-content" }}
          label={tag}
          onClick={() => onClickTag(tag)}
          onDelete={() => onClickTag(tag)}
          deleteIcon={isAdded ? undefined : <AddCircleOutline />}
        />
      );
    },
    [searchTerms]
  );

  return (
    <>
      <Button onClick={onOpen} variant="text">
        <Typography variant="body1" className={classes.text}>
          {searchTerms && searchTerms.length > 0
            ? searchTerms.join(", ")
            : "Add"}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Update Search Terms</DialogTitle>
        <DialogContent>
          <Box display="flex" flex={1} flexDirection="column">
            <Box display="flex" flex={1} flexDirection="row">
              {searchTerms.map(tag => (
                <TagView key={tag} tag={tag} />
              ))}
            </Box>

            <Input
              type="text"
              value={filterText}
              placeholder="Filter"
              onChange={event => setFilterText(event.target.value || undefined)}
            />
            <Table>
              <TableHead>
                {Object.keys(tagGroups).map(group => (
                  <TableCell key={group}>{group}</TableCell>
                ))}
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.keys(tagGroups).map(group => (
                    <TableCell
                      key={group}
                      valign="top"
                      style={{ verticalAlign: "top" }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignContent="flex-start"
                        alignItems="flex-start"
                      >
                        {tagGroups[group]
                          .filter(t => !searchTerms.includes(t.tag))
                          .map(tag => (
                            <TagView key={tag.id} tag={tag.tag} />
                          ))}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={onSave}>
            Set Search Terms
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
