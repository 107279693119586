import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput
} from "react-admin";
import Icon from "@material-ui/icons/PostAdd";
import GiftcardApplication, {
  Giftcard,
  GiftcardApplicationStatus
} from "../../entities/Giftcard";
import { GiftcardApplicationBulkActions } from "./GiftcardApplicationActions";
import ApprovedIcon from "@material-ui/icons/ThumbUpAlt";
import DeniedIcon from "@material-ui/icons/ThumbDownAlt";
import PendingIcon from "@material-ui/icons/ThumbsUpDown";

const GiftcardApplicationListFilters = (
  props: Omit<FilterProps, "children">
) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="status"
      choices={[
        { id: "pending", name: "Pending" },
        { id: "approved", name: "Approved" },
        { id: "denied", name: "Denied" },
        { id: "error", name: "Error" }
      ]}
    />
    <TextInput alwaysOn source="name" />
    <TextInput alwaysOn source="email" />
    <TextInput alwaysOn source="phoneNumber" />
  </Filter>
);

export const GiftcardApplicationList = (props: ListProps) => {
  return (
    <List
      {...props}
      filters={<GiftcardApplicationListFilters />}
      filterDefaultValues={{ status: "pending" }}
      sort={{ field: "created", order: "desc" }}
      bulkActionButtons={<GiftcardApplicationBulkActions />}
    >
      <Datagrid>
        <FunctionField<GiftcardApplication>
          sortable={true}
          sortBy="status"
          render={(app?: GiftcardApplication) => {
            if (!app) return null;

            return app.status === GiftcardApplicationStatus.Approved ? (
              <ApprovedIcon aria-label="Approved" />
            ) : app.status === GiftcardApplicationStatus.Denied ? (
              <DeniedIcon aria-label="Denied" />
            ) : (
              <PendingIcon aria-label="Pending" />
            );
          }}
          label="Status"
        />
        <TextField source="name" sortable={true} />
        <FunctionField<GiftcardApplication>
          sortable={true}
          sortBy="phoneNumber"
          label="Phone"
          render={(app?: GiftcardApplication) => {
            if (!app) return null;

            return (
              <a
                href={`tel:+${app.countryCode}${app.phoneNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                +{app.countryCode} {app.phoneNumber}
              </a>
            );
          }}
        />
        <FunctionField<GiftcardApplication>
          label="Evidence"
          render={(app?: GiftcardApplication) => {
            if (!app) return null;

            return (
              <a
                href={app.evidenceMediaFullUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={app.evidenceMediaThumbUrl}
                  style={{ maxWidth: "150px", height: "auto" }}
                />
              </a>
            );
          }}
        />
        <ReferenceField
          label="Generated Giftcard"
          source="generatedGiftcardId"
          reference="giftcard"
          link="show"
        >
          <FunctionField<Giftcard>
            render={record => (
              <div style={{ textAlign: "center" }}>
                <p>Status: {record?.status}</p>
                <p>{record?.giftcardCode}</p>
              </div>
            )}
          />
        </ReferenceField>
        <DateField showTime source="created" />
        <DateField showTime source="updated" />
      </Datagrid>
    </List>
  );
};

export const GiftcardApplicationIcon = Icon;
