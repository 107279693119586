import { Box } from "@material-ui/core";
import React from "react";
import { useQuery, Loading } from "react-admin";
import { DailyUserActivity } from "../../entities/Profile";
import VideoViewSessionTable from "./VideoViewSessionTable";

const DailyUserActivityListExpandedRow = (props?: {
  record?: DailyUserActivity;
}) => {
  const { data: sessions, loading: sessionsLoading } = useQuery({
    type: "getList",
    resource: "video-view-session",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "createdDate", order: "DESC" },
      filter: { userId: props?.record?.userId }
    }
  });

  if (sessionsLoading) return <Loading />;

  return (
    <Box>
      <VideoViewSessionTable sessions={sessions} />
    </Box>
  );
};

export default DailyUserActivityListExpandedRow;
