import React, { cloneElement, useCallback, useEffect, useState } from "react";
import keyBy from "lodash/keyBy";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button as RAButton,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  Pagination,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  ResourceComponentPropsWithId,
  sanitizeListRestProps,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useDataProvider,
  useMutation,
  useNotify,
  useQuery,
  useRedirect,
  useReference,
  useRefresh,
  useUpdate
} from "react-admin";

import icon from "@material-ui/icons/AccountBox";
import dayjs from "dayjs";
import EmailIcon from "@material-ui/icons/Email";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import TimelapseRoundedIcon from "@material-ui/icons/TimelapseRounded";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import LockIcon from "@material-ui/icons/Lock";
import FlagIcon from "@material-ui/icons/Flag";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import { Device, Profile, VideoViewSession } from "../entities/Profile";
import StringArrayChipsField from "../components/fields/StringArrayChipsField";
import ProfileDataProvider from "../providers/data/ProfileDataProvider";
import { MemberStatus, Payment, Product } from "../entities/Subscription";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from "@material-ui/core";
import { PaymentSummary } from "./PaymentResource";
import DateInput from "../components/inputs/DateInput";
import { calculateDateLastSeen, formatDate } from "../utils";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { NotificationlogDataGrid } from "./NotificationLog/NotificationLogList";
import { Alert } from "@material-ui/lab";
import CancelStripeSubscriptionButton from "./Profile/CancelStripeSubscriptionButton";
import { COUNTRY_CODES } from "../constants";
import { UserProfile, Video } from "@booyaltd/core";
import {
  DateBetweenFilter,
  TextContainsFilter
} from "../components/filters/TextFilters";
import SendSmsToProfileListButton from "./Profile/SendSmsToProfileListButton";
import SendPushToProfileListButton from "./Profile/SendPushToProfileListButton";

// eslint-disable-next-line
const useStyles = makeStyles(() =>
  createStyles({
    flexWrapper: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      margin: "5px 0"
    },
    divider: {
      margin: "20px 0 10px 0"
    },
    cardSection: {
      padding: "15px",
      borderRadius: "0 0",
      backgroundColor: "#f7f7f7",
      marginBottom: "10px",
      boxShadow: "none",
      color: "#545454",
      "& label": {
        width: "100%",
        "& .MuiCheckbox-root": {
          padding: "3px 9px"
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "0.9rem"
        }
      },
      "& .MuiFormHelperText-root": {
        display: "none"
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "#fff",
        marginBottom: "20px",
        "&:hover": {
          backgroundColor: "#fff"
        }
      }
    },
    cardSectionHeading: {
      fontSize: "1.2rem",
      color: "#6f6f6f",
      borderBottom: "1px solid #bbb",
      paddingBottom: "5px",
      marginBottom: "10px"
    },
    cardSectionStatus: {
      borderRadius: "0 0",
      backgroundColor: "#f7f7f7",
      marginBottom: "10px",
      boxShadow: "none",
      color: "#545454"
    },
    cardHeading: {
      fontSize: "1.2rem",
      color: "#6f6f6f"
    },
    label: {
      color: "#848484"
    },
    cardLabel: {
      fontSize: "1.2rem",
      color: "#848484",
      marginBottom: "16px"
    },
    avatar: {
      margin: "0 auto 10px auto",
      width: "80px",
      height: "80px",
      fontSize: "2.6rem"
    },
    profileName: {
      fontSize: "1.8rem"
    },
    copyIcon: {
      width: "1rem",
      height: "1rem"
    },
    statusButton: {
      backgroundColor: "transparent",
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    statusButtonLarge: {
      lineHeight: "initial",
      backgroundColor: "transparent",
      padding: 0,
      fontSize: "1.6rem",
      textTransform: "capitalize",
      "& .MuiButton-iconSizeMedium > *:first-child": {
        fontSize: "30px"
      },
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    currentStatus: {
      fontSize: "1.6rem",
      lineHeight: "1.7rem"
    },
    currentStatusPlan: {
      fontSize: "1.6rem",
      lineHeight: "1.7rem",
      color: "#a0a0a0"
    },
    standardStatus: {
      color: "#B5D800"
    },
    lockedStatus: {
      color: "#f44336"
    },
    expiredStatus: {
      color: "#f17d45"
    },
    smlTextButton: {
      fontSize: "0.8rem",
      textDecoration: "underline",
      "&:hover": {
        color: "#B5D800",
        cursor: "pointer"
      }
    },
    tooltip: {
      color: "#B5D800",
      "&:hover": {
        cursor: "pointer"
      }
    },
    dialogTitle: {
      backgroundColor: "#B5D800"
    },
    flagButton: {
      width: "100%",
      color: "#f44336"
    },
    flaggedButton: {
      width: "100%",
      color: "#fff",
      backgroundColor: "#f44336",
      padding: "4px 12px",
      "&:hover": {
        backgroundColor: "#f44336"
      }
    },
    ".MuiTableCell-head": {
      backgroundColor: "#e4e4e4"
    }
  })
);

const ProfileFilter = (props: {}) => (
  <Filter {...props}>
    <TextContainsFilter label="Phone" source="phoneNumber" alwaysOn />
    <TextContainsFilter label="Email" source="email" alwaysOn />
    <TextContainsFilter label="Last Name" source="lastName" alwaysOn />
    <TextContainsFilter label="First Name" source="firstName" />
    <ReferenceArrayInput
      label="Status"
      source="memberStatusId__in"
      reference="member-status"
      sort={{ field: "order", order: "ASC" }}
    >
      <SelectArrayInput optionText="name" optionValue="id" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Product"
      source="productId__in"
      reference="product"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <BooleanInput source="productAutoRenewing" label="Auto Renewing" />
    <BooleanInput
      source="smsMarketingRejected"
      label="SMS Marketing Rejected"
    />
    <ReferenceArrayInput
      label="In Audiences"
      source="profileSegment__in"
      reference="profile-segment"
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Not In Audiences"
      source="profileSegment__not__in"
      reference="profile-segment"
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>
    <DateBetweenFilter
      source="subscriptionExpiryDate"
      label="Subscription Expiry Between"
    />
    <DateBetweenFilter source="dateLastSeen" label="Last Seen Between" />
    <DateBetweenFilter source="dateCreated" label="Created Between" />
  </Filter>
);

const ProfileListActionButtons = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  showFilter,
  total,
  ...rest
}: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filterValues={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={200000}
    />
    <SendSmsToProfileListButton />
    <SendPushToProfileListButton />
  </TopToolbar>
);

export const ProfileList = (props: {}) => (
  <List
    perPage={50}
    filters={<ProfileFilter />}
    bulkActionButtons={false}
    actions={<ProfileListActionButtons />}
    sort={{ field: "dateLastSeen", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <ShowButton />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <FunctionField<Profile>
        label="Phone"
        render={(profile?: Profile) =>
          `${
            profile?.countryCode ? `+${profile.countryCode} ` : ""
          }${profile?.phoneNumber || ""}`
        }
      />
      <ReferenceField
        label="Status"
        source="memberStatusId"
        reference="member-status"
        sortable={false}
      >
        <TextField label="Status" source="name" />
      </ReferenceField>
      <DateField
        source="dateLastSeen"
        label="Last Seen (Web)"
        showTime={true}
        options={{
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        }}
      />
    </Datagrid>
  </List>
);

const PostEditToolbar = (props: {}) => (
  <Toolbar {...props}>
    <SaveButton label="Save" submitOnEnter={true} />
  </Toolbar>
);

const ProfileTitle = ({ record }: { record?: Profile }) => {
  return (
    <span>
      Profile:{" "}
      {record
        ? `${
            record.firstName || record.lastName
              ? `${record.firstName} ${record.lastName}`
              : "New"
          }`
        : ""}
    </span>
  );
};

export const DeviceEdit = (props: {}) => (
  <Edit {...props} title={<ProfileTitle />} successMessage="Device Updated">
    <SimpleForm redirect="/member" toolbar={<PostEditToolbar />}>
      <TextField label="Platform" source="platform" />
      <TextField label="Platform Version" source="platformVersion" />
      <TextField label="Make" source="make" />
      <TextField label="Model" source="model" />
      <TextField label="Ip" source="ip" />
      <DateField label="Login Time" source="loginTime" showTime />
      <BooleanInput label="Active" source="active" />
      <BooleanInput label="Blocked" source="blocked" />
    </SimpleForm>
  </Edit>
);

const PaymentTitle = ({ record }: { record?: Payment }) => {
  return (
    <span>Payment: {record ? `${record.paymentProviderReference}` : ""}</span>
  );
};

export const PaymentDetails = (props: {}) => {
  return (
    <Show {...props} title={<PaymentTitle />}>
      <SimpleShowLayout>
        <PaymentSummary />
      </SimpleShowLayout>
    </Show>
  );
};

export const EntitlementCreate = (props: {}) => {
  return (
    <Create
      title="Create Manual Entitlement"
      successMessage="Entitlement Created"
      {...props}
    >
      <SimpleForm redirect="/member">
        <ReferenceField label="Name" source="memberId" reference="member">
          <TextField label="Name" source="name" />
        </ReferenceField>

        <ReferenceField label="Email" source="memberId" reference="member">
          <FunctionField
            label="Email"
            render={(record: any) =>
              record.email !== null ? `${record.email}` : "No email supplied"
            }
          />
        </ReferenceField>
        <ReferenceField
          label="Phone Number"
          source="memberId"
          reference="member"
        >
          <FunctionField
            label="Phone Number"
            render={(record: any) =>
              record.phoneNumber !== null
                ? `${record.phoneNumber}`
                : "No phone number supplied"
            }
          />
        </ReferenceField>

        <ReferenceInput
          label="Member Status Price"
          source="productId"
          reference="product"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DateInput label="Active Date" source="activeDate" />
      </SimpleForm>
    </Create>
  );
};

export const EntitlementEdit = (props: {}) => (
  <Edit {...props} successMessage="Entitlement Updated">
    <SimpleForm redirect="/member" toolbar={<PostEditToolbar />}>
      <TextField source="active" label="Active" />
      <DateField showTime source="activeDate" label="Active Date" />
      <DateInput source="expiryDate" label="Expiry Date" />
      <TextField source="source" label="Source" />
    </SimpleForm>
  </Edit>
);

const ProfileShowActivitySummary = ({ record }: { record?: Profile }) => {
  const classes = useStyles();

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.cardSection}>
        <Typography variant="body2" className={classes.cardLabel}>
          Latest Activity
        </Typography>
        <Box display="flex">
          <Box flexGrow={1} p={1}>
            <Typography variant="body2" className={classes.label}>
              Last Seen
            </Typography>
            <Typography>
              {calculateDateLastSeen(record?.dateLastSeen)}
            </Typography>
            <Typography
              variant="body2"
              className={classes.label}
              style={{ fontSize: "0.8rem" }}
            >
              {formatDate(record?.dateLastSeen)}
            </Typography>
          </Box>
          <Box flexGrow={1} p={1}>
            <Typography variant="body2" className={classes.label}>
              Active Devices
            </Typography>
            <Typography>{record.activeDevices.toString()}</Typography>
          </Box>
          <Box flexGrow={1} p={1}>
            <Typography variant="body2" className={classes.label}>
              Inactive Devices
            </Typography>
            <Typography>{record.inactiveDevices.toString()}</Typography>
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box display="flex">
          <Box flexGrow={1} p={1}>
            <Typography variant="body2" className={classes.label}>
              First Web Login
            </Typography>
            <Typography>{formatDate(record?.dateWebFirstLoggedIn)}</Typography>
            <br />
            <Typography variant="body2" className={classes.label}>
              Latest Web Login
            </Typography>
            <Typography>{formatDate(record?.dateWebLastLoggedIn)}</Typography>
          </Box>
          <Box flexGrow={1} p={1}>
            <Typography variant="body2" className={classes.label}>
              First App Login
            </Typography>
            <Typography>{formatDate(record?.dateAppFirstLoggedIn)}</Typography>
            <br />
            <Typography variant="body2" className={classes.label}>
              Latest App Login
            </Typography>
            <Typography>{formatDate(record?.dateAppLastLoggedIn)}</Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

const ProfileShowDevicesSummary = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.cardSection}>
        <Typography variant="body2" className={classes.cardLabel}>
          Devices
        </Typography>

        <ReferenceManyField
          reference="device"
          target="userId"
          source="id"
          label=" "
          sort={{ field: "loginTime", order: "DESC" }}
        >
          <Datagrid>
            <TextField source="platform" />
            <TextField source="platformVersion" />
            <TextField source="make" />
            <TextField source="model" />
            <TextField source="ip" />
            <DateField
              source="loginTime"
              options={{
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
              }}
            />
            <BooleanField source="active" />
            <BooleanField source="blocked" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Card>
    </Grid>
  );
};

const ProfileShowCommunicationLog = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.cardSection}>
        <Typography variant="body2" className={classes.cardLabel}>
          Notification Log
        </Typography>

        <ReferenceManyField
          reference="notification-log"
          target="userId"
          label=" "
        >
          <NotificationlogDataGrid />
        </ReferenceManyField>
      </Card>
    </Grid>
  );
};

const ProfileShowViewingHistorySummary = ({
  record
}: {
  record?: UserProfile;
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({ field: "createdDate", order: "DESC" });
  const { data, total, loading, error } = useQuery(
    {
      type: "getList",
      resource: "video-view-session",
      payload: {
        pagination: { page, perPage },
        sort,
        filter: { userId: record?.id, validView: true }
      }
    },
    { enabled: !!record }
  );

  if (loading) {
    return null;
  }
  if (error) {
    return <p>ERROR: {error}</p>;
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.cardSection}>
        <Typography variant="body2" className={classes.cardLabel}>
          Viewing History
        </Typography>

        <Datagrid
          data={keyBy(data, "id")}
          ids={data.map(({ id }: VideoViewSession) => id)}
          currentSort={sort}
          setSort={(field, order) =>
            setSort({ field, order: order || sort.order })
          }
        >
          <ReferenceField label="Video" source="videoId" reference="video">
            <FunctionField<Video>
              render={(video?: Video) => {
                return `${video?.name} (${
                  video && video.masterLengthSecs
                    ? `${Math.floor(
                        video.masterLengthSecs / 60
                      )}:${video.masterLengthSecs % 60}`
                    : "0:00"
                })`;
              }}
            />
          </ReferenceField>
          <ReferenceField label="Device" source="deviceId" reference="device">
            <FunctionField<Device>
              label="Device"
              render={(device?: Device) =>
                device
                  ? `${device.platform} - ${device.platformVersion}${
                      device.make ? ` - ${device.make} - ${device.model}` : ""
                    }`.substring(0, 60)
                  : ""
              }
            />
          </ReferenceField>
          <FunctionField<VideoViewSession>
            label="Progress"
            render={(watch?: VideoViewSession) =>
              `${
                watch && watch.startPosition
                  ? `${Math.floor(
                      watch.startPosition / 60
                    )}:${watch.startPosition % 60}`
                  : "0:00"
              } - ${
                watch && watch.currentPosition
                  ? `${Math.floor(
                      watch.currentPosition / 60
                    )}:${watch.currentPosition % 60}`
                  : "0:00"
              } (${watch?.currentPositionUpdateCount || 0} events)`
            }
          />
          <DateField
            showTime={true}
            source="createdDate"
            options={{
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            }}
          />
        </Datagrid>

        <Pagination
          // @ts-ignore
          page={page || 1}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          total={total}
        />
      </Card>
    </Grid>
  );
};

const ProfileShowSummaryTab = ({ record }: { record?: Profile }) => {
  const classes = useStyles();

  // @ts-ignore
  const productHook: {
    referenceRecord?: Product;
  } = useReference({
    reference: "product",
    // @ts-ignore
    id: record.productId
  });

  // @ts-ignore
  const statusHook: {
    referenceRecord?: Product;
  } = useReference({
    reference: "member-status",
    // @ts-ignore
    id: record.memberStatusId
  });

  const [
    resetEmailRejected,
    { loading: resetEmailRejectedLoading }
  ] = useUpdate("member", record?.id || "", {
    emailRejected: false,
    emailRejectedReason: ""
  });

  const plan = productHook.referenceRecord;
  const status = statusHook.referenceRecord;

  if (!record) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.cardSection}>
          {record.emailRejected && (
            <Alert
              severity="error"
              action={
                <Button
                  onClick={() => resetEmailRejected()}
                  disabled={resetEmailRejectedLoading}
                >
                  Reset
                  {resetEmailRejectedLoading && <CircularProgress size={20} />}
                </Button>
              }
            >
              <strong>Email Sending Disabled:</strong>{" "}
              {record.emailRejectedReason}
            </Alert>
          )}
          <Box display="flex">
            <Box p={1}>
              <Avatar className={classes.avatar}>
                {record.firstName.charAt(0)}
              </Avatar>
            </Box>
            <Box p={1} flexGrow={1}>
              <Typography className={classes.profileName}>
                {record.firstName} {record.lastName}
              </Typography>
              <Typography variant="body2">
                <span className={classes.label}>Registered:</span>{" "}
                {formatDate(record.dateWebFirstLoggedIn)}
              </Typography>
              <Box display="flex">
                <Box pr={2} pt={2}>
                  <div className={classes.flexWrapper}>
                    <Typography variant="body2">
                      <span className={classes.label}>Email: </span>{" "}
                      {record.email}{" "}
                      <Tooltip
                        className={classes.tooltip}
                        placement="top"
                        title="Copy Email Address"
                        aria-label="copy-email-address"
                      >
                        <FileCopyOutlinedIcon
                          className={classes.copyIcon}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              record.email as string
                            );
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Box display="flex">
                {record.countryCode && record.phoneNumber && (
                  <Box pt={1}>
                    <div className={classes.flexWrapper}>
                      <Typography variant="body2">
                        <span className={classes.label}>Mobile: </span>+
                        {record.countryCode}
                        {record.phoneNumber}
                      </Typography>
                      {!record.countryCode ||
                        (!record.phoneNumber && (
                          <Typography variant="body2">
                            <span className={classes.label}>Mobile: </span> No
                            Phone Number
                          </Typography>
                        ))}
                    </div>
                  </Box>
                )}
              </Box>
              <Box display="flex">
                <Box pr={2} pt={1}>
                  <div className={classes.flexWrapper}>
                    <Typography variant="body2">
                      <span className={classes.label}>Segments: </span>
                    </Typography>
                    {record.segments ? (
                      record.segments
                        .map(segment => (
                          <Chip
                            key={segment}
                            label={segment}
                            style={{
                              height: "22px",
                              padding: "5px 0",
                              margin: "0 2px"
                            }}
                          />
                        ))
                        .filter(s => s)
                    ) : (
                      <Typography variant="body2"> None</Typography>
                    )}
                  </div>
                </Box>
              </Box>
              <Box display="flex">
                <Box pr={2} pt={1}>
                  <div className={classes.flexWrapper}>
                    <Typography variant="body2">
                      <span className={classes.label}>
                        Email Marketing Enabled:{" "}
                      </span>
                    </Typography>
                    {!record.emailMarketingRejected ? (
                      <CheckCircleIcon fontSize="small" color="primary" />
                    ) : (
                      <CancelIcon fontSize="small" color="error" />
                    )}
                  </div>
                </Box>
              </Box>
              <Box display="flex">
                <Box pr={2} pt={1}>
                  <div className={classes.flexWrapper}>
                    <Typography variant="body2">
                      <span className={classes.label}>
                        SMS Marketing Enabled:{" "}
                      </span>
                    </Typography>
                    {!record.smsMarketingRejected ? (
                      <CheckCircleIcon fontSize="small" color="primary" />
                    ) : (
                      <CancelIcon fontSize="small" color="error" />
                    )}
                  </div>
                </Box>
              </Box>
            </Box>
            {status && status.id === "standard" && (
              <>
                <Box p={1}>
                  <Box style={{ textAlign: "right" }}>
                    <Button
                      disableRipple={true}
                      disableElevation={true}
                      className={`${classes.statusButton} ${classes.standardStatus}`}
                      variant="contained"
                      startIcon={<CheckCircleIcon />}
                    >
                      {status.name}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            {status && status.id === "standard-expired" && (
              <>
                <Box p={1}>
                  <Box style={{ textAlign: "right" }}>
                    <Button
                      disableRipple={true}
                      disableElevation={true}
                      className={`${classes.statusButton} ${classes.expiredStatus}`}
                      variant="contained"
                      startIcon={<TimelapseRoundedIcon />}
                    >
                      {status.name}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            {status && status.id === "trial" && (
              <>
                <Box p={1}>
                  <Box style={{ textAlign: "right" }}>
                    <Button
                      disableRipple={true}
                      disableElevation={true}
                      className={`${classes.statusButton} ${classes.standardStatus}`}
                      variant="contained"
                      color="primary"
                      startIcon={<AvTimerIcon />}
                    >
                      {status.name}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            {status && status.id === "trial-expired" && (
              <>
                <Box p={1}>
                  <Box style={{ textAlign: "right" }}>
                    <Button
                      disableRipple={true}
                      disableElevation={true}
                      className={`${classes.statusButton} ${classes.expiredStatus}`}
                      variant="contained"
                      color="primary"
                      startIcon={<UpdateRoundedIcon />}
                    >
                      {status.name}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            {status && status.id === "locked" && (
              <>
                <Box p={1}>
                  <Box style={{ textAlign: "right" }}>
                    <Button
                      disableRipple={true}
                      disableElevation={true}
                      className={`${classes.statusButton} ${classes.lockedStatus}`}
                      variant="contained"
                      startIcon={<LockIcon />}
                    >
                      {status.name}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box p={1}>
            <Box style={{ textAlign: "right" }}>
              <Typography variant="body2">
                <span className={classes.label}>Last Seen: </span>
                {calculateDateLastSeen(record.dateLastSeen)}
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box display="flex">
            <Box
              p={0.5}
              flex={1}
              display="flex"
              justifyContent="flex-start"
              width={"60%"}
            >
              <Tooltip
                className={classes.tooltip}
                placement="top"
                title="Send Email"
                aria-label="send-email"
              >
                <Button
                  href={"mailto:" + record.email}
                  startIcon={<EmailIcon />}
                  size="small"
                >
                  Send Email
                </Button>
              </Tooltip>
            </Box>
            <Box p={0.5}>
              <FlagProfileButton record={record} />
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.cardSection}>
          <Box display="flex">
            <Box flexGrow={1} p={1}>
              <Typography variant="body2" className={classes.label}>
                Plan
              </Typography>
              <Typography>{plan ? plan.name : ""}</Typography>
            </Box>
            <Box flexGrow={1} p={1}>
              <Typography variant="body2" className={classes.label}>
                Auto Renew
              </Typography>
              <div className={classes.flexWrapper}>
                {record.productAutoRenewing ? (
                  <Button
                    disableRipple={true}
                    disableElevation={true}
                    style={{
                      backgroundColor: "transparent",
                      padding: 0,
                      textTransform: "capitalize"
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<CheckCircleIcon color="primary" />}
                  >
                    Enabled
                  </Button>
                ) : (
                  <Button
                    disableRipple={true}
                    disableElevation={true}
                    style={{
                      backgroundColor: "transparent",
                      padding: 0,
                      textTransform: "capitalize"
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<CancelIcon color="error" />}
                  >
                    Disabled
                  </Button>
                )}
              </div>
            </Box>
            <Box flexGrow={1} p={1}>
              <Typography variant="body2" className={classes.label}>
                {record.productAutoRenewing ? "Renewal Date" : "End Date"}
              </Typography>
              <Typography>
                {formatDate(record.subscriptionExpiryDate)}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const ProfileShowCurrentSubscriptionStatus = (props: any) => {
  const classes = useStyles();

  const record = props.record as Profile;

  const now = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const subscriptionExpiryDate = dayjs(record.subscriptionExpiryDate).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  const subscriptionExpiryDatePretty = dayjs(
    record.subscriptionExpiryDate
  ).format("MMMM D, YYYY h:mm A");
  const cancellationDatePretty = dayjs(record.subscriptionCancelledDate).format(
    "MMMM D, YYYY h:mm A"
  );

  // @ts-ignore
  const productHook: {
    referenceRecord?: Product;
  } = useReference({
    reference: "product",
    // @ts-ignore
    id: record?.productId
  });

  // @ts-ignore
  const statusHook: {
    referenceRecord?: Product;
  } = useReference({
    reference: "member-status",
    // @ts-ignore
    id: record?.memberStatusId
  });

  const plan = productHook.referenceRecord;
  const status = statusHook.referenceRecord;

  if (!record) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.cardSection}>
          <Box display="flex">
            <Box flexGrow={1} p={1} justifyContent="flex-start">
              <Typography
                variant="body2"
                className={classes.cardLabel}
                style={{ marginBottom: "6px" }}
              >
                Current Status
              </Typography>
              {status && status.id === "standard" && (
                <>
                  <Button
                    disableRipple={true}
                    disableElevation={true}
                    className={`${classes.statusButtonLarge} ${classes.standardStatus}`}
                    variant="contained"
                    startIcon={<CheckCircleIcon />}
                  >
                    {status.name}
                  </Button>
                </>
              )}
              {status && status.id === "trial" && (
                <>
                  <Button
                    disableRipple={true}
                    disableElevation={true}
                    className={`${classes.statusButtonLarge} ${classes.standardStatus}`}
                    variant="contained"
                    startIcon={<AvTimerIcon />}
                  >
                    {status.name}
                  </Button>
                </>
              )}
              {status && status.id === "standard-expired" && (
                <>
                  <Button
                    disableRipple={true}
                    disableElevation={true}
                    className={`${classes.statusButtonLarge} ${classes.expiredStatus}`}
                    variant="contained"
                    startIcon={<TimelapseRoundedIcon />}
                  >
                    {status.name}
                  </Button>
                </>
              )}
              {status && status.id === "trial-expired" && (
                <>
                  <Button
                    disableRipple={true}
                    disableElevation={true}
                    className={`${classes.statusButtonLarge} ${classes.expiredStatus}`}
                    variant="contained"
                    startIcon={<UpdateRoundedIcon />}
                  >
                    {status.name}
                  </Button>
                </>
              )}
              {status && status.id === "locked" && (
                <>
                  <Button
                    disableRipple={true}
                    disableElevation={true}
                    className={`${classes.statusButtonLarge} ${classes.lockedStatus}`}
                    variant="contained"
                    startIcon={<LockIcon />}
                  >
                    {status.name}
                  </Button>
                </>
              )}
            </Box>
            <Box flexGrow={1} p={1} justifyContent="flex-start">
              <Typography
                variant="body2"
                className={classes.cardLabel}
                style={{ marginBottom: "6px" }}
              >
                Plan
              </Typography>
              <Typography className={classes.currentStatusPlan}>
                {plan?.name}
              </Typography>
            </Box>
            <Box flexGrow={1} p={1} justifyContent="flex-start">
              {/* If they have cancelled, show:

              1. If sub End Date is greater than now, show 'Subscription will cancel on sub end date'
              2. If sub end date is less than now, show 'Subscription cancelled on sub end date' */}
              {/* Wrap check around this - customer must have an Active Stripe Subscription */}
              {record.stripeCustomerId && (
                <>
                  {!record.subscriptionCancelled && (
                    <CancelStripeSubscriptionButton {...props} />
                  )}
                  {record.subscriptionCancelled && (
                    <>
                      {subscriptionExpiryDate > now && (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.cardLabel}
                            style={{ marginBottom: "6px" }}
                          >
                            Pending Cancellation
                          </Typography>
                          <Typography variant="body2" className={classes.label}>
                            <span className={classes.label}>Cancels on: </span>
                            {subscriptionExpiryDatePretty}
                            <br />
                            <span className={classes.label}>Actioned by: </span>
                            {record.subscriptionCancelledByAdmin
                              ? "Support (" +
                                record.subscriptionCancelledByAdmin +
                                ")"
                              : "Member "}
                            on&nbsp;
                            {cancellationDatePretty}
                          </Typography>
                        </>
                      )}
                      {subscriptionExpiryDate < now && (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.cardLabel}
                            style={{ marginBottom: "6px" }}
                          >
                            Cancelled Details
                          </Typography>
                          <Typography variant="body2">
                            <span className={classes.label}>
                              Cancelled on:{" "}
                            </span>
                            {subscriptionExpiryDatePretty}
                            <br />
                            <span className={classes.label}>Actioned by: </span>
                            {record.subscriptionCancelledByAdmin
                              ? "Support (" +
                                record.subscriptionCancelledByAdmin +
                                ")"
                              : "Member "}
                            on&nbsp;
                            {cancellationDatePretty}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {!record.stripeCustomerId && (
                <>{/*  PayPal / Giftcard Users */}</>
              )}
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const ProfileShowSubscriptionStatusChanges = (props: any) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} {...props}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.cardSection}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="body2" className={classes.cardLabel}>
                Status Changes
              </Typography>
            </Box>
            <Box>
              <AddMemberStatusChangeButton {...props} />
            </Box>
          </Box>
          {/* Status Changes */}
          <ReferenceManyField
            reference="member-status-change"
            source="id"
            target="memberId"
            label=""
            sort={{ field: "activeDate", order: "DESC" }}
          >
            <Datagrid style={{ width: "100%" }}>
              <BooleanField source="active" label="Active" />
              <ReferenceField
                label="Status"
                source="memberStatusId"
                reference="member-status"
                sortable={false}
              >
                <TextField label="Status" source="name" />
              </ReferenceField>
              <DateField
                source="activeDate"
                showTime
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric"
                }}
                label="Active Date"
              />
              <DateField
                showTime
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric"
                }}
                source="expiryDate"
                label="Expiry Date"
              />
              <ExpireNowButton />
            </Datagrid>
          </ReferenceManyField>
        </Card>
      </Grid>
    </Grid>
  );
};

const ProfileShowVideoEntitlementsChanges = (props: any) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} {...props}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.cardSection}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="body2" className={classes.cardLabel}>
                Video Entitlements
              </Typography>
            </Box>
            <Box>
              <AddVideoEntitlementButton {...props} />
            </Box>
          </Box>
          {/* Video Entitlements */}
          <ReferenceManyField
            reference="video-tag-entitlement"
            source="id"
            target="memberId"
            label=""
            sort={{ field: "activeDate", order: "DESC" }}
          >
            <Datagrid>
              <BooleanField source="active" label="Active" />
              <StringArrayChipsField source="videoTags" label="Video Tags" />
              <DateField
                showTime
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric"
                }}
                source="activeDate"
                label="Active Date"
              />
              <DateField
                showTime
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric"
                }}
                source="expiryDate"
                label="Expiry Date"
              />
              <ExpireNowButton />
            </Datagrid>
          </ReferenceManyField>
        </Card>
      </Grid>
    </Grid>
  );
};

const ProfileShowGiftcardsRedeemed = (props: any) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} {...props}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.cardSection}>
          <Typography variant="body2" className={classes.cardLabel}>
            Giftcards Redeemed
          </Typography>

          {/* Giftcards Redeemed  */}
          <ReferenceManyField
            reference="giftcard"
            target="redeemedUserId"
            label=""
            sort={{ field: "redeemedOn", order: "DESC" }}
          >
            <Datagrid>
              <ReferenceField
                label="Giftcard"
                source="id"
                reference="giftcard"
                // linkType="show"
                sortable={false}
              >
                <TextField source="giftcardCode" label="Giftcard" />
              </ReferenceField>
              <ReferenceField
                label="Plan"
                source="productId"
                reference="product"
                // linkType="show"
                sortable={false}
              >
                <TextField label="Plan" source="name" />
              </ReferenceField>
              <TextField source="status" label="Status" />
              <TextField label="Shopify Reference" source="shopifyReference" />
              <DateField
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric"
                }}
                source="redeemedOn"
              />
            </Datagrid>
          </ReferenceManyField>
        </Card>
      </Grid>
    </Grid>
  );
};

const ProfileShowPaymentHistory = (props: any) => {
  const classes = useStyles();

  const currencySymbol = (currency: string) => {
    switch (currency) {
      case "GBP": {
        return "£";
      }
      case "EUR": {
        return "€";
      }
      case "USD": {
        return "$";
      }
      default: {
        return "£";
      }
    }
  };

  return (
    <Grid container spacing={2} {...props}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classes.cardSection}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="body2" className={classes.cardLabel}>
                Payment History
              </Typography>
            </Box>
            <Box>
              <ReferenceField {...props} source="id" reference="member">
                <FunctionField
                  render={(record: any) => (
                    <Typography className={classes.label}>
                      Lifetime Value (Est): {record.lifetimeValue}
                    </Typography>
                  )}
                />
              </ReferenceField>
            </Box>
          </Box>
          <ReferenceManyField
            reference="payment"
            source="id"
            target="memberId"
            label=""
            sort={{ field: "updated", order: "DESC" }}
          >
            <Datagrid>
              <FunctionField
                sortable={false}
                label="Cost"
                render={(record: any) =>
                  `${currencySymbol(record.currency)}${record.cost}`
                }
              />
              <TextField
                source="status"
                sortable={false}
                label="Status"
                style={{ textTransform: "capitalize" }}
              />
              <TextField
                source="paymentProvider"
                label="Payment Provider"
                sortable={false}
                style={{ textTransform: "capitalize" }}
              />
              <TextField
                source="paymentProviderReference"
                label="Payment Provider Reference"
                sortable={false}
              />
              <TextField
                source="failureReason"
                label="Failure Reason"
                sortable={false}
              />
              <DateField
                source="updated"
                label="Updated"
                sortable={true}
                showTime
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric"
                }}
              />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Card>
      </Grid>
    </Grid>
  );
};

const ExpireNowButton = ({ record, resource }: any) => {
  const refresh = useRefresh();
  const [expire, { loading, loaded }] = useMutation({
    type: "update",
    resource: resource,
    payload: {
      id: record.id,
      data: { expiryDate: new Date(Date.now() - 1000) }
    }
  });

  useEffect(() => {
    if (loaded) {
      refresh();
    }
  }, [refresh, loaded]);

  if (!record || !record.active || !record.expiryDate) {
    return null;
  }

  return (
    <RAButton
      label="Expire Now"
      onClick={expire}
      disabled={loading}
      color="default"
      variant="outlined"
      style={{ float: "right" }}
    />
  );
};

const FlagProfileButton = ({ record }: { record?: Profile }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const refresh = useRefresh();
  // Existing segments
  const memberSegments = record?.segments;

  const [update, { loading, loaded }] = useMutation({
    type: "update",
    resource: "member",
    payload: {
      id: record?.id,
      data: { segments: memberSegments?.concat(["flagged-account"]) }
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (loaded) {
      refresh();
      handleClose();
    }
  }, [refresh, handleClose, loaded]);

  return (
    <div>
      {record &&
        record.segments &&
        record?.segments.indexOf("flagged-account") > -1 && (
          <Button
            size="small"
            className={classes.flaggedButton}
            startIcon={<FlagIcon />}
          >
            Flagged
          </Button>
        )}
      {record &&
        record.segments &&
        record?.segments.indexOf("flagged-account") === -1 && (
          <>
            <Tooltip
              className={classes.tooltip}
              placement="top"
              title="Flag Profile"
              aria-label="flag-profile"
              onClick={handleOpen}
            >
              <Button
                size="small"
                className={classes.flagButton}
                startIcon={<FlagIcon />}
              >
                Flag
              </Button>
            </Tooltip>
            <Dialog
              open={open}
              fullWidth={true}
              maxWidth={"sm"}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                className={classes.dialogTitle}
                id="form-dialog-title"
              >
                Flag Profile
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1">
                  Are you sure you wish to flag this profile?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  disabled={loading}
                  color="primary"
                >
                  No, Cancel
                </Button>
                <Button onClick={update} disabled={loading} color="primary">
                  Yes, Flag
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
    </div>
  );
};

export const ProfileShow = (props: ResourceComponentPropsWithId) => (
  <Show {...props} title={<ProfileTitle />}>
    <TabbedShowLayout>
      <Tab label="Profile">
        <ProfileShowSummaryTab />
      </Tab>
      <Tab label="Subscription Details">
        <ProfileShowCurrentSubscriptionStatus {...props} />
        <ProfileShowSubscriptionStatusChanges {...props} />
        <ProfileShowVideoEntitlementsChanges {...props} />
        <ProfileShowGiftcardsRedeemed {...props} />
      </Tab>

      <Tab label="Payment History">
        <ProfileShowPaymentHistory {...props} />
      </Tab>

      <Tab label="Activity">
        <ProfileShowActivitySummary />
        <ProfileShowDevicesSummary />
        <ProfileShowViewingHistorySummary />
      </Tab>

      <Tab label="Communications">
        {/* Add in email / sms rejected bools */}
        <ProfileShowCommunicationLog />
        {/* <ProfileShowSummaryTab /> */}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ProfileCreate = (props: {}) => {
  return (
    <Create {...props} title={<ProfileTitle />} successMessage="User Created">
      <SimpleForm redirect="/member" toolbar={<PostEditToolbar />}>
        <TextInput label="First Name" source="firstName" />
        <TextInput label="Last Name" source="lastName" />
        <SelectInput
          source="countryCode"
          choices={COUNTRY_CODES}
          optionText={optionRenderer}
          optionValue="phone"
        />
        <TextInput label="Phone Number" source="phoneNumber" />
        <TextInput label="E-Mail" source="email" />
        <TextInput label="Password" source="password" />
        <ReferenceArrayInput
          label="Segments"
          source="segments"
          reference="tag"
          filter={{ type: "member" }}
        >
          <SelectArrayInput optionText="tag" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const ProfileEdit = (props: {}) => {
  return (
    <Edit {...props} title={<ProfileTitle />} successMessage="User Updated">
      <EditForm />
    </Edit>
  );
};

const EditForm = (props: any = {}) => {
  const { record } = props;
  const notify = useNotify();
  const redirect = useRedirect();

  const resetPassword = () => {
    const provider = new ProfileDataProvider();
    if (record !== undefined && record?.email !== undefined) {
      provider
        .resetPassword(record.email)
        .then(() => {
          // success side effects go here
          redirect("/member");
          notify(`Password Reset email sent to ${record.email}`);
        })
        .catch(error => {
          // failure side effects go here
          notify(
            `Failed to send Reset Password email: ${error.message}`,
            "warning"
          );
        });
    }
  };

  return (
    <SimpleForm {...props} redirect="/member" toolbar={<PostEditToolbar />}>
      <TextInput label="First Name" source="firstName" />
      <TextInput label="Last Name" source="lastName" />
      <SelectInput
        source="countryCode"
        choices={COUNTRY_CODES}
        optionText={optionRenderer}
        optionValue="phone"
      />
      <TextInput label="Phone Number" source="phoneNumber" />
      <TextInput label="E-Mail" source="email" />
      <BooleanInput label="Terms Accepted" source="termsAccepted" />
      <BooleanInput
        label="Email Marketing Rejected"
        source="emailMarketingRejected"
      />
      <BooleanInput
        label="SMS Marketing Rejected"
        source="smsMarketingRejected"
      />
      <ReferenceArrayInput
        label="Segments"
        source="segments"
        reference="tag"
        filter={{ type: "member" }}
      >
        <SelectArrayInput optionText="tag" />
      </ReferenceArrayInput>
      {record?.email && (
        <Button variant="contained" color="primary" onClick={resetPassword}>
          Reset User Password
        </Button>
      )}
    </SimpleForm>
  );
};

const optionRenderer = (choice: any) => `${choice.label} +${choice.phone}`;

export const ProfileIcon = icon;

const AddMemberStatusChangeButton = ({ record }: { record?: Profile }) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const [memberStatuses, setMemberStatuses] = React.useState<MemberStatus[]>();
  const [
    expiryDate,
    setExpiryDate
  ] = React.useState<MaterialUiPickersDate | null>(null);
  const [memberStatusId, setMemberStatusId] = React.useState("standard");

  const refresh = useRefresh();
  const [create, { loading, loaded }] = useMutation({
    type: "create",
    resource: "member-status-change",
    payload: {
      data: { memberId: record?.id, expiryDate, memberStatusId }
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (loaded) {
      refresh();
      handleClose();
    }
  }, [refresh, handleClose, loaded]);

  useEffect(() => {
    if (open && !memberStatuses) {
      dataProvider
        .getList<MemberStatus>("member-status", {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "order", order: "ASC" },
          filter: {}
        })
        .then(({ data }: { data: MemberStatus[] }) => {
          setMemberStatuses(data);
        });
    }
  }, [open, memberStatuses, dataProvider]);

  const onChangeDate = (date: MaterialUiPickersDate) => {
    setExpiryDate(date);
  };

  const onChangeMemberStatusId = (event: React.ChangeEvent<string> | any) => {
    setMemberStatusId(event.target.value);
  };

  const canSave = expiryDate && memberStatusId && memberStatusId.length > 0;

  return (
    <div>
      <Button onClick={handleClickOpen} color="primary">
        Add Status Change
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select a status and desired expiry time below.
          </DialogContentText>

          <InputLabel>Status</InputLabel>
          <Select
            id="memberStatusId"
            value={memberStatusId}
            onChange={onChangeMemberStatusId}
            fullWidth={true}
            margin="dense"
            style={{ marginBottom: "1em" }}
            disabled={!memberStatuses}
          >
            {memberStatuses &&
              memberStatuses.map(m => (
                <MenuItem key={m.id} value={m.id}>
                  {m.name}
                </MenuItem>
              ))}
          </Select>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              clearable
              label="Expiry Date"
              value={expiryDate}
              onChange={onChangeDate}
              fullWidth={true}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} color="primary">
            Cancel
          </Button>
          <Button
            onClick={create}
            disabled={loading || !canSave}
            color="primary"
          >
            Add Status Change
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AddVideoEntitlementButton = ({ record }: { record?: Profile }) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = React.useState<Product[]>();
  const [
    expiryDate,
    setExpiryDate
  ] = React.useState<MaterialUiPickersDate | null>(null);
  const [productId, setProductId] = React.useState<string | null>(null);

  const refresh = useRefresh();
  const [create, { loading, loaded }] = useMutation({
    type: "create",
    resource: "video-tag-entitlement",
    payload: {
      data: { memberId: record?.id, expiryDate, productId }
    }
  });

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (loaded) {
      refresh();
      handleClose();
    }
  }, [loaded, refresh, handleClose]);

  useEffect(() => {
    if (open && !products) {
      dataProvider
        .getList<Product>("product", {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "name", order: "ASC" },
          filter: {}
        })
        .then(({ data }: { data: Product[] }) => {
          setProducts(data);
        });
    }
  }, [open, dataProvider, products]);

  const onChangeDate = (date: MaterialUiPickersDate) => {
    setExpiryDate(date);
  };

  const onChangeProductId = (event: React.ChangeEvent<string> | any) => {
    setProductId(event.target.value);
  };

  const canSave = expiryDate && productId && productId.length > 0;

  return (
    <div>
      <Button onClick={handleClickOpen} color="primary">
        Add Video Entitlement
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select a status and desired expiry time below.
          </DialogContentText>

          <InputLabel>Product</InputLabel>
          <Select
            id="productId"
            value={productId}
            onChange={onChangeProductId}
            fullWidth={true}
            margin="dense"
            style={{ marginBottom: "1em" }}
            disabled={!products}
          >
            {products &&
              products.map(m => (
                <MenuItem key={m.id} value={m.id}>
                  {m.name}
                </MenuItem>
              ))}
          </Select>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              clearable
              label="Expiry Date"
              value={expiryDate}
              onChange={onChangeDate}
              fullWidth={true}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} color="primary">
            Cancel
          </Button>
          <Button
            onClick={create}
            disabled={loading || !canSave}
            color="primary"
          >
            Add Entitlement
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
