import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import { useGetMany, LinearProgress } from "react-admin";
import { Device, VideoViewSession } from "../../entities/Profile";
import ActiveIcon from "@material-ui/icons/PlayArrow";
import InactiveIcon from "@material-ui/icons/Stop";
import { Video } from "../../entities/Catalog";
import { secsToMinString } from "../../utils";

const VideoViewSessionTable = ({
  sessions
}: {
  sessions: VideoViewSession[];
}) => {
  const deviceIds = sessions.reduce((ids, ses: VideoViewSession) => {
    if (!ids.includes(ses.deviceId)) ids.push(ses.deviceId);
    return ids;
  }, [] as string[]);

  const videoIds = sessions.reduce((ids, ses: VideoViewSession) => {
    if (!ids.includes(ses.videoId)) ids.push(ses.videoId);
    return ids;
  }, [] as string[]);

  const { data: devices, loading: devicesLoading } = useGetMany(
    "device",
    deviceIds
  );

  const { data: videos, loading: videosLoading } = useGetMany(
    "video",
    videoIds
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Video</TableCell>
          <TableCell>Device</TableCell>
          <TableCell>View Time</TableCell>
          <TableCell>Estimated Watched Minutes</TableCell>
          <TableCell>Start / Finish</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sessions.map((session: VideoViewSession) => {
          const device: Device | undefined =
            !devicesLoading && devices
              ? ((devices.find(
                  d => d.id === session.deviceId
                ) as unknown) as Device)
              : undefined;

          const video: Video | undefined =
            !videosLoading && videos
              ? ((videos.find(
                  v => v.id === session.videoId
                ) as unknown) as Video)
              : undefined;

          return (
            <TableRow key={session.id}>
              <TableCell>
                {session.active ? <ActiveIcon /> : <InactiveIcon />}
              </TableCell>
              <TableCell>{video ? video.name : <LinearProgress />}</TableCell>
              <TableCell>
                {device ? (
                  `${device.platform[0].toUpperCase()}${device.platform.slice(
                    1,
                    device.platform.length
                  )} - ${device.platformVersion} (${device.ip})`
                ) : (
                  <LinearProgress />
                )}
              </TableCell>
              <TableCell>
                {dayjs(session.createdDate).format("LTS")} -{" "}
                {dayjs(session.currentPositionUpdateDate).format("LTS")}
              </TableCell>
              <TableCell>{session.currentPositionUpdateCount / 2}</TableCell>
              <TableCell>
                {secsToMinString(session.startPosition)} -{" "}
                {session.active
                  ? "Ongoing"
                  : secsToMinString(session.currentPosition)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default VideoViewSessionTable;
