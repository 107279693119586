import React from "react";
import { Video } from "../../../../../entities/Catalog";
import { useDataProvider, useGetOne, useRefresh } from "react-admin";
import { IUserMediaItem } from "@booyaltd/core";
import { Box, Typography } from "@material-ui/core";
import ThumbManagerMediaItemSummary from "./ThumbManagerMediaItemSummary";
import ThumbManagerUploader from "./ThumbManagerUploader";

type ThumbManagerPanelProps = {
  video: Video;
};

const ThumbManagerPanel = ({ video }: ThumbManagerPanelProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [editing, setEditing] = React.useState(!video.thumbnailMediaId);

  const { data: mediaItem } = useGetOne<IUserMediaItem>(
    "media-item",
    String(video.thumbnailMediaId),
    {
      enabled: !!video.thumbnailMediaId
    }
  );

  const onMediaIdChanged = (newMediaId: string) => {
    return dataProvider.update("video", {
      id: video.id,
      previousData: video,
      data: {
        ...video,
        thumbnailMediaId: newMediaId
      }
    });
  };

  const triggerRefresh = () => {
    refresh();
  };

  const onBeginUpload = () => {
    setEditing(true);
  };

  return (
    <Box width="100%" flex={1} display="flex" flexDirection="column">
      <Typography variant="h5">Thumbnail</Typography>
      {!editing ? (
        <ThumbManagerMediaItemSummary
          video={video}
          mediaItem={mediaItem}
          onBeginUpload={onBeginUpload}
        />
      ) : (
        <ThumbManagerUploader
          onMediaIdChanged={onMediaIdChanged}
          triggerRefresh={triggerRefresh}
        />
      )}
    </Box>
  );
};

export default ThumbManagerPanel;
