import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Show,
  TextInput,
  Filter,
  ShowButton,
  ReferenceManyField,
  SimpleShowLayout,
  ReferenceField,
  FunctionField,
  DateField
} from "react-admin";
import icon from "@material-ui/icons/Payment";
import { Payment } from "../entities/Subscription";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Profile } from "../entities/Profile";

const useStyles = makeStyles({
  pos: {
    marginBottom: 6
  }
});

const PaymentFilter = (props: {}) => (
  <Filter {...props}>
    <TextInput label="Member Status" source="memberStatusId" alwaysOn />
    <TextInput label="Status" source="status" alwaysOn />
    <TextInput label="Payment Provider" source="paymentProvider" alwaysOn />
    <TextInput label="Failure Reason" source="failureReason" alwaysOn />
  </Filter>
);

export const PaymentList = (props: {}) => (
  <List
    filters={<PaymentFilter />}
    title="Payments"
    bulkActionButtons={false}
    sort={{ field: "updated", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="Payment Id" sortable={false} />
      <TextField
        source="memberStatusId"
        sortable={false}
        label="Member Status"
      />
      <ReferenceField
        label="Name"
        source="memberId"
        reference="member"
        sortable={false}
        linkType="show"
      >
        <FunctionField<Profile>
          label="Name"
          render={(profile?: Profile) =>
            `${profile?.firstName} ${profile?.lastName}`
          }
        />
      </ReferenceField>
      <TextField source="currency" sortable={false} />
      <TextField source="cost" sortable={false} />
      <TextField source="paymentProvider" sortable={false} />
      <TextField source="paymentProviderReference" sortable={false} />
      <TextField source="status" sortable={false} />
      <TextField source="failureReason" sortable={false} />
      <DateField source="updated" sortable={true} showTime />
      <ShowButton />
    </Datagrid>
  </List>
);

const PaymentTitle = ({ record }: { record?: Payment }) => {
  return <span>Payment: {record ? `${record.id}` : ""}</span>;
};

export const PaymentSummary = ({ record }: { record?: Payment }) => {
  const classes = useStyles();
  if (!record) {
    return null;
  }
  return (
    <Box display="flex">
      <Box flexWrap="wrap">
        <Card>
          <CardContent>
            <Typography variant={"body2"} color={"textSecondary"}>
              Payment Id
            </Typography>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              className={classes.pos}
            >
              {record?.id}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"}>
              Member Status
            </Typography>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              className={classes.pos}
            >
              {record?.memberStatusId}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"}>
              Member Status Price Id
            </Typography>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              className={classes.pos}
            >
              {record?.productId}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"}>
              Member Id
            </Typography>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              className={classes.pos}
            >
              {record?.memberId}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"}>
              Cost
            </Typography>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              className={classes.pos}
            >
              {record?.cost}
              {record?.currency}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"}>
              Status
            </Typography>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              className={classes.pos}
            >
              {record?.status}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"}>
              Provider
            </Typography>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              className={classes.pos}
            >
              {record?.paymentProvider}
            </Typography>
            {record?.failureReason && (
              <Typography variant={"body2"} color={"textSecondary"}>
                Failure Reason
              </Typography>
            )}
            {record?.failureReason && (
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.failureReason}
              </Typography>
            )}
            {record?.paymentProviderReference && (
              <Typography variant={"body2"} color={"textSecondary"}>
                Provider Reference
              </Typography>
            )}
            {record?.paymentProviderReference && (
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.paymentProviderReference}
              </Typography>
            )}
            {record?.paymentProviderRawPayload && (
              <>
                <Typography variant={"body2"} color={"textSecondary"}>
                  Payment Provider Raw Payload
                </Typography>

                <pre style={{ whiteSpace: "pre-wrap", maxWidth: "90vw" }}>
                  {record && record.paymentProviderRawPayload
                    ? JSON.stringify(
                        JSON.parse(record.paymentProviderRawPayload),
                        null,
                        2
                      )
                    : ""}
                </pre>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export const PaymentShow = (props: {}) => {
  return (
    <Show {...props} title={<PaymentTitle />}>
      <SimpleShowLayout>
        <PaymentSummary />
      </SimpleShowLayout>
    </Show>
  );
};

export const PaymentDetails = (props: {}) => {
  return (
    <Show {...props} title={<PaymentTitle />}>
      <SimpleShowLayout>
        <ReferenceManyField reference="payments" target="id" label="Payment">
          <PaymentSummary />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export const PaymentIcon = icon;
