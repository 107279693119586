import React from "react";
import { Box, Card, CardContent, CardHeader } from "@material-ui/core";

type PlaylistMediaShow = {
  thumbnailMediaUrl: string | null;
  thumbnailTransparentMediaUrl: string | null;
  posterHorizontalMediaUrl: string | null;
  posterVerticalMediaUrl: string | null;
};

const PlaylistMediaShow = ({
  thumbnailMediaUrl,
  thumbnailTransparentMediaUrl,
  posterHorizontalMediaUrl,
  posterVerticalMediaUrl
}: PlaylistMediaShow) => {
  return (
    <>
      <Box display="flex">
        {thumbnailMediaUrl && (
          <Box flex={1}>
            <Card>
              <CardHeader
                title={"Thumbnail"}
                titleTypographyProps={{ variant: "h6" }}
              />
              <CardContent
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <img
                  alt={"Thumbnail"}
                  src={thumbnailMediaUrl}
                  style={{
                    width: "auto",
                    height: "auto",
                    maxHeight: "300px",
                    maxWidth: "100%"
                  }}
                />
              </CardContent>
            </Card>
          </Box>
        )}
        {thumbnailTransparentMediaUrl && (
          <Box flex={1}>
            <Card>
              <CardHeader
                title={"Thumbnail (Transparent BG)"}
                titleTypographyProps={{ variant: "h6" }}
              />
              <CardContent
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <img
                  alt={"Thumbnail (Transparent BG)"}
                  src={thumbnailTransparentMediaUrl}
                  style={{
                    width: "auto",
                    height: "auto",
                    maxHeight: "300px",
                    maxWidth: "100%"
                  }}
                />
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
      <Box display="flex">
        {posterHorizontalMediaUrl && (
          <Box flex={1}>
            <Card>
              <CardHeader
                title={"Poster (H)"}
                titleTypographyProps={{ variant: "h6" }}
              />
              <CardContent
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <img
                  alt={"Playlist Poster Horizontal"}
                  src={posterHorizontalMediaUrl}
                  style={{
                    width: "auto",
                    height: "auto",
                    maxHeight: "300px",
                    maxWidth: "100%"
                  }}
                />
              </CardContent>
            </Card>
          </Box>
        )}

        {posterVerticalMediaUrl && (
          <Box flex={1}>
            <Card>
              <CardHeader
                title={"Poster (V)"}
                titleTypographyProps={{ variant: "h6" }}
              />
              <CardContent
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <img
                  alt={"Playlist Poster Vertical"}
                  src={posterVerticalMediaUrl}
                  style={{
                    width: "auto",
                    height: "auto",
                    maxHeight: "300px",
                    maxWidth: "100%"
                  }}
                />
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PlaylistMediaShow;
