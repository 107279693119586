import React from "react";
import { CloudUploadOutlined } from "@material-ui/icons";
import { observer } from "mobx-react";
import { Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { container } from "tsyringe";
import MultipartUploadStore, { Upload } from "../../mobx/MultipartUploadStore";
import UploadProgress from "./UploadProgress";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    color: theme.palette.common.white
  }
}));

const MultipartUploadStatusRow = observer(
  ({ id, upload }: { id: string; upload: Upload }) => {
    return (
      <Box display="flex" flex={1} flexDirection="column">
        {upload.stats ? (
          <UploadProgress
            uploadState={upload.state}
            mediaItem={upload.mediaItem}
            stats={upload.stats}
          />
        ) : null}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          px={1}
        >
          {upload.state === "uploading" ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => container.resolve(MultipartUploadStore).cancel(id)}
            >
              Cancel
            </Button>
          ) : upload.state === "paused" ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => container.resolve(MultipartUploadStore).resume(id)}
            >
              Resume
            </Button>
          ) : null}
        </Box>
      </Box>
    );
  }
);

const MultipartUploadStatusIcon = observer(() => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const store = container.resolve(MultipartUploadStore);

  return (
    <>
      {Object.keys(store.uploads).length > 0 ? (
        <IconButton
          onClick={() => setOpen(!open)}
          className={classes.iconButton}
        >
          <CloudUploadOutlined />
        </IconButton>
      ) : null}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        disableBackdropClick
        aria-label="Upload Manager"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>Upload Manager</DialogTitle>

        <DialogContent>
          {Object.keys(store.uploads).map(uploadId => (
            <MultipartUploadStatusRow
              key={uploadId}
              id={uploadId}
              upload={store.uploads[uploadId]}
            />
          ))}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default MultipartUploadStatusIcon;
