import React from "react";
import {
  composeValidators,
  Create,
  maxLength,
  minLength,
  email,
  required,
  AutocompleteInput,
  SimpleForm,
  TextInput
} from "react-admin";
import { COUNTRY_CODES } from "../../constants";

const optionRenderer = (choice: any) => `${choice.label} +${choice.phone}`;

const GiftcardApplicationCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm resource="giftcard-application" redirect="list">
        <TextInput
          source="name"
          validate={composeValidators(required(), minLength(3), maxLength(255))}
        />
        <TextInput
          source="email"
          validate={composeValidators(required(), email(), maxLength(255))}
        />
        <AutocompleteInput
          source="countryCode"
          choices={COUNTRY_CODES}
          optionText={optionRenderer}
          optionValue="code"
          matchSuggestion={(
            filter: string,
            { phone }: { phone: string; label: string }
          ) => {
            console.log("filter", filter, "phone", phone);
            return (
              phone.indexOf(filter) === 0 || phone.indexOf(`+${filter}`) === 0
            );
          }}
          validate={composeValidators(required())}
        />
        <TextInput
          source="phoneNumber"
          validate={composeValidators(required(), minLength(3), maxLength(11))}
        />
        <TextInput
          source="evidenceLink"
          validate={composeValidators(maxLength(255))}
        />
      </SimpleForm>
    </Create>
  );
};

export default GiftcardApplicationCreate;
