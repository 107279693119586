import React from "react";
import { IUserMediaItem, IUserMediaStatus } from "@booyaltd/core";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import MediaStatusAlert from "./MediaStatusAlert";

type VideoManagerMediaItemSummaryProps = {
  mediaItem: IUserMediaItem;
  downloadUrl: string | undefined;
  onBeginUpload: () => void;
};

const mediaItemStatusMessage = (
  mediaItem?: IUserMediaItem
): string | JSX.Element => {
  switch (mediaItem?.status) {
    case undefined:
      return "No video uploaded";
    case "error":
      return "Previous upload failed. Please try again.";
    case "pending-upload":
      return (
        <>
          <strong>Upload is being processed.</strong>
          <br />
          If you see this message for more than a few minutes, please retry
          upload
        </>
      );
    case "processing":
      return (
        <>
          <strong>Video is being processed.</strong>
          <br />
          This will take a few minutes, please refresh the page to check the
          status.
        </>
      );
    case "ready":
      return "Uploaded successfully.";
    case "deleted":
      return "Deleted";
    default:
      return "";
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginTop: theme.spacing(2)
  }
}));

const VideoManagerMediaItemSummary = ({
  mediaItem,
  downloadUrl,
  onBeginUpload
}: VideoManagerMediaItemSummaryProps) => {
  useStyles();

  const isUploaded = [
    IUserMediaStatus.Processing,
    IUserMediaStatus.Ready,
    IUserMediaStatus.Uploaded
  ].includes(mediaItem?.status);

  return (
    <Box display="flex" flexDirection="column" mt={1} borderRadius={15}>
      <MediaStatusAlert
        ready={isUploaded}
        desc={mediaItemStatusMessage(mediaItem)}
        openLinks={
          isUploaded && downloadUrl
            ? [{ label: "Download Original", url: downloadUrl }]
            : []
        }
        actions={
          isUploaded
            ? [
                {
                  label: "Re-upload",
                  onClick: onBeginUpload
                }
              ]
            : [
                {
                  label: "Upload",
                  onClick: onBeginUpload
                }
              ]
        }
        error={
          mediaItem?.status === "error"
            ? mediaItem.statusReason || "Unknown Error"
            : undefined
        }
      />
    </Box>
  );
};

export default VideoManagerMediaItemSummary;
