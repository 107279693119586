import React from "react";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { Video } from "../../../../../entities/Catalog";
import { TagIcon } from "../../../../TagResource";
import VideoSearchTermsEditorModalButton from "./VideoSearchTermsEditorModalButton";
import VideoSoftLinksEditorModalButton from "./VideoSoftLinksEditorModalButton";
import VideoMetadataEditorModalButton from "./VideoMetadataEditorModalButton";

const ChipArray = (props: { tags: string[] }) => {
  return (
    <Grid container spacing={1}>
      {props.tags.map(tag => (
        <Grid item key={tag}>
          <Chip label={tag} icon={<TagIcon />} />
        </Grid>
      ))}
    </Grid>
  );
};

const VideoShowSearchTab = ({ record: video }: { record?: Video }) => {
  if (!video) {
    return null;
  }

  const { searchTerms, softLinks, metadata } = video;

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column" flex={1} p={2} mr={4}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5">Search Terms</Typography>
          <VideoSearchTermsEditorModalButton video={video} />
        </Box>
        <ChipArray tags={searchTerms} />
      </Box>
      <Box display="flex" flexDirection="column" flex={1} p={2} mx={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5">Soft Links</Typography>
          <VideoSoftLinksEditorModalButton video={video} />
        </Box>
        <ChipArray tags={softLinks} />
      </Box>
      <Box display="flex" flexDirection="column" flex={1} p={2} ml={4}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5">Free Text Keywords</Typography>
          <VideoMetadataEditorModalButton video={video} />
        </Box>
        <Typography variant="h6">{metadata}</Typography>
      </Box>
    </Box>
  );
};

export default VideoShowSearchTab;
