import React, { useState } from "react";
import { Create, SimpleForm } from "react-admin";
import InboxNotificationForm from "./InboxNotificationForm";
import { InboxNotification } from "../../entities/Notification";
import { Box, Button } from "@material-ui/core/";
import InboxNotificationCreateYoutubeButton from "./InboxNotificationCreateYoutubeButton";

const InboxNotificationCreate = (props: any) => {
  const [defaults, setDefaults] = useState<Partial<InboxNotification>>();

  if (!defaults) {
    return (
      <Box>
        <InboxNotificationCreateYoutubeButton setDefaultsInForm={setDefaults} />
        <Button onClick={() => setDefaults({})}>Other</Button>
      </Box>
    );
  }

  return (
    <Create {...props}>
      <SimpleForm
        resource="inbox-notification"
        redirect="list"
        initialValues={defaults}
      >
        <InboxNotificationForm />
      </SimpleForm>
    </Create>
  );
};

export default InboxNotificationCreate;
