import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  EditButton,
  FormWithRedirect,
  DeleteButton,
  Edit,
  SaveButton,
  TextInput,
  Create,
  Filter,
  ShowButton,
  ChipField,
  NumberField,
  NumberInput,
  BooleanField,
  BooleanInput,
  SelectInput,
  Pagination
} from "react-admin";
import { Channel } from "../entities/Catalog";
import icon from "@material-ui/icons/TvSharp";
import ThumbnailField from "../components/fields/ThumbnailField";
import {
  Box,
  Card,
  CardContent,
  Chip,
  FormControlLabel,
  makeStyles,
  Switch,
  Toolbar,
  Typography
} from "@material-ui/core";
import { useField } from "react-final-form";
import { VideoSort, VideoType } from "@booyaltd/core";
import { VideoDataGrid } from "./Video/VideoList";
import { TextContainsFilter } from "../components/filters/TextFilters";
import PlaylistMediaInputs from "../components/inputs/PlaylistMediaInputs";
import PlaylistMediaShow from "../components/fields/PlaylistMediaShow";
import TagCreateSelectInput from "../components/inputs/TagCreateSelectInput";
import PermissionGuard from "../components/guards/PermissionGuard";

const SORT_OPTIONS: { [key in VideoSort]: string | false } = {
  [VideoSort.PublishDateAsc]: "Publish Date (Asc)",
  [VideoSort.PublishDate]: "Publish Date (Desc)",
  [VideoSort.CaptureDateAsc]: "Capture Date (Asc)",
  [VideoSort.CaptureDate]: "Capture Date (Desc)",
  [VideoSort.VideoLength]: "Video Length (Acs)",
  [VideoSort.VideoLengthDesc]: "Video Length (Desc)",
  [VideoSort.Popularity]: "Popularity",
  [VideoSort.CustomOrder]: "Custom Order Field",
  [VideoSort.Relevance]: false,
  [VideoSort.FavouritedDate]: false,
  [VideoSort.FavouritedCount]: false,
  [VideoSort.WatchedDate]: false
};

const ChannelFilter = (props: {}) => (
  <Filter {...props}>
    <TextContainsFilter label="Name" source="name" alwaysOn />
    <TextContainsFilter label="Video Tag" source="tag" alwaysOn />
  </Filter>
);

export const ChannelList = (props: {}) => (
  <List
    filters={<ChannelFilter />}
    sort={{ field: "order", order: "asc" }}
    bulkActionButtons={false}
    exporter={false}
    {...props}
  >
    <Datagrid>
      <ThumbnailField
        source="thumbnailMediaUrl"
        sortable={false}
        label="Thumb"
      />
      <ThumbnailField
        source="thumbnailTransparentMediaUrl"
        sortable={false}
        label="Thumb (Transparent BG)"
      />
      <ThumbnailField
        source="posterHorizontalMediaUrlThumb"
        sortable={false}
        label="Poster (H)"
      />
      <ThumbnailField
        source="posterVerticalMediaUrlThumb"
        sortable={false}
        label="Poster (V)"
      />
      <TextField source="name" sortable={false} />
      <ChipField source="tag" label="Video Tag" sortable={false} />
      <NumberField sortable={true} source="order" />
      <BooleanField sortable={false} source="active" />
      <PermissionGuard role="admin">
        <EditButton />
      </PermissionGuard>
      <ShowButton />
    </Datagrid>
  </List>
);

const ChannelTitle = ({ record }: { record?: Channel }) => {
  return <span>Channel: {record ? `${record.name}` : ""}</span>;
};

const useStyles = makeStyles({
  root: {
    flex: 1,
    margin: "1em"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

const Summary = ({ record }: { record?: Channel }) => {
  const classes = useStyles();

  if (!record) {
    return null;
  }

  const {
    active,
    order,
    name,
    description,
    tag,
    includePreviewVideos,
    defaultVideoSort,
    thumbnailMediaUrl,
    thumbnailTransparentMediaUrl,
    posterHorizontalMediaUrl,
    posterVerticalMediaUrl
  } = record;
  return (
    <Box>
      <Box display="flex">
        <Box flex={1}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Channel Details
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Name
              </Typography>
              <Typography variant="body2" component="p">
                {name}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Description
              </Typography>
              <Typography variant="body2" component="p">
                {description}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Is Active
              </Typography>
              <Typography variant="body2" component="p">
                <Chip
                  label={active ? "Yes" : "No"}
                  variant="outlined"
                  color={active ? "primary" : "secondary"}
                />
              </Typography>
            </CardContent>
          </Card>
        </Box>

        <Box flex={1}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Video Settings
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Show videos with tag
              </Typography>
              <Typography variant="body2" component="p">
                <Chip variant="outlined" label={tag} />
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Order videos by
              </Typography>
              <Typography variant="body2" component="p">
                {defaultVideoSort}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Show preview videos
              </Typography>
              <FormControlLabel
                control={
                  <Switch checked={includePreviewVideos} disabled={true} />
                }
                label={includePreviewVideos ? "Yea" : "No"}
              />
              <Typography className={classes.pos} color="textSecondary">
                Order
              </Typography>
              <Typography variant="body2" component="p">
                {order}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>

      <PlaylistMediaShow
        thumbnailMediaUrl={thumbnailMediaUrl}
        thumbnailTransparentMediaUrl={thumbnailTransparentMediaUrl}
        posterHorizontalMediaUrl={posterHorizontalMediaUrl}
        posterVerticalMediaUrl={posterVerticalMediaUrl}
      />
    </Box>
  );
};

const VideoList = ({ record, ...props }: { record?: Channel }) => {
  if (!record) {
    return null;
  }

  return (
    <List
      {...props}
      basePath="/video"
      resource="video"
      hasCreate={false}
      hasShow={true}
      hasEdit={false}
      perPage={1000}
      pagination={<Pagination />}
      exporter={false}
      filter={{
        type: VideoType.Episode,
        channelId: record.id
      }}
      sort={{ field: "masterReleaseDate", order: "desc" }}
    >
      <VideoDataGrid />
    </List>
  );
};

export const ChannelShow = (props: {}) => {
  return (
    <Show {...props} title={<ChannelTitle />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <Summary />
        </Tab>

        <Tab label="Videos">
          <VideoList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const ChannelEdit = (props: {}) => (
  <Edit {...props} title={<ChannelTitle />}>
    <ChannelForm />
  </Edit>
);

export const ChannelCreate = (props: {}) => (
  <Create {...props} title={<ChannelTitle />}>
    <ChannelForm />
  </Create>
);

const ChannelForm = (props: any = {}) => (
  <FormWithRedirect
    {...props}
    render={(formProps: any = {}) => {
      const {
        input: { value: colorPrimary }
      } = useField("colorPrimary");
      const {
        input: { value: colorSecondary }
      } = useField("colorSecondary");

      return (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Channel
                </Typography>
                <Box display="flex" mb="1em">
                  <Box pt="0.75em">
                    <BooleanInput
                      label="Active"
                      source="active"
                      defaultValue={true}
                      fullWidth
                    />
                  </Box>
                  <Box flex={2} ml="1em">
                    <TextInput label="Name" source="name" fullWidth />
                  </Box>
                  <Box flex={4} ml="1em">
                    <TextInput
                      label="Description"
                      source="description"
                      fullWidth
                    />
                  </Box>
                  <Box flex={2} ml="1em">
                    <Box flexDirection="row">
                      <TextInput
                        source="colorPrimary"
                        label="Primary Color (HEX)"
                      />
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: colorPrimary
                        }}
                      />
                    </Box>
                    <Box flexDirection="row">
                      <TextInput
                        source="colorSecondary"
                        label="Secondary Color (HEX)"
                      />
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: colorSecondary
                        }}
                      />
                    </Box>
                  </Box>

                  <Box ml="1em">
                    <NumberInput label="Order" source="order" />
                  </Box>
                </Box>

                <Typography variant="h6" gutterBottom>
                  Video Settings
                </Typography>

                <Box display="flex" flex={1} mb="1em">
                  <TagCreateSelectInput />
                  <Box flex={1} ml="1em">
                    <SelectInput
                      source="defaultVideoSort"
                      label="Sort videos by"
                      choices={Object.keys(SORT_OPTIONS)
                        // @ts-ignore
                        .filter(opt => !!SORT_OPTIONS[opt.toString()])
                        .map(opt => ({
                          id: opt.toString(),
                          // @ts-ignore
                          name: SORT_OPTIONS[opt.toString()].toString()
                        }))}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="1em" pt="0.75em">
                    <BooleanInput
                      source="includePreviewVideos"
                      label="Include preview videos"
                      fullWidth
                    />
                  </Box>
                </Box>

                <PlaylistMediaInputs />
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                redirect="list"
              />
              <DeleteButton
                record={formProps.record}
                resource={formProps.resource}
              />
            </Box>
          </Toolbar>
        </form>
      );
    }}
  />
);

export const ChannelIcon = icon;
