import React from "react";
import { Box, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { CloudUploadOutlined, VideoCallOutlined } from "@material-ui/icons";
import {
  IUserMediaContentType,
  IUserMediaItem,
  IUserMediaItemType
} from "@booyaltd/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useNotify } from "react-admin";
import ImageDataProvider from "../../../../../providers/data/ImageDataProvider";

type ThumbManagerUploaderProps = {
  onMediaIdChanged: (mediaId: string) => void;
  triggerRefresh: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneRoot: {
    display: "flex",
    flex: 1,
    minHeight: "auto",
    padding: theme.spacing(1),
    width: "100%",
    border: 0
  },
  dropzoneTextContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: theme.spacing(2),
    borderRadius: 15,
    borderStyle: "dashed",
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    borderWidth: 2,
    width: "100%"
  },
  dropzoneText: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    margin: 0,
    padding: 0
  }
}));

export type UploadState =
  | "idle"
  | "initialising"
  | "uploading"
  | "done"
  | "error";

const ThumbManagerUploader = ({
  onMediaIdChanged,
  triggerRefresh
}: ThumbManagerUploaderProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const [uploadState, setUploadState] = React.useState<UploadState>("idle");
  const [media, setMedia] = React.useState<IUserMediaItem | null>(null);

  const onDrop = (files: File[]) => {
    if (!files || files.length !== 1) {
      return;
    }

    const file = files[0];

    const imageDataProvider = new ImageDataProvider();

    setUploadState("initialising");
    imageDataProvider
      .createUserMediaItem({
        type: IUserMediaItemType.VideoThumbnail,
        contentType: file.type as IUserMediaContentType,
        contentLength: file.size,
        filename: file.name
      })
      .then(({ uploadUrl, item: mediaItem }) => {
        setUploadState("uploading");
        setMedia(mediaItem);
        onMediaIdChanged(mediaItem.id);

        return fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type
          }
        }).then(() => {
          setUploadState("done");
          notify("Upload finished, please wait . . .", "success");

          setTimeout(() => {
            triggerRefresh();
          }, 5000);
        });
      })
      .catch(e => {
        setUploadState("error");
        notify("Error uploading thumbnail", "error");
        console.error("Error uploading thumbnail", e);
      });
  };

  return (
    <Box
      width="100%"
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Box width="100%" flex={1} display="flex" flexDirection="column" p={2}>
        {uploadState !== "idle" ? (
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            p={2}
            border={1}
            borderColor="grey.300"
            bgcolor="grey.100"
            borderRadius={15}
          >
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <CloudUploadOutlined fontSize="large" />
              <Box display="flex" flexDirection="column" flex={1} ml={2}>
                <Typography variant="h6">
                  {media?.filename || " . . . "}
                </Typography>
                <Typography variant="body2">{status}</Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <DropzoneArea
            classes={{
              root: classes.dropzoneRoot,
              textContainer: classes.dropzoneTextContainer,
              text: classes.dropzoneText
            }}
            showPreviews={false}
            showPreviewsInDropzone={false}
            showAlerts={false}
            dropzoneText={`Drop image file or click here`}
            // @ts-ignore
            Icon={VideoCallOutlined}
            onChange={onDrop}
            filesLimit={1}
            acceptedFiles={[
              IUserMediaContentType.JPEG,
              IUserMediaContentType.PNG
            ]}
            maxFileSize={1024 * 1024 * 1024 * 10}
          />
        )}
      </Box>
    </Box>
  );
};

export default ThumbManagerUploader;
