import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { API_HOST } from "../env";

export default abstract class Provider {
  protected axios: AxiosInstance;
  constructor() {
    this.axios = Axios.create({
      baseURL: API_HOST,
      timeout: 10000
    });

    this.axios.interceptors.request.use((req: AxiosRequestConfig) => {
      const jwt = localStorage.getItem("jwt");
      if (jwt) {
        req.headers["Authorization"] = `Bearer ${jwt}`;
      }

      return req;
    });

    this.axios.interceptors.response.use(
      response => response,
      err => {
        if (err.response && err.response.status === 400) {
          return Promise.reject(err.response.data);
        }

        return Promise.reject(err);
      }
    );
  }
}
