/* eslint-disable react/display-name */
import React, { useState } from "react";
import {
  GridRenderCellParams,
  GridRenderEditCellParams
} from "@mui/x-data-grid";
import {
  IUserMediaContentType,
  IUserMediaItemType,
  IUserMediaStatus
} from "@booyaltd/core";
import ImageDataProvider from "../../../providers/data/ImageDataProvider";
import Dropzone from "react-dropzone";
import { Typography } from "@material-ui/core";

export const renderMediaEdit = (
  urlColumn: string,
  type: IUserMediaItemType,
  notify: (title: string, type: "error") => void
) => (params: GridRenderEditCellParams) => {
  const field = params.field;
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>(params.row[urlColumn]);

  const onDrop = (files: File[]) => {
    if (!files || files.length !== 1) {
      return;
    }

    const file = files[0];

    const imageDataProvider = new ImageDataProvider();

    setLoading(true);
    imageDataProvider
      .createUserMediaItem({
        type: type,
        contentType: files[0].type as IUserMediaContentType,
        contentLength: files[0].size,
        filename: files[0].name
      })
      .then(({ uploadUrl, item: mediaItem }) => {
        return fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type
          }
        }).then(async r => {
          await new Promise(resolve => setTimeout(resolve, 1500));

          for (let i = 1; i <= 4; i++) {
            await new Promise(resolve => setTimeout(resolve, 1500 * i));
            const media = await imageDataProvider
              .getUserMediaItem(mediaItem.id)
              .catch(() => undefined);
            if (
              !media ||
              !media.item ||
              media.item.status !== IUserMediaStatus.Ready
            ) {
              continue;
            }

            if (media?.item?.id) {
              params.api.setEditCellValue({
                id: params.id,
                field,
                value: media.item.id
              });
            }

            if (media?.sizeUrls?.thumb) {
              setUrl(media.sizeUrls.thumb);
            }

            return r;
          }
        });
      })
      .catch(e => {
        notify("Upload error", "error");
        console.error("Error uploading file", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dropzone
      onDrop={onDrop}
      maxFiles={1}
      accept={[IUserMediaContentType.PNG, IUserMediaContentType.JPEG]}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            width: "100px",
            aspectRatio: "16 / 9",
            borderColor: "black",
            borderStyle: "dashed",
            borderWidth: isDragActive ? 1 : 0
          }}
        >
          <input {...getInputProps()} />
          {loading ? (
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "center"
              }}
            >
              Uploading . . .
            </Typography>
          ) : url ? (
            <>
              <img
                style={{
                  width: "100%",
                  aspectRatio: "16 / 9",
                  opacity: 0.5,
                  zIndex: 1
                }}
                src={url}
              />
            </>
          ) : (
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "center"
              }}
            >
              Upload
            </Typography>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export const renderMediaView = (urlColumn: string) => (
  params: GridRenderCellParams
) => {
  const url = params.row[urlColumn];

  if (!url) return null;

  return <img style={{ width: "100px", height: "auto" }} src={url} />;
};
