import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  Create,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput
} from "react-admin";
import icon from "@material-ui/icons/PermDeviceInformation";
import StringArrayChipsField from "../components/fields/StringArrayChipsField";
import { LandingPage } from "../entities/Notification";
import DateInput from "../components/inputs/DateInput";

const LandingPageFilter = (props: {}) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" alwaysOn />
  </Filter>
);

export const LandingPageList = (props: {}) => (
  <List
    filters={<LandingPageFilter />}
    sort={{ field: "activeDate", order: "DESC" }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField source="title" />
      <DateField showTime={true} source="activeDate" />
      <DateField showTime={true} source="expiryDate" />
      <TextField source="embedUrl" />
      <StringArrayChipsField
        label={"Member Segments"}
        source={"showForMemberSegments"}
        emptyValue={"All"}
      />
      <EditButton />
    </Datagrid>
  </List>
);

const LandingPageTitle = ({ record }: { record?: LandingPage }) => {
  return <span>Landing Page: {record ? `${record.title}` : ""}</span>;
};

const getExpiryDate = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return date;
};

export const LandingPageEdit = (props: {}) => (
  <Edit {...props} title={<LandingPageTitle />}>
    <SimpleForm>
      <TextInput source="title" />
      <DateInput source="activeDate" label="Active" defaultValue={new Date()} />
      <DateInput
        source="expiryDate"
        label="Expiry"
        defaultValue={getExpiryDate()}
      />
      <TextInput source="embedUrl" />
      <ReferenceArrayInput
        label="Member segments"
        source="showForMemberSegments"
        reference="tag"
        filter={{ type: "member" }}
      >
        <SelectArrayInput optionText="tag" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const LandingPageCreate = (props: {}) => (
  <Create {...props} title={<LandingPageTitle />}>
    <SimpleForm>
      <TextInput source="title" />
      <DateInput source="activeDate" label="Active" defaultValue={new Date()} />
      <DateInput
        source="expiryDate"
        label="Expiry"
        defaultValue={getExpiryDate()}
      />
      <TextInput source="embedUrl" />
      <ReferenceArrayInput
        label="Member segments"
        source="showForMemberSegments"
        reference="tag"
        filter={{ type: "member" }}
      >
        <SelectArrayInput optionText="tag" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export const LandingPageIcon = icon;
