import React from "react";
import { AutocompleteInput, required, useGetList } from "react-admin";
import { useFormState } from "react-final-form";
import { CircularProgress } from "@material-ui/core";

const TemplateInput = () => {
  const {
    values: { type }
  } = useFormState();
  const { ids, loading } = useGetList(
    "notification-template",
    { page: 1, perPage: 1000 },
    { field: "id", order: "ASC" },
    type ? { types: `%${type}%` } : undefined
  );

  if (loading || !ids) {
    return <CircularProgress size={20} />;
  }

  return (
    <AutocompleteInput
      source="template"
      validate={required()}
      choices={ids.map(id => ({ id, name: id }))}
    />
  );
};

export default TemplateInput;
