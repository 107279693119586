import React from "react";
import { CardActions } from "@material-ui/core";
import { MemberStatus } from "../entities/Subscription";
import icon from "@material-ui/icons/Subscriptions";
import StringArrayChipsField from "../components/fields/StringArrayChipsField";

import {
  TextField,
  ReferenceField,
  List,
  TabbedShowLayout,
  ReferenceManyField,
  Show,
  Tab,
  ShowButton,
  NumberField,
  BooleanField,
  Datagrid,
  CreateButton
} from "react-admin";

const SubscriptionActions = ({ basePath }: { basePath?: string }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const SubscriptionShow = (props: {}) => {
  return (
    <Show {...props} title={<SubscriptionTitle />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" />
          <TextField source="description" />
          <StringArrayChipsField
            source="entitledVideoTags"
            label={"Entitled Video Tags"}
          />
          <NumberField
            sortable={true}
            source="concurrentDevicesLoggedIn"
            label="# of logged in devices"
          />
          <BooleanField source="canUpgrade" />
          <StringArrayChipsField
            source="canUpgradeToStatuses"
            label={"Can Upgrade To Statuses"}
          />
          <TextField source="downgradeToStatusAfterEntitlement" />
          <NumberField source="defaultEntitlementPeriodValue" />
          <TextField source="defaultEntitlementPeriodUnit" />
          <TextField source="autoAssignMemberTag" />
          <BooleanField source="isDefault" />
          <NumberField source="order" />
        </Tab>
        <Tab label="Products">
          <ReferenceManyField
            reference="product"
            target="memberStatusId"
            label="Products"
          >
            <Datagrid>
              <ReferenceField
                label="Subscription"
                source="memberStatusId"
                reference="subscription-status"
              >
                <TextField source="id" />
              </ReferenceField>
              <TextField source="name" />
              <TextField source="description" />
              <TextField source="stripeReference" />
              <TextField source="paypalReference" />
              <NumberField
                source="entitlementPeriodValue"
                label="Entitlement Period"
              />
              <TextField source="entitlementPeriodUnit" label="" />
              <TextField source="currency" />
              <NumberField source="cost" />
              <BooleanField source="public" />
              <NumberField source="order" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const SubscriptionList = (props: {}) => {
  return (
    <List
      actions={<SubscriptionActions />}
      sort={{ field: "order", order: "ASC" }}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="Status" sortable={false} />
        <TextField source="description" sortable={false} />
        <StringArrayChipsField
          source="entitledVideoTags"
          label={"Entitled Video Tags"}
          sortable={false}
        />
        <NumberField
          sortable={true}
          source="concurrentDevicesLoggedIn"
          label="# of logged in devices"
        />
        <BooleanField sortable={true} source="canUpgrade" label="Can Upgrade" />
        <StringArrayChipsField
          source="canUpgradeToStatuses"
          label={"Can Upgrade To"}
          sortable={false}
        />
        <TextField
          sortable={false}
          source="downgradeToStatusAfterEntitlement"
          label="Auto Downgrade To"
        />
        <TextField source="autoAssignMemberTag" label="Member Tag" />
        <BooleanField sortable={true} source="isDefault" />
        <NumberField sortable={true} source="order" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const SubscriptionTitle = ({ record }: { record?: MemberStatus }) => {
  return <span>Subscription: {record ? `${record.id}` : ""}</span>;
};

export const SubscriptionIcon = icon;
