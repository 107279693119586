import React from "react";
import { Video } from "@booyaltd/core";
import MediaStatusAlert from "./MediaStatusAlert";
import VideoPreviewModal from "../../../../../components/video/VideoPreviewModal";
import { Typography } from "@material-ui/core";

type VideoProviderManagerProps = {
  video: Video;
  type: "master" | "preview";
  onReprocess: (mediaId: string) => void;
};

const VideoProviderManager = ({
  video,
  type,
  onReprocess
}: VideoProviderManagerProps) => {
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const { origId, provider, providerId, providerReady } =
    type === "master"
      ? {
          origId: video.masterOrigMediaId,
          provider: video.masterProvider,
          providerId: video.masterProviderId,
          providerReady: video.masterProviderReady
        }
      : {
          origId: video.previewOrigMediaId,
          provider: video.previewProvider,
          providerId: video.previewProviderId,
          providerReady: video.previewProviderReady
        };

  const onClickWatch = () => {
    setPreviewOpen(true);
  };

  if (!origId && !providerReady) {
    return null;
  }

  return (
    <>
      <Typography variant="h5">
        {type === "master"
          ? "Master Streaming Provider"
          : "Preview Streaming Provider"}
      </Typography>
      <MediaStatusAlert
        ready={providerReady}
        desc={
          provider
            ? providerId
              ? providerReady
                ? `Streaming Provider: ${provider}, video is ready`
                : `Streaming Provider: ${provider}, video is not ready`
              : `Streaming Provider: ${provider}, video not sent to provider`
            : "No provider"
        }
        openLinks={
          providerReady ? [{ label: "Watch", onClick: onClickWatch }] : []
        }
        actions={
          origId
            ? [{ label: "Reprocess", onClick: () => onReprocess(origId) }]
            : []
        }
      />
      <VideoPreviewModal
        open={previewOpen}
        videoId={video.id}
        type={type}
        onClose={() => setPreviewOpen(false)}
      />
    </>
  );
};

export default VideoProviderManager;
