import { Avatar } from "@material-ui/core";
import React from "react";

const ThumbnailField = ({
  record,
  source
}: {
  record?: { [key: string]: string };
  label: string;
  source: string;
  sortable?: boolean;
}) => (record ? <Avatar variant="rounded" src={record[source]} /> : null);

export default ThumbnailField;
