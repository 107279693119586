import React from "react";
import { Link } from "react-admin";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

type VideoWarningProps = {
  type: "warning" | "error";
  title: string;
  desc: string;
  actions: Array<{ label: string; url: string }>;
};

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  link: {
    marginLeft: 10
  }
}));

const VideoWarning = ({ type, title, desc, actions }: VideoWarningProps) => {
  const classes = useStyles();
  return (
    <Alert
      severity={type}
      className={classes.alert}
      action={
        <>
          {actions.map(({ label, url }) => (
            <Link key={label} to={url}>
              <Button>{label}</Button>
            </Link>
          ))}
        </>
      }
    >
      <AlertTitle>{title}</AlertTitle>
      <Typography variant="body2">{desc}</Typography>
    </Alert>
  );
};

export default VideoWarning;
