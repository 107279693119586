import React, { Fragment, useEffect, useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  FieldProps,
  useDataProvider
} from "react-admin";
import PieChartIcon from "@material-ui/icons/PieChart";
import { ProfileSegment } from "../entities/Profile";
import { Button, CircularProgress, Typography } from "@material-ui/core/";

export const ProfileSegmentCountField = (props: FieldProps<ProfileSegment>) => {
  const [show, setShow] = useState(false);
  const [count, setCount] = useState<number>();

  const { getOne } = useDataProvider();

  useEffect(() => {
    if (show && count === undefined && props.record?.id) {
      getOne<ProfileSegment>("profile-segment", {
        id: props.record.id
      }).then(r => {
        console.log(r);
        setCount(r.data.count);
      });
    }
  }, [show]);

  if (!show) {
    return <Button onClick={() => setShow(true)}>Load Count</Button>;
  }

  if (show && count !== undefined) {
    return <Typography variant="h6">{count} Users</Typography>;
  }

  return <CircularProgress size={20} />;
};

export const ProfileSegmentList = (props: {}) => {
  return (
    <Fragment>
      <List
        sort={{ field: "name", order: "ASC" }}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <TextField source="name" label="Name" sortable={true} />
          <TextField
            source="filter"
            label="Filter (Raw SQL)"
            sortable={false}
          />
          <ProfileSegmentCountField />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export const ProfileSegmentIcon = PieChartIcon;
