import React from "react";
import { Box } from "@material-ui/core";
import UserMediaUploaderField from "./UserMediaUploaderField";
import { IUserMediaItemType } from "@booyaltd/core";

const PlaylistMediaInputs = () => {
  return (
    <>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <UserMediaUploaderField
            type={IUserMediaItemType.PlaylistThumbnail}
            source="thumbnailMediaId"
            urlField="thumbnailMediaUrl"
            label="Thumbnail"
          />
        </Box>
        <Box flex={1} mr="0.5em">
          <UserMediaUploaderField
            type={IUserMediaItemType.PlaylistThumbnailTransparent}
            source="thumbnailTransparentMediaId"
            urlField="thumbnailTransparentMediaUrl"
            label="Thumbnail (Transparent BG)"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr="1em">
          <UserMediaUploaderField
            type={IUserMediaItemType.PlaylistPosterHorizontal}
            source="posterHorizontalMediaId"
            urlField="posterHorizontalMediaUrl"
            label="Horizontal Poster (1920 x 1080)"
          />
        </Box>
        <Box flex={1} mr="1em">
          <UserMediaUploaderField
            type={IUserMediaItemType.PlaylistPosterVertical}
            source="posterVerticalMediaId"
            urlField="posterVerticalMediaUrl"
            label="Vertical Poster (1080 x 1920)"
          />
        </Box>
      </Box>
    </>
  );
};

export default PlaylistMediaInputs;
