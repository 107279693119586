import React, { useState } from "react";
import { Create, TabbedShowLayout, Tab, useNotify } from "react-admin";
import icon from "@material-ui/icons/Description";
import SendIcon from "@material-ui/icons/Send";
import {
  Button,
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  TextField
} from "@material-ui/core";
import EmailDataProvider from "../providers/data/EmailDataProvider";

function createData(
  id: any,
  title: any,
  action: any,
  type: any,
  identifier: any,
  active: boolean
) {
  return { id, title, action, type, identifier, active };
}

const TFHTrialData = [
  createData(
    "1",
    "First Registration",
    "When a user registers for a 24H Trial",
    "Email",
    "trial-registration",
    true
  ),
  createData(
    "2",
    "Trial Ending Soon",
    "When a users trial is X hours before ending ",
    "Email",
    "trial-ending-soon",
    true
  ),
  createData(
    "3",
    "Trial Elapsed",
    "24H Trial Over - Extra 24H Subscription Window to join",
    "Email",
    "trial-expired",
    true
  ),
  createData(
    "4",
    "Subscription Window Elapsed",
    "Subscription Window Over - Account Locked",
    "Email",
    "trial-locked",
    true
  ),
  createData(
    "5",
    "Follow Up",
    "Follow Up Correspondence - w/ Feedback - 7 days after",
    "Email",
    "trial-locked-feedback",
    true
  )
];

const StandardData = [
  createData(
    "1",
    "First Time Memebership",
    "When a user first purchases a FarmFLiX Membership",
    "Email",
    "standard-membership",
    true
  ),
  createData(
    "2",
    "Annual Renewal Membership Success - User",
    "When a user manually renews their FarmFLiX Membership",
    "Email",
    "user-standard-renewal",
    false
  ),
  createData(
    "3",
    "Annual Renewal Membership Success - System",
    "Automatic renewal on Annual Memberships only",
    "Email",
    "auto-standard-renewal",
    false
  ),
  createData(
    "4",
    "Annual Renewal Reminder",
    "30 day Reminder before Annual Auto Renewal",
    "Email",
    "annual-renewal-reminder",
    true
  ),
  createData(
    "5",
    "Auto Renew Enabled",
    "When a user enables their Auto Renew setting",
    "Email",
    "auto-renew-enabled",
    false
  ),
  createData(
    "6",
    "Auto Renew Disabled",
    "When a user disables their Auto Renew setting",
    "Email",
    "auto-renew-disabled",
    true
  ),
  createData(
    "7",
    "Membership Cancelled - Admin",
    "When a user requests to cancel their membership",
    "Email",
    "membership-cancel-admin",
    false
  )
];

const ExpiredData = [
  createData(
    "1",
    "Failed Payment",
    "When a users payment has failed",
    "Email",
    "standard-expired-failed-payment",
    true
  ),
  createData(
    "2",
    "Failed Payment - Final Warning",
    "When a users has one payment attempt left - final warning",
    "Email",
    "standard-expired-final-warning-payment",
    true
  ),
  createData(
    "3",
    "Failed Payment - Membership Cancelled",
    "When a users final payment attempt fails - membership cancelled",
    "Email",
    "standard-expired-membership-cancelled",
    true
  ),
  createData(
    "4",
    "Membership Reminder",
    "Reminder email that they can get their membership going again at any time",
    "Email",
    "standard-expired-reminder",
    true
  )
];

const LockedData = [
  createData(
    "1",
    "Membership Locked",
    "When a users FarmFLiX Membership becomes locked",
    "Email",
    "locked",
    false
  )
];

const GeneralData = [
  createData(
    "1",
    "Forgot Password",
    "Forgot Password email containing 6 digit code for reset",
    "Email",
    "forgot-password",
    false
  ),
  createData(
    "2",
    "Watch Anywhere",
    "Watch options / advertise the App",
    "Email",
    "watch-anywhere",
    true
  )
];

const BuildList = (data: any) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailType, setEmailType] = useState("");
  const [emailIdentifer, setEmailIdentifer] = useState("");

  const sentTestEmail = (to: string, template: string) => {
    const provider = new EmailDataProvider();
    provider
      .sendEmail(to, template)
      .then(() => {
        setOpen(false);
        // success side effects go here
        notify(`Test email sent successfully!`);
      })
      // eslint-disable-next-line
      .catch((error) => {
        // failure side effects go here
        notify(`Failed to send test email`);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendEmailPreview = (identifier: any) => {
    setOpen(true);
    setEmailType(identifier.title);
    setEmailIdentifer(identifier.identifier);
  };

  const handleSendTestEmail = () => {
    console.log(emailAddress);
    sentTestEmail(emailAddress, emailIdentifer);
  };

  const handleTestEmail = (e: any) => {
    setEmailAddress(e.target.value);
  };

  return (
    <>
      <Grid container>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell component="th">
                  <b>Title</b>
                </TableCell>
                <TableCell component="th">
                  <b>Actioned</b>
                </TableCell>
                <TableCell component="th">
                  <b>Type</b>
                </TableCell>
                <TableCell component="th">
                  <b>ID</b>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell component="td" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {row.action}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {row.identifier}
                  </TableCell>
                  <TableCell component="td" align="right">
                    {row.active && (
                      <Button
                        color="primary"
                        startIcon={<SendIcon />}
                        onClick={() => sendEmailPreview(row)}
                      >
                        Send Test Email
                      </Button>
                    )}
                    {!row.active && (
                      <Button
                        color="primary"
                        disabled
                        startIcon={<SendIcon />}
                        onClick={() => sendEmailPreview(row)}
                      >
                        Send Test Email
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Dialog onClose={handleClose} open={open} maxWidth={"md"}>
        {emailIdentifer && emailType && (
          <>
            <DialogTitle>Send &apos;{emailType}&apos; Test Email</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="body2">Enter email address</Typography>
                <TextField
                  fullWidth
                  id="filled-basic"
                  label="Email Address"
                  variant="filled"
                  onChange={handleTestEmail}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSendTestEmail}>Send</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export const CommsShow = (props: {}) => (
  <Create {...props} title={"Automated Comms"}>
    <TabbedShowLayout>
      <Tab label="24H Free Trial">
        <BuildList data={TFHTrialData} />
      </Tab>
      <Tab label="Standard Members">
        <BuildList data={StandardData} />
      </Tab>
      <Tab label="Expired Members">
        <BuildList data={ExpiredData} />
      </Tab>
      <Tab label="Locked Members">
        <BuildList data={LockedData} />
      </Tab>
      <Tab label="General">
        <BuildList data={GeneralData} />
      </Tab>
    </TabbedShowLayout>
  </Create>
);

export const CommsIcon = icon;
