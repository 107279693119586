import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useRef } from "react";
import { Player } from "./player";
import { VideoDataProvider } from "../../providers/data/BasicCrudDataProviders";
import { DetailedVideoSearchResult, VideoSearchResult } from "@booyaltd/core";

type VideoPreviewModalProps = {
  open: boolean;
  videoId: string;
  type: "master" | "preview";
  onClose: () => void;
};

const VideoPreviewModal = ({
  open,
  videoId,
  type,
  onClose
}: VideoPreviewModalProps) => {
  const dataProvider = useRef(new VideoDataProvider()).current;
  const [video, setVideo] = React.useState<VideoSearchResult>();

  useEffect(() => {
    if (!open) {
      setVideo(undefined);
      return;
    }

    console.log("VideoPreviewModal: fetching video");
    dataProvider
      .getOne({ id: videoId, type })
      .then(res => setVideo(res.data))
      .catch(err => console.error("Error loading video", err));
  }, [videoId, type, open]);

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="video-title"
    >
      <DialogTitle id="video-title">
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!video ? (
          <CircularProgress />
        ) : video ? (
          <Player video={video as DetailedVideoSearchResult} type={type} />
        ) : (
          <Typography>Error loading video</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoPreviewModal;
