import React, { ChangeEvent, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import {
  IUserMediaContentType,
  IUserMediaItem,
  IUserMediaItemType
} from "@booyaltd/core";
import ImageDataProvider from "../../../../../providers/data/ImageDataProvider";
import { useNotify } from "react-admin";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { container } from "tsyringe";
import MultipartUploadStore from "../../../../../mobx/MultipartUploadStore";

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneRoot: {
    display: "flex",
    flex: 1,
    minHeight: "auto",
    padding: theme.spacing(1),
    width: "100%",
    border: 0
  },
  dropzoneTextContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: theme.spacing(2),
    borderRadius: 15,
    borderStyle: "dashed",
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    borderWidth: 2,
    width: "100%"
  },
  dropzoneText: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    margin: 0,
    padding: 0
  },
  cancelButton: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: theme.spacing(1)
  }
}));

type VideoOriginalManagerProps = {
  onMediaChanged: (media: IUserMediaItem) => void;
  onCancel?: () => void;
};

const VideoOriginalUploader = observer(
  ({ onMediaChanged, onCancel }: VideoOriginalManagerProps) => {
    const [loading, setLoading] = useState(false);
    const store = container.resolve(MultipartUploadStore);
    const classes = useStyles();
    const notify = useNotify();
    const [file, setFile] = useState<File>();

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || event.target.files.length !== 1) {
        return;
      }

      setFile(event.target.files[0]);
    };

    const startUpload = () => {
      if (!file) {
        return;
      }

      setLoading(true);

      const imageDataProvider = new ImageDataProvider();

      imageDataProvider
        .createUserMediaItem({
          type: IUserMediaItemType.CatalogVideo,
          contentType: IUserMediaContentType.MP4,
          contentLength: file.size,
          filename: file.name
        })
        .then(
          ({ item, bucket, key, region, s3Endpoint, multipartSigningKey }) => {
            onMediaChanged(item);
            notify("Upload Authorized", "success");
            return store.startUpload(file, item, {
              bucket,
              key,
              region,
              s3Endpoint,
              multipartSigningKey
            });
          }
        )
        .catch(e => {
          notify("Error initialising upload", "error");
          console.error("Error initialising upload", e);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <Box
        width="100%"
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Box width="100%" flex={1} display="flex" flexDirection="column" p={2}>
          {loading ? (
            <Typography variant="h6">Beginning Upload...</Typography>
          ) : (
            <Box
              flex={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <input type="file" onChange={onFileChange} accept="video/mp4" />

              <Button
                onClick={startUpload}
                disabled={!file}
                variant="outlined"
                color="primary"
              >
                Upload
              </Button>
            </Box>
          )}
          {onCancel ? (
            <Button className={classes.cancelButton} onClick={onCancel}>
              Cancel
            </Button>
          ) : null}
        </Box>
      </Box>
    );
  }
);

export default VideoOriginalUploader;
