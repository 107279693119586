import IDataProvider from "./IDataProvider";
import ProfileDataProvider from "./ProfileDataProvider";
import {
  NotificationPreferenceDataProvider,
  GiftcardApplicationDataProvider,
  UserMediaItemDataProvider,
  ScheduledNotificationDataProvider
} from "./BasicCrudDataProviders";
import GiftcardDataProvider from "./GiftcardDataProvider";
import {
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteResult,
  GetManyReferenceResult,
  UpdateManyParams,
  UpdateManyResult,
  CreateResult,
  GetListResult,
  GetManyResult,
  GetOneResult,
  UpdateResult
} from "react-admin";
import AdminAutoCrudDataProvider, {
  SearchSynonymDataProvider
} from "./AdminAutoCrudDataProvider";
import {
  AutocompletePhrase,
  ChannelResponse,
  InboxNotificationResponse,
  LandingPage,
  NotificationTemplate,
  PlaylistResponse,
  PromoItem,
  UserProfileSegmentSnapshot,
  Video,
  VideoSearchHistoryFlattened,
  NotificationLog,
  Product,
  Payment
} from "@booyaltd/core";
import { Tag } from "../../entities/Catalog";
import { DailySMSUsage } from "../../entities/DailySMSUsage";
import {
  DailyUserActivity,
  Device,
  ProfileSegment,
  UserWatchHistory,
  VideoViewSession
} from "../../entities/Profile";
import {
  MemberStatusChange,
  VideoTagEntitlement
} from "../../entities/Subscription";

const providers: { [key: string]: IDataProvider<any> } = {
  "landing-page": new AdminAutoCrudDataProvider<LandingPage>({
    queryField: "title",
    path: "landing-pages"
  }),
  "inbox-notification": new AdminAutoCrudDataProvider<
    InboxNotificationResponse
  >({
    queryField: "title",
    path: "inbox-notifications"
  }),
  "member-status": new AdminAutoCrudDataProvider<any>({
    queryField: "name",
    path: "member-statuses"
  }),
  product: new AdminAutoCrudDataProvider<Product>({
    queryField: "name",
    path: "products"
  }),
  payment: new AdminAutoCrudDataProvider<Payment>({
    queryField: "paymentProviderReference",
    path: "payments"
  }),
  "notification-log": new AdminAutoCrudDataProvider<NotificationLog>({
    queryField: "templateData",
    path: "notification-logs"
  }),
  "scheduled-notification": new ScheduledNotificationDataProvider(),
  "notification-template": new AdminAutoCrudDataProvider<NotificationTemplate>({
    path: "notification-templates",
    queryField: "id"
  }),

  // New style data providers
  "autocomplete-phrase": new AdminAutoCrudDataProvider<AutocompletePhrase>({
    path: "autocomplete-phrase",
    queryField: "phrase"
  }),
  "promo-item": new AdminAutoCrudDataProvider<PromoItem>({
    path: "promo-items",
    queryField: "title"
  }),
  playlist: new AdminAutoCrudDataProvider<PlaylistResponse>({
    path: "playlists",
    queryField: "name"
  }),
  channel: new AdminAutoCrudDataProvider<ChannelResponse>({
    path: "channels",
    queryField: "name"
  }),
  tag: new AdminAutoCrudDataProvider<Tag>({
    path: "tags",
    queryField: "tag"
  }),
  smsusage: new AdminAutoCrudDataProvider<DailySMSUsage>({
    path: "smsusage",
    queryField: "id"
  }),
  "search-term": new AdminAutoCrudDataProvider<Tag>({
    path: "tags",
    queryField: "tag"
  }),
  "video-search": new AdminAutoCrudDataProvider<VideoSearchHistoryFlattened>({
    path: "video-searches",
    queryField: "phrase"
  }),
  "soft-link": new AdminAutoCrudDataProvider<Tag>({
    path: "tags",
    queryField: "tag"
  }),
  device: new AdminAutoCrudDataProvider<Device>({
    path: "devices",
    queryField: "id"
  }),
  video: new AdminAutoCrudDataProvider<Video>({
    path: "videos",
    queryField: "name"
  }),
  "bulk-video": new AdminAutoCrudDataProvider<Video>({
    path: "videos",
    queryField: "name"
  }),
  "profile-segment": new AdminAutoCrudDataProvider<ProfileSegment>({
    path: "user-profile-segments",
    queryField: "filter"
  }),
  "profile-segment-snapshot": new AdminAutoCrudDataProvider<
    UserProfileSegmentSnapshot
  >({
    path: "user-profile-segment-snapshots",
    queryField: "userProfileSegmentId"
  }),
  "daily-user-activity": new AdminAutoCrudDataProvider<DailyUserActivity>({
    path: "daily-user-activity",
    queryField: "userId",
    queryFilter: ""
  }),
  "video-view-session": new AdminAutoCrudDataProvider<VideoViewSession>({
    path: "video-view-sessions",
    queryField: "userId",
    queryFilter: ""
  }),
  "watch-history": new AdminAutoCrudDataProvider<UserWatchHistory>({
    path: "user-watch-history",
    queryField: "userId",
    queryFilter: ""
  }),

  // Data providers which do something different from the basic CRUD provided for the resoureces above
  "search-synonym": new SearchSynonymDataProvider(),
  "media-item": new UserMediaItemDataProvider(),
  "member-status-change": new AdminAutoCrudDataProvider<MemberStatusChange>({
    path: "member-status-changes",
    queryField: "memberId"
  }),
  "video-tag-entitlement": new AdminAutoCrudDataProvider<VideoTagEntitlement>({
    path: "video-tag-entitlements",
    queryField: "memberId"
  }),
  giftcard: new GiftcardDataProvider(),
  "giftcard-application": new GiftcardApplicationDataProvider(),
  "notification-preference": new NotificationPreferenceDataProvider(),
  member: new ProfileDataProvider()
};

export default class DataProviderWrapper implements DataProvider {
  public getList(resource: string, params: any): Promise<GetListResult<any>> {
    return DataProviderWrapper.getDataProviderForResource(resource).getList(
      params
    );
  }

  public getOne(resource: string, params: any): Promise<GetOneResult<any>> {
    return DataProviderWrapper.getDataProviderForResource(resource).getOne(
      params
    );
  }

  public getMany(resource: string, params: any): Promise<GetManyResult<any>> {
    return DataProviderWrapper.getDataProviderForResource(resource).getMany(
      params
    );
  }

  public getManyReference(
    resource: string,
    params: any
  ): Promise<GetManyReferenceResult<any>> {
    const { target, id, ...updatedParams } = params;

    if (!updatedParams.filter) {
      updatedParams.filter = {};
    }

    updatedParams.filter[target] = id;

    return DataProviderWrapper.getDataProviderForResource(resource).getList(
      updatedParams
    );
  }

  public update(resource: string, params: any): Promise<UpdateResult<any>> {
    return DataProviderWrapper.getDataProviderForResource(resource).update(
      params
    );
  }

  public create(resource: string, params: any): Promise<CreateResult<any>> {
    return DataProviderWrapper.getDataProviderForResource(resource).create(
      params
    );
  }

  public delete(resource: string, params: any): Promise<DeleteResult<any>> {
    return DataProviderWrapper.getDataProviderForResource(resource).delete(
      params
    );
  }

  private static getDataProviderForResource(
    resource: string
  ): IDataProvider<any> {
    if (!providers[resource]) {
      throw new Error(`No data provider for resource ${resource}`);
    }

    return providers[resource];
  }

  public updateMany(
    // eslint-disable-next-line
        resource: string,
    // eslint-disable-next-line
        params: UpdateManyParams
  ): Promise<UpdateManyResult> {
    throw new Error("Not Supported");
  }

  public deleteMany(
    // eslint-disable-next-line
        resource: string,
    // eslint-disable-next-line
        params: DeleteManyParams
  ): Promise<DeleteManyResult> {
    throw new Error("Not Supported");
  }
}
