import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Show,
  ReferenceManyField,
  SimpleShowLayout,
  NumberField,
  ResourceComponentPropsWithId,
  Edit,
  EditButton,
  SimpleForm,
  BooleanInput
} from "react-admin";
import icon from "@material-ui/icons/ShoppingBasket";
import { Product } from "../entities/Subscription";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StringArrayChipsField from "../components/fields/StringArrayChipsField";

const useStyles = makeStyles({
  pos: {
    marginBottom: 6
  }
});

export const ProductList = (props: {}) => (
  <List
    title="Products"
    bulkActionButtons={false}
    sort={{ field: "updated", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="Product Id" sortable={false} />
      <TextField
        source="memberStatusId"
        sortable={false}
        label="Member Status"
      />
      <TextField source="name" sortable={false} label="Name" />
      <TextField source="description" sortable={false} label="Description" />
      <TextField source="currency" sortable={false} />
      <TextField source="cost" sortable={false} />
      <NumberField source="entitlementPeriodValue" sortable={false} />
      <TextField source="entitlementPeriodUnit" sortable={false} />
      <StringArrayChipsField
        source="entitledVideoTags"
        sortable={false}
        label="Entitled Tags"
      />
      <TextField
        source="stripeReference"
        sortable={false}
        label="Stripe Reference"
      />
      <TextField
        source="paypalReference"
        sortable={false}
        label="Paypal Reference"
      />
      <NumberField source="public" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

const ProductTitle = ({ record }: { record?: Product }) => {
  return <span>Product: {record ? `${record.id}` : ""}</span>;
};

export const ProductSummary = ({ record }: { record?: Product }) => {
  const classes = useStyles();
  if (!record) {
    return null;
  }
  return (
    <Box>
      <Box display="flex">
        <Box flexWrap="wrap">
          <Card>
            <CardContent>
              <Typography variant={"body2"} color={"textSecondary"}>
                Product Id
              </Typography>
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.id}
              </Typography>
              <Typography variant={"body2"} color={"textSecondary"}>
                Member Status
              </Typography>
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.memberStatusId}
              </Typography>
              <Typography variant={"body2"} color={"textSecondary"}>
                Entitled Video Tags
              </Typography>
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.entitledVideoTags}
              </Typography>
              <Typography variant={"body2"} color={"textSecondary"}>
                Member Status
              </Typography>
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.memberStatusId}
              </Typography>
              <Typography variant={"body2"} color={"textSecondary"}>
                Cost
              </Typography>
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.cost}
                {record?.currency}
              </Typography>
              <Typography variant={"body2"} color={"textSecondary"}>
                Stripe Reference
              </Typography>
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.stripeReference}
              </Typography>
              <Typography variant={"body2"} color={"textSecondary"}>
                Is Public
              </Typography>
              <Typography
                variant={"body2"}
                color={"textPrimary"}
                className={classes.pos}
              >
                {record?.public}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export const ProductEdit = (props: ResourceComponentPropsWithId) => {
  return (
    <Edit {...props} title={<ProductTitle />}>
      <SimpleForm>
        <BooleanInput label="Public" source="public" />
      </SimpleForm>
    </Edit>
  );
};

export const ProductDetails = (props: {}) => {
  return (
    <Show {...props} title={<ProductTitle />}>
      <SimpleShowLayout>
        <ReferenceManyField reference="products" target="id" label="Product">
          <ProductSummary />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export const ProductIcon = icon;
