import React, { Fragment, cloneElement } from "react";
import {
  Datagrid,
  List,
  DateField,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  FunctionField,
  Show,
  SaveButton,
  Toolbar,
  ResourceComponentPropsWithId,
  TopToolbar,
  sanitizeListRestProps
} from "react-admin";
import icon from "@material-ui/icons/Sms";
import { Box, Card, Divider, Grid, Typography } from "@material-ui/core";
import { CheckCircle, Error } from "@material-ui/icons";

import { formatDate, formatNiceDate } from "../../utils";

import { DailySMSUsage } from "../../entities/DailySMSUsage";
import SetDailySMSLimitsButton from "./SetDailySMSLimitsButton";

const SMSListActionButtons = ({
  className,
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
  ...rest
}: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <SetDailySMSLimitsButton />
  </TopToolbar>
);

export const DailySMSUsageList = (props: {}) => (
  <List
    exporter={false}
    title="SMS Usage"
    bulkActionButtons={false}
    perPage={20}
    actions={<SMSListActionButtons />}
    sort={{ field: "id", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <DateField
        source="date"
        label="Date"
        showTime={true}
        options={{
          year: "numeric",
          month: "long",
          day: "numeric"
        }}
      />
      <FunctionField<DailySMSUsage>
        label="UK"
        render={(record: DailySMSUsage | undefined) => {
          if (!record) return null;

          const ukPercentage = (record.ukCount / record.ukLimit) * 100;
          const ukBlocked = record.ukBlocked;

          return (
            <Box flexDirection="column" display="flex">
              <Box flexDirection="row" display="flex">
                <Typography>{ukPercentage.toFixed(0)}% &nbsp;</Typography>
                {ukBlocked || ukPercentage >= 100 ? (
                  <Error style={{ alignSelf: "center", color: "red" }} />
                ) : (
                  <>
                    {ukPercentage >= 75 ? (
                      <Error style={{ alignSelf: "center", color: "orange" }} />
                    ) : (
                      <CheckCircle
                        style={{ alignSelf: "center", color: "green" }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        }}
      />
      <FunctionField<DailySMSUsage>
        label="IE"
        render={(record: DailySMSUsage | undefined) => {
          if (!record) return null;

          const iePercentage = (record.ieCount / record.ieLimit) * 100;
          const ieBlocked = record.ieBlocked;

          return (
            <Box flexDirection="column" display="flex">
              <Box flexDirection="row" display="flex">
                <Typography>{iePercentage.toFixed(0)}% &nbsp;</Typography>
                {ieBlocked || iePercentage >= 100 ? (
                  <Error style={{ alignSelf: "center", color: "red" }} />
                ) : (
                  <>
                    {iePercentage >= 75 ? (
                      <Error style={{ alignSelf: "center", color: "orange" }} />
                    ) : (
                      <CheckCircle
                        style={{ alignSelf: "center", color: "green" }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        }}
      />
      <FunctionField<DailySMSUsage>
        label="USA/CAN"
        render={(record: DailySMSUsage | undefined) => {
          if (!record) return null;

          const usaCanPercentage =
            (record.usaCanCount / record.usaCanLimit) * 100;
          const usaCanBlocked = record.usaCanBlocked;

          return (
            <Box flexDirection="column" display="flex">
              <Box flexDirection="row" display="flex">
                <Typography>{usaCanPercentage.toFixed(0)}% &nbsp;</Typography>
                {usaCanBlocked || usaCanPercentage >= 100 ? (
                  <Error style={{ alignSelf: "center", color: "red" }} />
                ) : (
                  <>
                    {usaCanPercentage >= 75 ? (
                      <Error style={{ alignSelf: "center", color: "orange" }} />
                    ) : (
                      <CheckCircle
                        style={{ alignSelf: "center", color: "green" }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        }}
      />
      <FunctionField<DailySMSUsage>
        label="AUS"
        render={(record: DailySMSUsage | undefined) => {
          if (!record) return null;

          const ausPercentage = (record.ausCount / record.ausLimit) * 100;
          const ausBlocked = record.ausBlocked;

          return (
            <Box flexDirection="column" display="flex">
              <Box flexDirection="row" display="flex">
                <Typography>{ausPercentage.toFixed(0)}% &nbsp;</Typography>
                {ausBlocked || ausPercentage >= 100 ? (
                  <Error style={{ alignSelf: "center", color: "red" }} />
                ) : (
                  <>
                    {ausPercentage >= 75 ? (
                      <Error style={{ alignSelf: "center", color: "orange" }} />
                    ) : (
                      <CheckCircle
                        style={{ alignSelf: "center", color: "green" }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        }}
      />
      <FunctionField<DailySMSUsage>
        label="NZ"
        render={(record: DailySMSUsage | undefined) => {
          if (!record) return null;

          const nzPercentage = (record.nzCount / record.nzLimit) * 100;
          const nzBlocked = record.nzBlocked;

          return (
            <Box flexDirection="column" display="flex">
              <Box flexDirection="row" display="flex">
                <Typography>{nzPercentage.toFixed(0)}% &nbsp;</Typography>
                {nzBlocked || nzPercentage >= 100 ? (
                  <Error style={{ alignSelf: "center", color: "red" }} />
                ) : (
                  <>
                    {nzPercentage >= 75 ? (
                      <Error style={{ alignSelf: "center", color: "orange" }} />
                    ) : (
                      <CheckCircle
                        style={{ alignSelf: "center", color: "green" }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        }}
      />
      <FunctionField<DailySMSUsage>
        label="Other"
        render={(record: DailySMSUsage | undefined) => {
          if (!record) return null;

          const otherPercentage = (record.otherCount / record.otherLimit) * 100;
          const otherBlocked = record.otherBlocked;

          return (
            <Box flexDirection="column" display="flex">
              <Box flexDirection="row" display="flex">
                <Typography>{otherPercentage.toFixed(0)}% &nbsp;</Typography>
                {otherBlocked || otherPercentage >= 100 ? (
                  <Error style={{ alignSelf: "center", color: "red" }} />
                ) : (
                  <>
                    {otherPercentage >= 75 ? (
                      <Error style={{ alignSelf: "center", color: "orange" }} />
                    ) : (
                      <CheckCircle
                        style={{ alignSelf: "center", color: "green" }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        }}
      />
      <EditButton />
    </Datagrid>
  </List>
);

export const DailySMSUsageShow = (props: ResourceComponentPropsWithId) => (
  <Show {...props} title="Daily Usage Summary">
    <DailySMSUsageSummary />
  </Show>
);

const DailySMSUsageTitle = ({ record }: { record?: DailySMSUsage }) => {
  return (
    <span>SMS Usage: {record ? `${formatNiceDate(record.date)}` : ""}</span>
  );
};

export const DailySMSUsageEdit = (props: ResourceComponentPropsWithId) => {
  return (
    <Edit {...props} title={<DailySMSUsageTitle />}>
      <SimpleForm toolbar={<DailySMSEditToolbar />}>
        <Box
          display="flex"
          style={{ width: "100%" }}
          justifyContent="space-between"
        >
          <Card
            style={{ width: "30%", padding: "12px", backgroundColor: "#eee" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ fontSize: "1.4rem" }}>UK</Typography>
              <Typography style={{ fontSize: "1rem" }}>
                SENT
                <br />
                <TextField
                  textAlign="left"
                  style={{ fontSize: "2rem", fontWeight: 600 }}
                  label="Sent"
                  source="ukCount"
                />
              </Typography>

              <FunctionField<DailySMSUsage>
                label="UK"
                render={(record: DailySMSUsage | undefined) => {
                  if (!record) return null;
                  const ukPercentage = (record.ukCount / record.ukLimit) * 100;
                  return (
                    <Box flexDirection="column" display="flex">
                      <Box flexDirection="row" display="flex">
                        <Typography>
                          {ukPercentage.toFixed(0)}% &nbsp;
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
            <Divider style={{ margin: "12px 0" }} />
            <Box display="flex" justifyContent="space-between">
              <TextInput label="SMS Limit" source="ukLimit" />
              <BooleanInput label="Blocked" source="ukBlocked" />
            </Box>
          </Card>
          <Card
            style={{ width: "30%", padding: "12px", backgroundColor: "#eee" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ fontSize: "1.4rem" }}>IE</Typography>
              <Typography style={{ fontSize: "1rem" }}>
                SENT
                <br />
                <TextField
                  textAlign="left"
                  style={{ fontSize: "2rem", fontWeight: 600 }}
                  label="Sent"
                  source="ieCount"
                />
              </Typography>

              <FunctionField<DailySMSUsage>
                label="ie"
                render={(record: DailySMSUsage | undefined) => {
                  if (!record) return null;
                  const iePercentage = (record.ieCount / record.ieLimit) * 100;
                  return (
                    <Box flexDirection="column" display="flex">
                      <Box flexDirection="row" display="flex">
                        <Typography>
                          {iePercentage.toFixed(0)}% &nbsp;
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
            <Divider style={{ margin: "12px 0" }} />
            <Box display="flex" justifyContent="space-between">
              <TextInput label="SMS Limit" source="ieLimit" />
              <BooleanInput label="Blocked" source="ieBlocked" />
            </Box>
          </Card>
          <Card
            style={{ width: "30%", padding: "12px", backgroundColor: "#eee" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ fontSize: "1.4rem" }}>
                USA / <br />
                CAN
              </Typography>
              <Typography style={{ fontSize: "1rem" }}>
                SENT
                <br />
                <TextField
                  textAlign="left"
                  style={{ fontSize: "2rem", fontWeight: 600 }}
                  label="Sent"
                  source="usaCanCount"
                />
              </Typography>

              <FunctionField<DailySMSUsage>
                label="usaCan"
                render={(record: DailySMSUsage | undefined) => {
                  if (!record) return null;
                  const usaCanPercentage =
                    (record.usaCanCount / record.usaCanLimit) * 100;
                  return (
                    <Box flexDirection="column" display="flex">
                      <Box flexDirection="row" display="flex">
                        <Typography>
                          {usaCanPercentage.toFixed(0)}% &nbsp;
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
            <Divider style={{ margin: "12px 0" }} />
            <Box display="flex" justifyContent="space-between">
              <TextInput label="SMS Limit" source="usaCanLimit" />
              <BooleanInput label="Blocked" source="usaCanBlocked" />
            </Box>
          </Card>
        </Box>
        <Box
          display="flex"
          style={{ width: "100%", marginTop: "18px" }}
          justifyContent="space-between"
        >
          <Card
            style={{ width: "30%", padding: "12px", backgroundColor: "#eee" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ fontSize: "1.4rem" }}>AUS</Typography>
              <Typography style={{ fontSize: "1rem" }}>
                SENT
                <br />
                <TextField
                  textAlign="left"
                  style={{ fontSize: "2rem", fontWeight: 600 }}
                  label="Sent"
                  source="ausCount"
                />
              </Typography>

              <FunctionField<DailySMSUsage>
                label="aus"
                render={(record: DailySMSUsage | undefined) => {
                  if (!record) return null;
                  const ausPercentage =
                    (record.ausCount / record.ausLimit) * 100;
                  return (
                    <Box flexDirection="column" display="flex">
                      <Box flexDirection="row" display="flex">
                        <Typography>
                          {ausPercentage.toFixed(0)}% &nbsp;
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
            <Divider style={{ margin: "12px 0" }} />
            <Box display="flex" justifyContent="space-between">
              <TextInput label="SMS Limit" source="ausLimit" />
              <BooleanInput label="Blocked" source="ausBlocked" />
            </Box>
          </Card>
          <Card
            style={{ width: "30%", padding: "12px", backgroundColor: "#eee" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ fontSize: "1.4rem" }}>NZ</Typography>
              <Typography style={{ fontSize: "1rem" }}>
                SENT
                <br />
                <TextField
                  textAlign="left"
                  style={{ fontSize: "2rem", fontWeight: 600 }}
                  label="Sent"
                  source="nzCount"
                />
              </Typography>

              <FunctionField<DailySMSUsage>
                label="nz"
                render={(record: DailySMSUsage | undefined) => {
                  if (!record) return null;
                  const nzPercentage = (record.nzCount / record.nzLimit) * 100;
                  return (
                    <Box flexDirection="column" display="flex">
                      <Box flexDirection="row" display="flex">
                        <Typography>
                          {nzPercentage.toFixed(0)}% &nbsp;
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
            <Divider style={{ margin: "12px 0" }} />
            <Box display="flex" justifyContent="space-between">
              <TextInput label="SMS Limit" source="nzLimit" />
              <BooleanInput label="Blocked" source="nzBlocked" />
            </Box>
          </Card>
          <Card
            style={{ width: "30%", padding: "12px", backgroundColor: "#eee" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ fontSize: "1.4rem" }}>OTHER</Typography>
              <Typography style={{ fontSize: "1rem" }}>
                SENT
                <br />
                <TextField
                  textAlign="left"
                  style={{ fontSize: "2rem", fontWeight: 600 }}
                  label="Sent"
                  source="otherCount"
                />
              </Typography>

              <FunctionField<DailySMSUsage>
                label="other"
                render={(record: DailySMSUsage | undefined) => {
                  if (!record) return null;
                  const otherPercentage =
                    (record.otherCount / record.otherLimit) * 100;
                  return (
                    <Box flexDirection="column" display="flex">
                      <Box flexDirection="row" display="flex">
                        <Typography>
                          {otherPercentage.toFixed(0)}% &nbsp;
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
            <Divider style={{ margin: "12px 0" }} />
            <Box display="flex" justifyContent="space-between">
              <TextInput label="SMS Limit" source="otherLimit" />
              <BooleanInput label="Blocked" source="otherBlocked" />
            </Box>
          </Card>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

const DailySMSEditToolbar = (props: {}) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const DailySMSUsageSummary = ({ record }: { record?: DailySMSUsage }) => {
  // const classes = useStyles();

  if (!record) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography>{record.ukLimit}</Typography>
        {formatDate(record.date)}
      </Grid>
    </Grid>
  );
};

export const DailySMSUsageIcon = icon;
