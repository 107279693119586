import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  CreateButton
} from "react-admin";
import { Tag } from "../entities/Catalog";
import icon from "@material-ui/icons/LocalOffer";
import { Route } from "react-router";
import { CardActions, Drawer } from "@material-ui/core";
import { TextContainsFilter } from "../components/filters/TextFilters";

const SearchTermActions = ({ basePath }: { basePath?: string }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);
const SearchTermFilter = (props: {}) => (
  <Filter {...props}>
    <TextContainsFilter label="Search Term" source="tag" alwaysOn />
  </Filter>
);

export const SearchTermList = (props: {}) => {
  const handleClose = () => {
    window.history.back();
  };

  return (
    <Fragment>
      <List
        actions={<SearchTermActions />}
        filters={<SearchTermFilter />}
        filterDefaultValues={{ type: "search-term" }}
        sort={{ field: "tag", order: "ASC" }}
        perPage={25}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <TextField source="tag" label="Search Term" sortable={true} />
          <TextField source="group" label="Group" sortable={true} />
          <EditButton />
        </Datagrid>
      </List>
      <Route
        path="/search-term/create"
        render={() => (
          <Drawer open onClose={handleClose} anchor="right"></Drawer>
        )}
      />
      <Route
        path="/search-term/:id"
        render={({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open onClose={handleClose} anchor="right">
              {isMatch ? (
                <SearchTermEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <SearchTermCreate {...props} />
              )}
            </Drawer>
          );
        }}
      />
    </Fragment>
  );
};

const SearchTermTitle = ({ record }: { record?: Tag }) => {
  return <span>Search Term {record ? `${record.tag}` : ""}</span>;
};

export const SearchTermEdit = (props: { id: string; onCancel: () => void }) => (
  <Edit {...props} title={<SearchTermTitle />}>
    <SimpleForm>
      <TextInput label="Search Term" source="tag" />
      <TextInput label="Group" source="group" />
    </SimpleForm>
  </Edit>
);

export const SearchTermCreate = (props: {}) => (
  <Create {...props} title={<SearchTermTitle />}>
    <SimpleForm
      initialValues={{ type: "search-term", active: true, additional: false }}
    >
      <TextInput label="Search Term" source="tag" autoFocus />
      <TextInput label="Group" source="group" />
    </SimpleForm>
  </Create>
);

export const SearchTermIcon = icon;
