import React, { useCallback, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import {
  useLoading,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateLoading
} from "react-admin";
import { GiftcardApplicationDataProvider } from "../../providers/data/BasicCrudDataProviders";
import GiftcardApplication from "../../entities/Giftcard";

const GiftcardApplicationDenyButton = (props: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [reason, setReason] = useState<string>();
  const notify = useNotify();
  const refresh = useRefresh();
  const loading = useLoading();
  const { startLoading, stopLoading } = useUpdateLoading();
  const [results, setResults] = useState<GiftcardApplication[]>();
  const unselectAll = useUnselectAll(props.resource);

  const giftcardApplicationIds: string[] = props.selectedIds;

  const deny = useCallback(() => {
    if (loading) return;

    const dataProvider = new GiftcardApplicationDataProvider();
    startLoading();
    dataProvider
      .denyMany(giftcardApplicationIds, reason)
      .then(response => {
        setResults(response);
        stopLoading();
        notify(
          `Denied ${giftcardApplicationIds.length} giftcard applications`,
          "success"
        );
      })
      .catch(e => {
        console.error(
          "Error denying giftcard applications",
          JSON.stringify(e, null, 2)
        );
        notify(
          `Error approving one or more giftcard applications: ${JSON.stringify(
            e,
            null,
            2
          )}`,
          "error"
        );
      });
  }, [loading, reason, giftcardApplicationIds]);

  const onOpen = () => {
    setModalOpen(true);
    setResults(undefined);
  };

  const onClose = () => {
    setModalOpen(false);
    setResults(undefined);
    unselectAll();
    refresh();
  };

  if (giftcardApplicationIds.length <= 0) return null;

  return (
    <>
      <Button onClick={onOpen} disabled={loading}>
        Deny
      </Button>
      <Dialog onClose={onClose} open={modalOpen} maxWidth={"md"}>
        <DialogTitle>
          Deny {giftcardApplicationIds.length} giftcard application(s)
        </DialogTitle>

        {results ? (
          <DialogContent>
            <Typography variant="body2">Approval Results</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map(
                  ({
                    id,
                    name,
                    countryCode,
                    phoneNumber,
                    email,
                    status,
                    denyReason,
                    errorReason
                  }) => (
                    <TableRow key={id}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        {countryCode} {phoneNumber}
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{status}</TableCell>
                      <TableCell>
                        {errorReason || denyReason || "Application Denied"}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText>
              <Typography variant="body2">Reason</Typography>
              <Typography variant="caption">
                This will be sent to the applicant in SMS, keep it short!!
              </Typography>
              <br />
              <TextField
                id="reason"
                variant="outlined"
                value={reason}
                onChange={e => setReason(e.target.value)}
                style={{ width: 300 }}
              />
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {results ? (
            <Button onClick={onClose} disabled={loading}>
              Close
            </Button>
          ) : (
            <>
              <Button
                onClick={deny}
                disabled={loading || !reason || reason.length === 0}
              >
                Deny
              </Button>
              <Button
                onClick={deny}
                disabled={loading || !!(reason && reason.length > 0)}
              >
                Deny (without SMS)
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GiftcardApplicationDenyButton;
