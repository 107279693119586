import Provider from "../Provider";
import { Image } from "../../entities/Catalog";
import { AxiosResponse } from "axios";
import {
  AdminCreateMediaResponse,
  CreateMediaRequest,
  GetMediaResponse
} from "@booyaltd/core";

const blobToBase64 = async function(blob: Blob): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = function() {
      const dataUrl = reader.result as string;
      const base64 = dataUrl.split(",")[1];
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });
};

export default class ImageDataProvider extends Provider {
  async create(image: File | string): Promise<Image> {
    return this.axios
      .post<any, AxiosResponse<Image>>(
        "/images",
        typeof image === "string"
          ? { url: image }
          : {
              image: await blobToBase64(image),
              imageName: image.name,
              imageType: image.type
            }
      )
      .then(r => r.data);
  }

  async createUserMediaItem(
    req: CreateMediaRequest
  ): Promise<AdminCreateMediaResponse> {
    return this.axios
      .post<AdminCreateMediaResponse>("/user-media", req)
      .then(r => r.data);
  }

  async getUserMediaItem(id: string): Promise<GetMediaResponse> {
    return this.axios
      .get<GetMediaResponse>(`/user-media/${id}`)
      .then(r => r.data);
  }
}
