import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  composeValidators,
  DateTimeInput,
  maxLength,
  minLength,
  NumberInput,
  ReferenceInput,
  regex,
  required,
  SelectArrayInput,
  TextInput
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { InboxNotification } from "../../entities/Notification";
import {
  MEMBER_STATUS_LOCKED,
  MEMBER_STATUS_STANDARD,
  MEMBER_STATUS_STANDARD_EXPIRED,
  MEMBER_STATUS_TRIAL,
  MEMBER_STATUS_TRIAL_EXPIRED
} from "@booyaltd/core";
import { IUserMediaItemType } from "@booyaltd/core";
import UserMediaUploaderField from "../../components/inputs/UserMediaUploaderField";

export const InboxNotificationTitle = ({
  record
}: {
  record?: InboxNotification;
}) => {
  return (
    <span>
      Inbox Notification: {record && record.title ? `${record.title}` : "New"}
    </span>
  );
};

const InboxNotificationForm = () => {
  return (
    <Box padding={2}>
      <Box display="flex" flex={1} flexDirection="row">
        <Box flex={1} margin={2}>
          <Typography variant="h4">Notification Content</Typography>
          <TextInput
            source="title"
            validate={composeValidators(
              required(),
              minLength(3),
              maxLength(255)
            )}
            fullWidth
          />
          <TextInput
            source="message"
            rows={5}
            multiline
            validate={composeValidators(
              required(),
              minLength(3),
              maxLength(255)
            )}
            fullWidth
          />
          <TextInput
            label="Firebase Click Event"
            source="clickEvent"
            validate={composeValidators(
              minLength(3),
              maxLength(20),
              regex(
                /^[a-z_]{3,20}$/,
                "Only use lowercase letters or underscore ( _ ), e.g. merch_click"
              )
            )}
            fullWidth
          />
          <TextInput
            source="linkUrl"
            validate={composeValidators(minLength(3), maxLength(255))}
            fullWidth
          />

          <ReferenceInput
            label="Embed Video"
            source="videoId"
            reference="video"
            filter={{ masterProviderReady: true }}
            sort={{ field: "masterReleaseDate", order: "DESC" }}
            perPage={1000}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText="name"
              fullWidth
              clearAlwaysVisible={true}
            />
          </ReferenceInput>

          <UserMediaUploaderField
            type={IUserMediaItemType.PlaylistPosterHorizontal}
            source="imageKey"
            urlField="imageUrl"
            label="Video Thumb"
          />
        </Box>
        <Box flex={1} marginX={2}>
          <Typography variant="h4">Member Targeting</Typography>

          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            justifyContent="space-evenly"
          >
            <DateTimeInput source="activeDate" validate={required()} />

            <DateTimeInput source="expiryDate" validate={required()} />
          </Box>

          <SelectArrayInput
            label="Only show to member statuses"
            source="showForMemberStatuses"
            choices={[
              { id: MEMBER_STATUS_STANDARD, name: "Standard Member" },
              {
                id: MEMBER_STATUS_STANDARD_EXPIRED,
                name: "Standard Expired Member"
              },
              { id: MEMBER_STATUS_TRIAL, name: "Trial Member" },
              { id: MEMBER_STATUS_TRIAL_EXPIRED, name: "Trial Expired Member" },
              { id: MEMBER_STATUS_LOCKED, name: "Locked" }
            ]}
            optionText="name"
            allowEmpty
            fullWidth
            helperText={"Leave blank to show to all members"}
          />

          <Typography variant="caption">
            These variables are used for delay or limit a notification being
            shown to the above member statuses. These will have no effect if the
            above member statuses are not set
          </Typography>

          <Typography variant="caption">
            E.g. If you the member status = Trial Expired, Delay = 1, Limit = 3,
            then the notification will only be shown to Trial Expired members
            while member is in 24-72 hour window after their trial
          </Typography>
          <Box display="flex" flexDirection="row" flex={1}>
            <NumberInput
              source="memberStatusDelayDays"
              defaultValue={0}
              fullWidth
            />
            <NumberInput
              source="memberStatusLimitDays"
              defaultValue={0}
              fullWidth
            />
          </Box>
          <BooleanInput
            source="autoSendToMemberStatusAfterDelay"
            label="Auto Send To Member After Delay"
            defaultValue={false}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};

export default InboxNotificationForm;
