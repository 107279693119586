import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  ListProps,
  ReferenceField,
  FunctionField,
  useUpdate,
  FilterProps,
  Filter,
  AutocompleteInput
} from "react-admin";
import {
  NotificationLog,
  NotificationLogStatus
} from "../../entities/Notification";
import { Profile } from "../../entities/Profile";
import Icon from "@material-ui/icons/History";

const NotificationlogRequeueButton = ({
  record
}: {
  record?: NotificationLog;
}) => {
  const [requeue, { loading }] = useUpdate(
    "notification-log",
    record?.id || "",
    { status: NotificationLogStatus.Pending.toString() },
    { status: record?.status || "" }
  );

  if (!record || record.status === NotificationLogStatus.Pending) return null;

  return (
    <Button onClick={() => requeue()} disabled={loading}>
      Re-Queue
      {loading && <CircularProgress size={20} />}
    </Button>
  );
};

export const NotificationlogDataGrid = () => (
  <Datagrid>
    <TextField source="type" />
    <ReferenceField label="User" source="userId" reference="member" link="show">
      <FunctionField<Profile>
        render={record => `${record?.firstName} ${record?.lastName}`}
      />
    </ReferenceField>
    <TextField source="template" />
    <TextField source="status" />
    <TextField source="failureReason" />
    <DateField showTime source="scheduledTime" />
    <DateField showTime source="sentTime" />
    <DateField showTime source="created" />
    <NotificationlogRequeueButton />
  </Datagrid>
);

const NotificationLogListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <AutocompleteInput
      alwaysOn
      source="type"
      choices={[
        { id: "email", name: "Email" },
        { id: "push", name: "Push" },
        { id: "sms", name: "SMS" }
      ]}
    />
    <AutocompleteInput
      alwaysOn
      source="template"
      choices={[
        { id: "latest-episode", name: "latest-episode" },
        { id: "inactivity-notification", name: "inactivity-notification" }
      ]}
    />
    <AutocompleteInput
      alwaysOn
      source="status"
      choices={[
        { id: "pending", name: "Pending" },
        { id: "sent", name: "Sent" },
        { id: "opened", name: "Opened" },
        { id: "failed", name: "Failed" },
        { id: "bounced", name: "Bounced" },
        { id: "complaint", name: "Complaint" }
      ]}
    />
  </Filter>
);

export const NotificationLogList = (props: ListProps) => (
  <List {...props} filters={<NotificationLogListFilters />}>
    <NotificationlogDataGrid />
  </List>
);

export const NotificationLogIcon = Icon;
