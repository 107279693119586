import React, { Fragment } from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateProps,
  Datagrid,
  DateField,
  List,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  Button,
  DeleteButton
} from "react-admin";
import { Grid, Typography } from "@material-ui/core/";
import { Link } from "react-router-dom";

export const NotificationPreferenceCreate = (props: CreateProps) => {
  const userIdRgx = /userId=([0-9]+)/;
  const userIdRgxMatch = userIdRgx.exec(window.location.search);

  if (!userIdRgxMatch) return null;

  const userId = parseInt(userIdRgxMatch[1]);

  const redirect = `/member/${userId}/show/4`;

  return (
    <Create {...props}>
      <SimpleForm
        resource="notification-preference"
        defaultValue={{
          userId
        }}
        redirect={redirect}
        style={{ flexGrow: 1 }}
      >
        <ReferenceInput
          source="userId"
          reference="member"
          validate={required()}
        >
          <SelectInput optionText="firstName" disabled />
        </ReferenceInput>
        <Typography variant="caption">
          Select `all` from the dropdown below to disable ALL notifications
        </Typography>
        <ReferenceInput
          source="template"
          reference="notification-template"
          validate={required()}
        >
          <SelectInput
            onBlur={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            optionText="id"
            error={false}
          />
        </ReferenceInput>

        {/** @ts-ignore */}
        <Grid fullWidth container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="caption">
              By enabling / disabled this sliders below, you are enabling /
              disabling notifications using the template selected above from
              being sent to the member.
            </Typography>
            <Typography variant="caption">
              The below sliders are disabled by default as this is the most
              common action.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              label="Sms"
              source="allowedSms"
              defaultChecked={true}
              style={{ flexGrow: 1 }}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              label="Email"
              source="allowedEmail"
              defaultChecked={true}
              style={{ flexGrow: 1 }}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              label="Push"
              source="allowedPush"
              defaultChecked={true}
              style={{ flexGrow: 1 }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const NotificationPreferenceActions = () => {
  const userIdRgx = /member\/([0-9]+)/;
  const userIdRgxMatch = userIdRgx.exec(window.location.pathname);

  if (!userIdRgxMatch) return null;

  return (
    <Button
      component={Link}
      to={`/notification-preference/create?userId=${userIdRgxMatch[1]}`}
      label="Set Notification Preference"
      title="Set Notification Preference"
    />
  );
};

const NotificationPreferenceEmpty = () => {
  return (
    <>
      <Typography variant="h5" align="center">
        All Notifications Allowed
      </Typography>
      <NotificationPreferenceActions />
    </>
  );
};

export const NotificationPreferenceDataGrid = (props: {}) => {
  return (
    <Fragment>
      <List
        sort={{ field: "id", order: "ASC" }}
        bulkActionButtons={false}
        actions={<NotificationPreferenceActions />}
        empty={<NotificationPreferenceEmpty />}
        {...props}
      >
        <Datagrid>
          <TextField source="template" sortable={false} />
          <BooleanField source="allowedEmail" />
          <BooleanField source="allowedSms" />
          <BooleanField source="allowedPush" />
          <DateField source="created" />
          <DateField source="updated" />
          <DeleteButton redirect={false} mutationMode="optimistic" />
        </Datagrid>
      </List>
    </Fragment>
  );
};
