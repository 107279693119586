import moment from "moment";
import dayjs from "dayjs";

export const formatDate = (
  date?: Date | string | undefined,
  undefinedText?: string
) => {
  if (!date) {
    return undefinedText || "N/A";
  }

  return dayjs(date).format("DD/MM/YYYY, HH:mm:ss");
};

export const formatNiceDate = (
  date?: Date | string | undefined,
  undefinedText?: string
) => {
  if (!date) {
    return undefinedText || "N/A";
  }

  return dayjs(date).format("D MMMM YYYY");
};

export const calculateDateLastSeen = (
  date?: Date | undefined,
  undefinedText?: string
) => {
  if (!date) {
    return undefinedText || "N/A";
  }
  const lastSeen = moment(date);

  return moment(lastSeen).fromNow();
};

export const secsToMinString = (raw: number): string => {
  let mins = Math.floor(raw / 60).toString();
  let secs = Math.floor(raw % 60).toString();

  if (mins.length === 1) mins = "0" + mins;
  if (secs.length === 1) secs = "0" + secs;

  return `${mins}:${secs}`;
};

export const chunkArray = <T>(arr: T[], size: number): T[][] =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const slugify = (text: string) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text

export const getNotificationTitleAndChannelFromVideoName = (
  name: string
): [string, string] | [false, false] => {
  const episodeRegex = /- ep[0-9]{1,}/gi;
  if (!name.match(episodeRegex)) return [false, false];

  const nameWithoutEpisodeNumber = name.replace(episodeRegex, "");

  const videoNameSplit = nameWithoutEpisodeNumber
    .split("-", 2)
    .map(s => s.trim());
  if (!videoNameSplit[1]) return [false, false];

  return [videoNameSplit[0], videoNameSplit[1]];
};

export const getTitleAndChannelFromVideoName = (
  name: string
): { title: string; episode?: string } => {
  let title = name;
  let episode: string | undefined = undefined;
  const nameParts = name
    .split(" - ", 4)
    .map(t => t.trim())
    .filter(part => !part.match(/aglife|straight6|loft|stockyards/i));

  const episodeRegex = /Ep([0-9]{1,})/i;
  for (let partIdx = 0; partIdx < nameParts.length; partIdx++) {
    const part = nameParts[partIdx];
    const episodeRegexMatch = part.match(episodeRegex);
    if (episodeRegexMatch && episodeRegexMatch[0] && episodeRegexMatch[1]) {
      episode = episodeRegexMatch[1];
    } else {
      title = part;
    }
  }

  return { title, episode };
};
