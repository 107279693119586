import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  TextField,
  SelectInput,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  BooleanField,
  BooleanInput,
  CreateButton
} from "react-admin";
import { Tag } from "../entities/Catalog";
import icon from "@material-ui/icons/LocalOffer";
import { Route } from "react-router";
import { CardActions, Drawer } from "@material-ui/core";
import {
  SelectEqualsFilter,
  TextContainsFilter
} from "../components/filters/TextFilters";

const TagActions = ({ basePath }: { basePath?: string }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);
const TagFilter = (props: {}) => (
  <Filter {...props}>
    <TextContainsFilter label="Tag" source="tag" alwaysOn />
    <SelectEqualsFilter
      alwaysOn
      allowEmpty
      emptyText="All"
      source="type"
      choices={[
        { id: "video", name: "Video" },
        { id: "search-term", name: "Search Term" },
        { id: "soft-link", name: "Soft Link" },
        { id: "member", name: "Member" }
      ]}
    />
    <BooleanInput label="Additional" source="additional" alwaysOn />
    <BooleanInput label="Active" source="active" alwaysOn />
  </Filter>
);

export const TagList = (props: {}) => {
  const handleClose = () => {
    window.history.back();
  };

  return (
    <Fragment>
      <List
        actions={<TagActions />}
        filters={<TagFilter />}
        filterDefaultValues={{ active: true }}
        sort={{ field: "tag", order: "ASC" }}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <TextField source="tag" label="Video" sortable={true} />
          <TextField source="group" label="Group" sortable={true} />
          <TextField source="type" label="Type" sortable={true} />
          <BooleanField sortable={true} source="additional" />
          <BooleanField sortable={true} source="active" />
          <EditButton />
        </Datagrid>
      </List>
      <Route
        path="/tag/create"
        render={() => (
          <Drawer open onClose={handleClose} anchor="right"></Drawer>
        )}
      />
      <Route
        path="/tag/:id"
        render={({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open onClose={handleClose} anchor="right">
              {isMatch ? (
                <TagEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <TagCreate {...props} />
              )}
            </Drawer>
          );
        }}
      />
    </Fragment>
  );
};

const TagTitle = ({ record }: { record?: Tag }) => {
  return <span>Tag: {record ? `${record.tag}` : ""}</span>;
};

export const TagEdit = (props: { id: string; onCancel: () => void }) => (
  <Edit {...props} title={<TagTitle />}>
    <SimpleForm>
      <TextInput label="Tag" source="tag" />
      <TextInput label="Group" source="group" />
      <SelectInput
        source="type"
        choices={[
          { id: "video", name: "Video" },
          { id: "search-term", name: "Search Term" },
          { id: "soft-link", name: "Soft Link" },
          { id: "member", name: "Member" }
        ]}
      />
      <BooleanInput label="Additional" source="additional" />
      <BooleanInput label="Active" source="active" />
    </SimpleForm>
  </Edit>
);

export const TagCreate = (props: {}) => (
  <Create {...props} title={<TagTitle />}>
    <SimpleForm>
      <TextInput label="Tag" source="tag" autoFocus />
      <TextInput label="Group" source="group" />
      <SelectInput
        source="type"
        defaultValue="search-term"
        choices={[
          { id: "video", name: "Video" },
          { id: "search-term", name: "Search Term" },
          { id: "soft-link", name: "Soft Link" },
          { id: "member", name: "Member" }
        ]}
      />
      <BooleanInput
        label="Additional"
        source="additional"
        defaultValue={false}
      />
      <BooleanInput label="Active" source="active" defaultValue={true} />
    </SimpleForm>
  </Create>
);

export const TagIcon = icon;
