/* eslint-disable react/display-name */
import React, { useCallback, useState } from "react";
import {
  List,
  useGetList,
  useListContext,
  useNotify,
  useResourceContext,
  useUpdate
} from "react-admin";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  DataGrid,
  getGridStringOperators,
  GridColumns,
  GridEditRowProps,
  GridEditRowsModel,
  GridFilterModel,
  GridRenderCellParams,
  GridRowId,
  GridSortModel
} from "@mui/x-data-grid";
import { Playlist, Tag } from "../../entities/Catalog";
import { IUserMediaItemType } from "@booyaltd/core";
import { renderMediaEdit, renderMediaView } from "./datagrid/MediaFields";
import {
  renderEditPlaylists,
  renderPlaylistView
} from "./datagrid/PlaylistFields";
import { renderEditSearchTerms } from "./datagrid/SearchTermFields";
import { useStyles } from "./datagrid/styles";
import { defaultConvertFilterModelToFilterValues } from "./datagrid/operators";
import { Link } from "@material-ui/core/";
import { renderEditSoftLinks } from "./datagrid/SoftLinkFields";
import { renderDateEdit, renderDateView } from "./datagrid/DateFields";

dayjs.extend(relativeTime);

const renderLinks = (params: GridRenderCellParams) => {
  const url = `https://farmflix.tv/videos/episode/${params.row.slug}`;

  return (
    <>
      <Link target="_blank" href={url} style={{ margin: "5px" }}>
        Watch
      </Link>
      <Link href={`/video/${params.id}`} style={{ margin: "5px" }}>
        Full Editor
      </Link>
    </>
  );
};

const BulkEditVideoListDatagrid = () => {
  const classes = useStyles();
  const {
    // data
    ids,
    data,
    total,
    error,
    loading,
    // pagination
    page,
    setPage,
    perPage,
    setPerPage,
    // filtering
    filterValues,
    setFilters,
    setSort,
    currentSort
  } = useListContext();
  const { data: searchTerms } = useGetList<Tag>(
    "tag",
    { page: 1, perPage: 1000 },
    { field: "tag", order: "asc" },
    {
      type: "search-term"
    }
  );
  const { data: softLinks } = useGetList<Tag>(
    "tag",
    { page: 1, perPage: 1000 },
    { field: "tag", order: "asc" },
    {
      type: "soft-link"
    }
  );
  const { data: playlists } = useGetList<Playlist>(
    "playlist",
    { page: 1, perPage: 50 },
    { field: "name", order: "asc" }
  );
  const resource = useResourceContext();
  const notify = useNotify();
  const [update] = useUpdate(resource, "");
  const [editModel, setEditModel] = useState<GridEditRowsModel>({});

  // pagination logic

  const handlePageChange = (page: number) => {
    setPage(page + 1);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPerPage(pageSize);
  };

  // // filtering logic
  //
  // const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
  //   items: defaultConvertFilterValuesToFilterModel(filterValues)
  // });
  //
  // React.useEffect(() => {
  //   const filterItems = defaultConvertFilterValuesToFilterModel(filterValues);
  //   if (isEqual(filterModel.items, filterItems)) {
  //     return;
  //   }
  //   setFilterModel({
  //     items: filterItems
  //   });
  // }, [filterValues, filterModel]);
  //
  const handleFilterChange = (params: GridFilterModel) => {
    setFilters(defaultConvertFilterModelToFilterValues(params), {});
  };

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (!model || !model[0]) return;

      if (
        model[0].field === currentSort.field &&
        model[0].sort?.toLowerCase() === currentSort.order.toLowerCase()
      ) {
        return;
      }

      setSort(model[0].field, model[0].sort?.toUpperCase() || "ASC");
    },
    [currentSort]
  );

  // edition logic
  const handleEditRowsModelChange = useCallback(
    (editRowsModel: GridEditRowsModel) => {
      setEditModel(editRowsModel);
    },
    [setEditModel]
  );

  const handleEditRowChangeCommitted = useCallback(
    (rowId: GridRowId) => {
      if (!editModel || !editModel[rowId]) return;
      const original = data[rowId];

      const updateData = Object.keys(editModel[rowId]).reduce<
        Record<string, unknown>
      >((partialVideo, columnKey) => {
        const row: GridEditRowProps = editModel[rowId];
        if (
          row &&
          row[columnKey] &&
          row[columnKey].value !== original[columnKey]
        ) {
          partialVideo[columnKey] = row[columnKey].value;
        }

        return partialVideo;
      }, {});

      if (Object.keys(updateData).length <= 0) {
        return;
      }

      update(
        {
          payload: {
            id: rowId,
            data: updateData,
            previousData: data[rowId]
          }
        },
        {
          onFailure: error => {
            notify(
              typeof error === "string"
                ? error
                : error.message || "ra.notification.http_error",
              "warning",
              {
                _:
                  typeof error === "string"
                    ? error
                    : error && error.message
                    ? error.message
                    : undefined
              }
            );
          }
        }
      );
    },
    [editModel]
  );

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Title",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      editable: false,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        o => o.value === "contains"
      ),
      width: 300
    },
    {
      field: "masterReleaseDate",
      type: "date",
      headerName: "Published",
      sortable: true,
      filterable: false,
      editable: true,
      renderEditCell: renderDateEdit,
      renderCell: renderDateView,
      width: 165
    },
    {
      field: "captureDate",
      type: "date",
      headerName: "Captured",
      sortable: true,
      filterable: false,
      editable: true,
      renderEditCell: renderDateEdit,
      renderCell: renderDateView,
      width: 165
    },
    {
      field: "thumbnailMediaId",
      headerName: "Thumb",
      sortable: false,
      editable: true,
      filterable: false,
      renderCell: renderMediaView("thumbnailThumb"),
      renderEditCell: renderMediaEdit(
        "thumbnailThumb",
        IUserMediaItemType.VideoThumbnail,
        notify
      )
    },
    {
      field: "playlistIds",
      headerName: "Playlists",
      sortable: false,
      editable: true,
      filterable: false,
      renderCell: renderPlaylistView(playlists),
      renderEditCell: renderEditPlaylists(playlists),
      flex: 1
    },
    {
      field: "softLinks",
      headerName: "Soft Links",
      sortable: false,
      editable: true,
      filterable: false,
      renderEditCell: renderEditSoftLinks(softLinks),
      flex: 1
    },
    {
      field: "searchTerms",
      headerName: "Search Terms",
      renderEditCell: renderEditSearchTerms(searchTerms),
      sortable: false,
      editable: true,
      filterable: false,
      flex: 1
    },
    {
      field: "metadata",
      headerName: "Keywords",
      sortable: false,
      editable: true,
      filterable: false,
      flex: 1
    },
    {
      field: "updated",
      type: "date",
      headerName: "Updated",
      sortable: true,
      editable: false,
      filterable: false,
      valueFormatter: ({ value }) =>
        typeof value === "string" || value instanceof Date
          ? dayjs(value).fromNow()
          : "",
      flex: 1
    },
    {
      field: "slug",
      headerName: " ",
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: renderLinks,
      flex: 1
    }
  ];

  return (
    <DataGrid
      className={classes.dataGrid}
      columns={columns}
      autoHeight={true}
      rowHeight={100}
      disableSelectionOnClick
      // data
      rows={ids.map(id => data[id])}
      loading={loading}
      error={error}
      // sorting
      sortingMode="server"
      sortModel={
        currentSort
          ? [
              {
                field: currentSort.field,
                sort:
                  currentSort.order.toLowerCase() === "asc"
                    ? "asc"
                    : currentSort.order.toLowerCase() === "desc"
                    ? "desc"
                    : undefined
              }
            ]
          : []
      }
      onSortModelChange={handleSortModelChange}
      // pagination
      paginationMode="server"
      page={page - 1}
      pageSize={perPage}
      rowCount={total}
      rowsPerPageOptions={[10, 50, 100, 200]}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      // filtering
      filterMode="server"
      filterModel={
        filterValues && Object.keys(filterValues).length > 0
          ? {
              items: [
                {
                  columnField: Object.keys(filterValues)[0],
                  value: Object.values(filterValues)[0],
                  operatorValue: "contains"
                }
              ]
            }
          : undefined
      }
      onFilterModelChange={handleFilterChange}
      // edition
      editMode="row"
      onRowEditCommit={handleEditRowChangeCommitted}
      onEditRowsModelChange={handleEditRowsModelChange}
    />
  );
};

export const BulkEditVideoList = (props: any) => (
  <List {...props} pagination={false} perPage={50}>
    <BulkEditVideoListDatagrid />
  </List>
);
