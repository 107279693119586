import React from "react";
import { Box } from "@material-ui/core";
import { Video } from "../../../../../entities/Catalog";
import VideoManagerPanel from "./VideoManagerPanel";
import ThumbManagerPanel from "./ThumbManagerPanel";
import ThumbnailVttManagerPanel from "./ThumbnailVttManagerPanel";

const VideoShowMediaTab = ({ record: video }: { record?: Video }) => {
  if (!video) {
    return null;
  }

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box display="flex" flex={1} flexDirection="row">
        <Box flex={1} mb={2} mr={1}>
          <VideoManagerPanel type="master" video={video} />
        </Box>
        <Box flex={1} mb={2} ml={1}>
          <VideoManagerPanel type="preview" video={video} />
        </Box>
      </Box>
      <Box display="flex" flex={1} flexDirection="row">
        <Box flex={1} mb={2} ml={1}>
          <ThumbManagerPanel video={video} />
        </Box>
        <Box flex={1} mb={3} ml={1}>
          <ThumbnailVttManagerPanel video={video} />
        </Box>
      </Box>
    </Box>
  );
};

export default VideoShowMediaTab;
