import React, { useEffect } from "react";
import {
  ShowProps,
  Show,
  SimpleShowLayout,
  useShowContext,
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  DateField,
  Filter,
  List,
  SelectArrayInput,
  Button
} from "react-admin";
import {
  ScheduledNotificationDataProvider,
  StatsResponse
} from "../../providers/data/BasicCrudDataProviders";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { NotificationLogStatus, ScheduledNotification } from "@booyaltd/core";
import { formatDate } from "../../utils";
import { Profile } from "../../entities/Profile";
import { Link } from "react-router-dom";
import { RemoveRedEye } from "@material-ui/icons";

const StatsStatuses = [
  NotificationLogStatus.Pending,
  NotificationLogStatus.Sent,
  NotificationLogStatus.Opened,
  NotificationLogStatus.Failed,
  NotificationLogStatus.Bounced,
  NotificationLogStatus.Complaint,
  NotificationLogStatus.Queued
];

const Summary = () => {
  const { record } = useShowContext<ScheduledNotification>();

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={6}>
      <Typography variant="h4">Summary</Typography>
      <Typography variant="h6">Campaign Name</Typography>
      <Typography variant="body1">{record.name}</Typography>
      <br />

      <Typography variant="h6">Status</Typography>
      <Typography variant="body1">{record.status}</Typography>
      {record.failureReason ? (
        <Typography variant="body1">
          Failure Reason: {record.failureReason}
        </Typography>
      ) : null}
      <br />

      <Typography variant="h6">Message</Typography>
      {record.template !== "generic" ? (
        <Typography variant="body1">{record.template}</Typography>
      ) : null}
      <Typography>{JSON.stringify(record.templateData, null, 2)}</Typography>
      <br />

      <Typography variant="h6">Scheduling</Typography>
      <Typography variant="body1">
        Send at {formatDate(record.scheduledTime)}
      </Typography>
      <Typography variant="body1">
        Queued at {formatDate(record.queueTime)}
      </Typography>

      <br />
      <Typography variant="h6">User Filters</Typography>
      <Typography>
        {JSON.stringify(record.userProfileFilter, null, 2)}
      </Typography>
      <Button
        component={Link}
        label="View Matching Users"
        to={`/member?displayedFilters=${encodeURIComponent(
          JSON.stringify(
            Object.keys(record.userProfileFilter).reduce(
              (carry, key) => ({ ...carry, [key.split("__", 2)[0]]: true }),
              {}
            )
          )
        )}&filter=${encodeURIComponent(
          JSON.stringify(
            Object.keys(record.userProfileFilter).reduce((carry, key) => {
              // @ts-ignore
              let val = record.userProfileFilter[key];

              if (key.indexOf("__in") > -1) {
                val = val.split(",");
              }

              return {
                ...carry,
                [key]: val
              };
            }, {})
          )
        )}`}
      >
        <RemoveRedEye />
      </Button>
    </Grid>
  );
};

const ScheduledNotificationStats = () => {
  const { record } = useShowContext<ScheduledNotification>();
  const [stats, setStats] = React.useState<StatsResponse>();
  const [statsLoading, setStatsLoading] = React.useState(false);
  useEffect(() => {
    if (!record || stats || statsLoading) {
      return;
    }

    new ScheduledNotificationDataProvider()
      .getStats(record.id)
      .then(setStats)
      .finally(() => setStatsLoading(false));
  }, [record, stats, statsLoading]);

  return (
    <Grid item xs={6}>
      <Typography variant="h4">Stats</Typography>
      <Typography variant="subtitle1">
        Expect this to be empty until Queue Time has passed
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">Message Status</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">Total</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {StatsStatuses.map(status => (
            <TableRow key={status}>
              <TableCell>{status}</TableCell>
              <TableCell>
                {stats?.find(
                  stat =>
                    stat.status?.toString()?.toLowerCase() ===
                    status.toString()?.toLowerCase()
                )?.total || 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

const NotificationLogsFilters = (props: any) => (
  <Filter {...props}>
    <SelectArrayInput
      alwaysOn
      fullWidth
      source="status__in"
      label="Status"
      choices={StatsStatuses.map(status => ({
        id: status.toString(),
        name: status
      }))}
    />
  </Filter>
);

const NotificationLogs = () => {
  const { record } = useShowContext<ScheduledNotification>();

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Typography variant="h4">Logs</Typography>
      <Typography variant="subtitle1">
        Note: this will be empty until Queue Time has passed
      </Typography>
      <ReferenceManyField
        reference="notification-log"
        target="scheduledNotificationId"
      >
        <List
          filter={{ scheduledNotificationId: record.id }}
          filters={<NotificationLogsFilters />}
          exporter={false}
          bulkActionButtons={false}
        >
          <Datagrid hasBulkActions={false}>
            <ReferenceField
              label="User"
              source="userId"
              reference="member"
              link="show"
            >
              <FunctionField<Profile>
                render={record => `${record?.firstName} ${record?.lastName}`}
              />
            </ReferenceField>
            <TextField source="status" />
            <TextField source="failureReason" />
            <DateField showTime source="sentTime" label="Sent Time" />
            <DateField showTime source="created" label="Queued Time" />
          </Datagrid>
        </List>
      </ReferenceManyField>
    </Grid>
  );
};

const ScheduledNotificationShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container>
          <Summary />
          <ScheduledNotificationStats />
          <NotificationLogs />
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default ScheduledNotificationShow;
