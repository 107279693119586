import React, { useCallback } from "react";
import { useRefresh, useGetList } from "react-admin";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ProfileDataProvider from "../../providers/data/ProfileDataProvider";
import { Alert } from "@material-ui/lab";
import { Payment } from "../../entities/Subscription";
import { formatDate } from "../../utils";

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      color: "#f44336"
    }
  })
);

const CancelStripeSubscriptionButton = (props: any) => {
  const classes = useStyles();
  const dataProvider = React.useRef(new ProfileDataProvider()).current;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [refundPaymentIds, setRefundPaymentIds] = React.useState<string[]>([]);

  const refresh = useRefresh();

  const { data: latestPayments, loading: latestPaymentLoading } = useGetList<
    Payment
  >(
    "payment",
    { page: 1, perPage: 1 },
    { field: "created", order: "desc" },
    {
      status: "success",
      memberId: props?.record?.id,
      paymentProvider: "stripe"
    },
    { enabled: !!props?.record?.id }
  );

  const latestPayment = latestPayments
    ? Object.values(latestPayments)[0]
    : false;

  const onConfirm = (immediately: boolean) => () => {
    if (loading || !props?.record?.id) return;

    setLoading(true);
    setError(undefined);

    return dataProvider
      .cancelSubscription(props.record.id, immediately, refundPaymentIds)
      .then(() => new Promise(resolve => setTimeout(resolve, 2000)))
      .then(() => {
        setOpen(false);
      })
      .catch(e => {
        setError("Error cancelling subscription: " + e.toString());
      })
      .finally(() => {
        setLoading(false);
        refresh(true);
      });
  };

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <div>
      <Button className={classes.cancelButton} onClick={handleClickOpen}>
        Cancel Subscription
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cancel Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to cancel {props?.record?.firstName}&apos;s
            FarmFLiX&apos;s Membership?
          </DialogContentText>
          {error && <Alert severity="error">{error}</Alert>}

          {latestPaymentLoading ? (
            <CircularProgress />
          ) : latestPayment ? (
            <Alert severity="info">
              <FormControlLabel
                control={
                  <Switch
                    checked={refundPaymentIds.includes(latestPayment.id)}
                    onChange={() => {
                      if (refundPaymentIds.includes(latestPayment.id)) {
                        setRefundPaymentIds([]);
                      } else {
                        setRefundPaymentIds([latestPayment.id]);
                      }
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={`Refund latest payment - ${formatDate(
                  latestPayment.successTime
                )} - ${latestPayment.currency} - ${latestPayment.cost}`}
              />
            </Alert>
          ) : (
            <Alert severity="info">No latest payment to refund</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button color="primary" disabled={loading} onClick={onConfirm(false)}>
            Cancel Subscription (End of Billing Period)
          </Button>
          <Button color="primary" disabled={loading} onClick={onConfirm(true)}>
            Cancel Subscription (Immediately)
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelStripeSubscriptionButton;
