import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Video } from "../../../../../entities/Catalog";

const VideoShowAnalyticsTab = ({ record: video }: { record?: Video }) => {
  if (!video) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6">{video.name}</Typography>
    </Box>
  );
};

export default VideoShowAnalyticsTab;
