import React from "react";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { Video } from "../../../../../entities/Catalog";
import { EditButton, Link, useGetMany, useGetOne } from "react-admin";
import { ChannelResponse } from "@booyaltd/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import {
  formatDate,
  getTitleAndChannelFromVideoName
} from "../../../../../utils";
import VideoWarning from "./VideoWarning";

const useStyles = makeStyles((theme: Theme) => ({
  videoThumb: {
    aspectRatio: "16 / 9",
    width: "auto",
    height: "180px",
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  channelThumb: {
    width: "auto",
    height: "80px",
    borderRadius: 40,
    margin: theme.spacing(2),
    marginLeft: theme.spacing(1)
  }
}));

const VideoShowInfoTab = ({ record: video }: { record?: Video }) => {
  const classes = useStyles();
  const { data: channel } = useGetOne<ChannelResponse>(
    "channel",
    String(video?.channelId),
    {
      enabled: !!video?.channelId
    }
  );

  const { data: playlists } = useGetMany(
    "playlist",
    video?.playlistIds || [],

    {
      enabled: !!(video?.playlistIds && video.playlistIds.length > 0)
    }
  );

  if (!video) {
    return null;
  }

  const {
    id,
    name,
    slug,
    description,
    tags,
    editor,
    videographer,
    song,
    location,
    thumbnail,
    episode,
    customOrder,
    masterReleaseDate,
    captureDate,
    type,
    restrictedFromMemberStatuses
  } = video;

  const { title, episode: episodeFromName } = getTitleAndChannelFromVideoName(
    name
  );

  return (
    <Box display="flex" flexDirection="column">
      {!thumbnail ? (
        <VideoWarning
          type="warning"
          title="Thumbnail Missing"
          desc="Please upload a thumbnail for this video"
          actions={[{ label: "Fix", url: `/video/${id}/show/media` }]}
        />
      ) : null}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {thumbnail ? (
          <img src={thumbnail} className={classes.videoThumb} />
        ) : null}
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            {channel?.thumbnailMediaUrl ? (
              <img
                src={channel.thumbnailMediaUrl}
                className={classes.channelThumb}
              />
            ) : null}
            <Box display="flex" flexDirection="column">
              {title || name ? (
                <Typography variant="h4">{title || name}</Typography>
              ) : null}
              {episodeFromName ? (
                <Typography variant="h6">Ep. {episodeFromName}</Typography>
              ) : null}
            </Box>
          </Box>

          {description ? (
            <Typography variant="body2">
              <strong>Description:</strong>
              <br />
              {description}
              <br />
              <br />
            </Typography>
          ) : null}
        </Box>

        <EditButton record={video} resource="video" title="Edit" />
      </Box>
      <Box display="flex" flexDirection="row" mb={2}>
        {playlists && playlists.length > 0 ? (
          <Box display="flex" flexDirection="column" flex={1}>
            <Typography variant="h6">Playlists</Typography>
            <Box display="flex" flexDirection="row">
              {playlists?.map((playlist, index) => {
                if (!playlist) return null;
                return (
                  <Link key={index} to={`/playlist/${playlist.id}/show`}>
                    <Chip
                      key={index}
                      label={playlist.name}
                      color="primary"
                      size="medium"
                      variant="outlined"
                    />
                  </Link>
                );
              })}
            </Box>
          </Box>
        ) : null}
        {tags && tags.length > 0 ? (
          <Box display="flex" flexDirection="column" flex={1}>
            <Typography variant="h6">Tags</Typography>
            <Box display="flex" flexDirection="row">
              {tags?.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  color="primary"
                  size="medium"
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box py={4} mb={2}>
        <Typography variant="h6">Additional Metadata</Typography>
        <Grid
          container
          component="dl" // mount a Definition List
          spacing={4}
        >
          {name ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Full Title
              </Typography>
              <Typography component="dd" variant="body2">
                {name}
              </Typography>
            </Grid>
          ) : null}
          {slug ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Slug
              </Typography>
              <Typography component="dd" variant="body2">
                {slug}
              </Typography>
            </Grid>
          ) : null}
          {episode ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Episode
              </Typography>
              <Typography component="dd" variant="body2">
                {episode}
              </Typography>
            </Grid>
          ) : null}
          {masterReleaseDate ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Release Date
              </Typography>
              <Typography component="dd" variant="body2">
                {formatDate(masterReleaseDate)}
              </Typography>
            </Grid>
          ) : null}
          {captureDate ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Capture Date
              </Typography>
              <Typography component="dd" variant="body2">
                {formatDate(captureDate)}
              </Typography>
            </Grid>
          ) : null}
          {location ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Location
              </Typography>
              <Typography component="dd" variant="body2">
                {location}
              </Typography>
            </Grid>
          ) : null}
          {editor ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Editor
              </Typography>
              <Typography component="dd" variant="body2">
                {editor}
              </Typography>
            </Grid>
          ) : null}
          {videographer ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Videographer
              </Typography>
              <Typography component="dd" variant="body2">
                {videographer}
              </Typography>
            </Grid>
          ) : null}
          {song ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Song
              </Typography>
              <Typography component="dd" variant="body2">
                {song}
              </Typography>
            </Grid>
          ) : null}
          {customOrder ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Custom Order
              </Typography>
              <Typography component="dd" variant="body2">
                {customOrder}
              </Typography>
            </Grid>
          ) : null}
          {type ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Video Type
              </Typography>
              <Typography component="dd" variant="body2">
                {type}
              </Typography>
            </Grid>
          ) : null}
          {restrictedFromMemberStatuses ? (
            <Grid item xs={4}>
              <Typography component="dt" variant="subtitle1">
                Restricted From
              </Typography>
              <Typography component="dd" variant="body2">
                {restrictedFromMemberStatuses.join(", ")}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};

export default VideoShowInfoTab;
