import React from "react";
import { Chip } from "@material-ui/core";

const StringArrayChipsField = ({
  record,
  source,
  emptyValue
}: {
  record?: { [key: string]: string[] };
  label: string;
  source: string;
  emptyValue?: string;
  sortable?: boolean;
}) => {
  let value = record ? record[source] : false;
  let isEmptyValue = false;
  if (!value || value.length === 0) {
    if (!emptyValue) {
      return <div />;
    }

    value = [emptyValue];
    isEmptyValue = true;
  }

  if (!Array.isArray(value) || value.length === 0) return <div />;

  return (
    <div>
      {value.map(val => (
        <Chip
          key={val}
          label={val}
          color={isEmptyValue ? "default" : "primary"}
        />
      ))}
    </div>
  );
};

export default StringArrayChipsField;
