import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  CreateButton,
  NumberInput,
  NumberField,
  composeValidators,
  required,
  minLength,
  maxLength
} from "react-admin";
import icon from "@material-ui/icons/LocalOffer";
import { CardActions } from "@material-ui/core";
import { TextContainsFilter } from "../components/filters/TextFilters";
import { IUserMediaItemType, PromoItem } from "@booyaltd/core";
import UserMediaUploaderField from "../components/inputs/UserMediaUploaderField";
import ThumbnailField from "../components/fields/ThumbnailField";

const PromoItemActions = ({ basePath }: { basePath?: string }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);
const PromoItemFilter = (props: {}) => (
  <Filter {...props}>
    <TextContainsFilter source="title" alwaysOn />
  </Filter>
);

export const PromoItemList = (props: {}) => {
  return (
    <List
      actions={<PromoItemActions />}
      filters={<PromoItemFilter />}
      empty={<PromoItemActions />}
      sort={{ field: "order", order: "ASC" }}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <ThumbnailField
          source="posterMediaUrl"
          sortable={false}
          label="Poster"
        />
        <TextField source="title" sortable={true} />
        <TextField source="subtitle" sortable={true} />
        <TextField source="clickUrl" sortable={true} />
        <TextField source="clickEvent" sortable={true} />
        <NumberField source="order" sortable={true} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const PromoItemTitle = ({ record }: { record?: PromoItem }) => {
  return <span>Promo Item: {record ? `${record.title}` : ""}</span>;
};

export const PromoItemForm = (props: any) => (
  <SimpleForm {...props}>
    <UserMediaUploaderField
      type={IUserMediaItemType.PlaylistPosterVertical}
      source="posterMediaId"
      urlField="posterMediaUrl"
      label="Vertical Poster (1080 x 1920)"
    />
    <TextInput
      source="title"
      validate={composeValidators(required(), minLength(3), maxLength(50))}
    />
    <TextInput source="subtitle" label="Subtitle (Optional)" />
    <TextInput
      source="clickUrl"
      label="On Click URL"
      validate={composeValidators(required(), minLength(3), maxLength(255))}
    />
    <TextInput
      source="clickEvent"
      label="Firebase Click Event"
      validate={composeValidators(required(), minLength(3), maxLength(20))}
    />
    <NumberInput source="order" validate={composeValidators(required())} />
  </SimpleForm>
);

export const PromoItemEdit = (props: {}) => (
  <Edit {...props} title={<PromoItemTitle />}>
    <PromoItemForm />
  </Edit>
);

export const PromoItemCreate = (props: {}) => (
  <Create {...props} title={<PromoItemTitle />}>
    <PromoItemForm />
  </Create>
);

export const PromoItemIcon = icon;
