import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useUpdate } from "react-admin";
import { Video } from "@booyaltd/core";
import { isEqual } from "lodash";

type VideoMetadataEditorModalButtonProps = {
  video: Video;
};

const VideoMetadataEditorModalButton = ({
  video
}: VideoMetadataEditorModalButtonProps) => {
  const [open, setOpen] = useState(false);
  const [metadata, setMetadata] = useState<string>(video.metadata || "");

  const [flushMetadata, { loading: updating }] = useUpdate(
    "video",
    String(video?.id),
    { ...video, metadata },
    video,
    {
      mutationMode: "optimistic"
    }
  );

  const onOpen = () => setOpen(true);
  const onDone = () => {
    setOpen(false);

    if (!isEqual(video.metadata, metadata)) {
      flushMetadata();
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="small"
        variant="outlined"
        color="secondary"
        disabled={updating}
        startIcon={<Edit />}
      >
        Edit
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Update Keywords</DialogTitle>
        <DialogContent>
          <Box display="flex" flex={1} flexDirection="column">
            <Input
              type="text"
              value={metadata}
              placeholder="Keywords"
              onChange={event => setMetadata(event.target.value || "")}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onDone}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VideoMetadataEditorModalButton;
