import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  CreateButton,
  useNotify
} from "react-admin";
import { SearchSynonym } from "../entities/Catalog";
import icon from "@material-ui/icons/SyncAlt";
import { Route } from "react-router";
import { CardActions, Drawer } from "@material-ui/core";
import { Button, Link, Typography } from "@material-ui/core/";
import { SearchSynonymDataProvider } from "../providers/data/AdminAutoCrudDataProvider";

const SearchSynonymActions = ({ basePath }: { basePath?: string }) => {
  const notify = useNotify();
  const onFlush = () => {
    const provider = new SearchSynonymDataProvider();
    provider
      .flushSynonyms()
      .then(() => {
        notify("Flush job started", "info");
      })
      .catch(() => {
        notify("Error kicking off flush job, please try again.", "error");
      });
  };
  return (
    <CardActions>
      <Button
        variant="outlined"
        color="primary"
        fullWidth={false}
        onClick={onFlush}
      >
        Flush Synonyms
      </Button>
      <CreateButton basePath={basePath} />
    </CardActions>
  );
};
const SearchSynonymFilter = (props: {}) => (
  <Filter {...props}>
    <TextInput label="Synonym" source="synonym" alwaysOn />
  </Filter>
);

export const SearchSynonymList = (props: {}) => {
  const handleClose = () => {
    window.history.back();
  };

  return (
    <Fragment>
      <Typography variant="subtitle1">Synonym Explainer</Typography>
      <Typography variant="body1">
        The synonyms are built using Solr synonym notation.{" "}
        <Link
          href="https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-synonym-tokenfilter.html#_solr_synonyms"
          target="_blank"
        >
          Click Here
        </Link>{" "}
        for example / explainer of the basic syntax
      </Typography>
      <List
        actions={<SearchSynonymActions />}
        filters={<SearchSynonymFilter />}
        empty={<SearchSynonymActions />}
        sort={{ field: "synonym", order: "ASC" }}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <TextField source="synonym" label="Synonym" sortable={true} />
          <EditButton />
        </Datagrid>
      </List>
      <Route
        path="/search-synonym/create"
        render={() => (
          <Drawer open onClose={handleClose} anchor="right"></Drawer>
        )}
      />
      <Route
        path="/search-synonym/:id"
        render={({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open onClose={handleClose} anchor="right">
              {isMatch ? (
                <SearchSynonymEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <SearchSynonymCreate {...props} />
              )}
            </Drawer>
          );
        }}
      />
    </Fragment>
  );
};

const SearchSynonymTitle = ({ record }: { record?: SearchSynonym }) => {
  return <span>Synonym: {record ? `${record.id}` : ""}</span>;
};

export const SearchSynonymEdit = (props: {
  id: string;
  onCancel: () => void;
}) => (
  <Edit {...props} title={<SearchSynonymTitle />}>
    <SimpleForm>
      <TextInput label="Synonym" source="synonym" />
    </SimpleForm>
  </Edit>
);

export const SearchSynonymCreate = (props: {}) => (
  <Create {...props} title={<SearchSynonymTitle />}>
    <SimpleForm>
      <TextInput label="Synonym" source="synonym" />
    </SimpleForm>
  </Create>
);

export const SearchSynonymIcon = icon;
