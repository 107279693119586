import Provider from "../Provider";
import IDataProvider from "./IDataProvider";
import {
  CreateParams,
  CreateResult,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  Record,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from "react-admin";
import {
  AdminListRequest,
  AdminListResponse,
  PositiveFilters
} from "@booyaltd/core";
import { SearchSynonym } from "../../entities/Catalog";

export type AdminAutoCrudDataProviderOptions<T> = {
  path: string;
  queryField: keyof T;
  queryFilter?: PositiveFilters;
};

export default class AdminAutoCrudDataProvider<T extends Record>
  extends Provider
  implements IDataProvider<T> {
  constructor(protected opts: AdminAutoCrudDataProviderOptions<T>) {
    super();
  }

  protected buildPath = (id?: Identifier) => {
    return `/admin/${this.opts.path}${id ? `/${id}` : ""}`;
  };

  create({ data }: CreateParams<T>): Promise<CreateResult<T>> {
    return this.axios.post<T>(this.buildPath(), data).then(({ data }) => ({
      data
    }));
  }

  update({ id, data }: UpdateParams<T>): Promise<UpdateResult<T>> {
    return this.axios.put<T>(this.buildPath(id), data).then(({ data }) => ({
      data
    }));
  }

  delete({ id }: DeleteParams): Promise<DeleteResult> {
    return this.axios.delete(this.buildPath(id)).then(() => ({}));
  }

  getList({
    filter,
    sort: { field, order },
    pagination: { page, perPage }
  }: GetListParams): Promise<GetListResult<T>> {
    if (filter) {
      filter = AdminAutoCrudDataProvider.cleanFilters(this.opts, filter);
    }

    const getListParams: AdminListRequest<{ id: Identifier }> = {
      page,
      limit: perPage,
      order: `${order.toLowerCase() === "asc" ? "+" : "-"}${field}`,
      ...filter
    };

    return this.axios
      .get<AdminListResponse<T>>(this.buildPath(), { params: getListParams })
      .then(({ data: { total, results } }) => ({ data: results, total }));
  }

  public static cleanFilters(
    opts: AdminAutoCrudDataProviderOptions<any>,
    filter: { [key: string]: any }
  ) {
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
      const value = filter[key];
      if (key === "q") {
        filter[
          opts.queryField.toString() +
            (opts.queryFilter?.toString() || "__contains")
        ] = value;
        delete filter[key];
      } else if ([true, "true"].includes(value)) {
        filter[key] = "1";
      } else if ([false, "false"].includes(value)) {
        filter[key] = "0";
      } else if (Array.isArray(value)) {
        filter[key] = value.join(",");
      } else if (value instanceof Date) {
        filter[key] = value.toISOString();
      }
    });

    return filter;
  }

  getMany({ ids }: GetManyParams): Promise<GetManyResult<T>> {
    const getListParams: AdminListRequest<{ id: Identifier }> = {
      id__in: ids.join(","),
      pagination: false
    };

    return this.axios
      .get<AdminListResponse<T>>(this.buildPath(), { params: getListParams })
      .then(({ data: { results } }) => ({ data: results }));
  }

  getOne({ id }: GetOneParams): Promise<GetOneResult<T>> {
    return this.axios.get<T>(this.buildPath(id)).then(({ data }) => ({
      data
    }));
  }

  updateMany(): Promise<UpdateManyResult> {
    throw new Error("Not Implemented");
  }

  deleteMany(): Promise<DeleteManyResult> {
    throw new Error("Not Implemented");
  }
}

export class SearchSynonymDataProvider extends AdminAutoCrudDataProvider<
  SearchSynonym
> {
  constructor() {
    super({ path: "search-synonyms", queryField: "synonym" });
  }

  flushSynonyms() {
    return this.axios.post("/admin/search-synonyms/flush");
  }
}
