import React, { useState } from "react";
import { Video } from "../../../../../entities/Catalog";
import { useGetOne, useNotify, useRefresh } from "react-admin";
import { IUserMediaItem } from "@booyaltd/core";
import { Box, Typography } from "@material-ui/core";
import { VideoDataProvider } from "../../../../../providers/data/BasicCrudDataProviders";
import MediaStatusAlert from "./MediaStatusAlert";
import DisabledMediaStatusAlert from "./DisabledMediaStatusAlert";

type ThumbManagerPanelProps = {
  video: Video;
};

const mediaItemStatusMessage = (mediaItem?: IUserMediaItem): string => {
  switch (mediaItem?.status) {
    case undefined:
      return "No WebVTT file generated yet.";
    case "pending-upload":
    case "processing":
      return "Generating WebVTT file, this will take a few minutes . . . ";
    case "error":
      return (
        "Last generation failed: " + (mediaItem?.status || "Unknown Error")
      );
    case "ready":
      return "Image ready for use";
    case "deleted":
      return "Deleted";
    default:
      return "";
  }
};

const ThumbnailVttManagerPanel = ({ video }: ThumbManagerPanelProps) => {
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const { id, thumbnailVtt, thumbnailVttMediaId, masterProviderReady } = video;

  const { data: mediaItem } = useGetOne<IUserMediaItem>(
    "media-item",
    String(thumbnailVttMediaId),
    {
      enabled: !!thumbnailVttMediaId
    }
  );

  const generateThumbnailVtt = () => {
    if (!id || loading) {
      return;
    }

    const dataProvider = new VideoDataProvider();
    setLoading(true);
    dataProvider
      .generateThumbnailVtt(id)
      .then(() => {
        notify(
          "Thumbnail VTT Job Started - Please wait a few minutes for it to process"
        );
      })
      .catch(() => {
        notify("Error generating thumbnail VTT", "warning");
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  const isUploaded = mediaItem?.status === "ready";

  return (
    <Box width="100%" flex={1} display="flex" flexDirection="column">
      <Typography variant="h5">Timeline Preview Thumbnails</Typography>
      <Box display="flex" flexDirection="column" mt={1}>
        {!masterProviderReady && !thumbnailVtt ? (
          <DisabledMediaStatusAlert
            title="Can't generate "
            desc="Can't generate timeline preview until master video is uploaded and processed."
          />
        ) : (
          <MediaStatusAlert
            ready={isUploaded}
            desc={mediaItemStatusMessage(mediaItem)}
            openLinks={
              isUploaded && thumbnailVtt
                ? [{ label: "WebVTT File", url: thumbnailVtt }]
                : []
            }
            actions={[
              {
                label: mediaItem ? "Re-Generate" : "Generate",
                onClick: generateThumbnailVtt
              }
            ]}
            error={
              mediaItem?.status === "error"
                ? mediaItem.statusReason || "Unknown Error"
                : undefined
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default ThumbnailVttManagerPanel;
