import React, { useEffect, useState } from "react";
import {
  FieldProps,
  ReferenceInput,
  required,
  SelectInput,
  useDataProvider
} from "react-admin";
import { useField } from "react-final-form";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core/";
import { ProfileSegment } from "../../entities/Profile";

export const ProfileSegmentCountField = (props: FieldProps<ProfileSegment>) => {
  const [show, setShow] = useState(false);
  const [count, setCount] = useState<number>();

  const { getOne } = useDataProvider();

  useEffect(() => {
    if (show && count === undefined && props.record?.id) {
      getOne<ProfileSegment>("profile-segment", {
        id: props.record.id
      }).then(r => {
        console.log(r);
        setCount(r.data.count);
      });
    }
  }, [show]);

  if (!show) {
    return <Button onClick={() => setShow(true)}>Load Count</Button>;
  }

  if (show && count !== undefined) {
    return <Typography variant="h6">{count} Users</Typography>;
  }

  return <CircularProgress size={20} />;
};

const MemberSegmentInput = () => {
  const { getOne } = useDataProvider();
  const {
    input: { value }
  } = useField("userProfileSegmentId");
  const [count, setCount] = useState<number>();

  useEffect(() => {
    setCount(undefined);

    if (value !== undefined) {
      getOne<ProfileSegment>("profile-segment", {
        id: value
      }).then(r => {
        console.log(r);
        setCount(r.data.count);
      });
    }
  }, [value]);
  return (
    <Box display="flex" flexDirection="row">
      <ReferenceInput
        label="Member Segment"
        validate={[required()]}
        source="userProfileSegmentId"
        reference="profile-segment"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {value && count === undefined && (
        <CircularProgress style={{ marginLeft: "10px", marginTop: "10px" }} />
      )}
      {value && count !== undefined && (
        <Typography
          variant="h6"
          style={{ marginLeft: "10px", paddingTop: "14px" }}
        >
          {count} Users
        </Typography>
      )}
    </Box>
  );
};

export default MemberSegmentInput;
