import { singleton } from "tsyringe";
import { action, makeObservable, observable, runInAction } from "mobx";
import { IUserMediaItem } from "@booyaltd/core";
import Evaporate, { TransferStats } from "evaporate";
import { API_HOST } from "../env";
import MD5 from "js-md5";
import { sha256 as SHA256 } from "js-sha256";
import EventEmitter from "events";

export type UploadState =
  | "initialising"
  | "uploading"
  | "paused"
  | "cancelled"
  | "done"
  | "error";

export type Upload = {
  state: UploadState;
  mediaItem: IUserMediaItem;
  fileKey?: string;
  stats?: TransferStats;
};

const md5 = (x: any) => {
  const a = MD5.create();
  a.update(x);
  return a.base64();
};

const sha256 = (x: any) => {
  const o = SHA256.create();
  o.update(x);
  return o.hex();
};

export enum UploadEvent {
  UPLOAD_STARTED = "upload_started",
  UPLOAD_PROGRESS = "upload_progress",
  UPLOAD_PAUSED = "upload_paused",
  UPLOAD_RESUMED = "upload_resumed",
  UPLOAD_COMPLETED = "upload_completed",
  UPLOAD_CANCELLED = "upload_cancelled",
  UPLOAD_ERROR = "upload_error"
}

@singleton()
export default class MultipartUploadStore {
  @observable
  public open = false;

  @observable
  public initialisingUpload = false;

  @observable.deep
  public uploads: Record<string, Upload> = {};

  private evaporate: Evaporate | null = null;

  constructor() {
    makeObservable(this);
  }

  private emitter = new EventEmitter();

  private fireEventForUpload(id: string, event: UploadEvent) {
    this.emitter.emit(`${id}-${event}`);
  }

  public subscribeToEvent(
    id: string,
    event: UploadEvent,
    callback: () => void
  ) {
    this.emitter.on(`${id}-${event}`, callback);
  }

  public unsubscribeFromEvent(
    id: string,
    event: UploadEvent,
    callback: () => void
  ) {
    this.emitter.off(`${id}-${event}`, callback);
  }

  @action
  public setOpen(open: boolean) {
    this.open = open;
  }

  @action
  private setInitialisingUpload(initialisingUpload: boolean) {
    this.initialisingUpload = initialisingUpload;
  }

  @action
  private setUploadState(id: string, state: UploadState) {
    if (!this.uploads[id]) {
      return;
    }

    this.uploads[id] = { ...this.uploads[id], state };
  }

  @action
  private setUploadStats(id: string, stats: TransferStats) {
    if (!this.uploads[id]) {
      return;
    }

    this.uploads[id] = { ...this.uploads[id], stats };
  }

  @action
  onStarted(id: string, fileKey: string) {
    if (!this.uploads[id]) {
      return;
    }

    this.uploads[id] = { ...this.uploads[id], state: "uploading", fileKey };
    this.fireEventForUpload(id, UploadEvent.UPLOAD_STARTED);
  }

  private onPaused(id: string) {
    this.setUploadState(id, "paused");
    this.fireEventForUpload(id, UploadEvent.UPLOAD_PAUSED);
  }

  private onResumed(id: string) {
    this.setUploadState(id, "uploading");
    this.fireEventForUpload(id, UploadEvent.UPLOAD_RESUMED);
  }

  private onProgress(id: string, stats: TransferStats) {
    this.setUploadStats(id, stats);
    this.fireEventForUpload(id, UploadEvent.UPLOAD_PROGRESS);
  }

  private onComplete(id: string) {
    this.setUploadState(id, "done");
    this.fireEventForUpload(id, UploadEvent.UPLOAD_COMPLETED);

    if (this.uploads[id]) {
      delete this.uploads[id];
    }

    console.log("onComplete");
  }

  private onError(id: string, error: string) {
    this.setUploadState(id, "error");
    this.fireEventForUpload(id, UploadEvent.UPLOAD_ERROR);
    console.error("error uploading file", JSON.stringify(error, null, 2));
  }

  private onCancelled(id: string) {
    this.setUploadState(id, "cancelled");
    this.fireEventForUpload(id, UploadEvent.UPLOAD_CANCELLED);
  }

  private onBeforeSigner(xhr: XMLHttpRequest) {
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem("jwt")}`
    );
  }

  public async startInitialising() {
    this.setOpen(true);
    this.setInitialisingUpload(true);
  }

  public async startUpload(
    file: File,
    item: IUserMediaItem,
    {
      bucket,
      key,
      region,
      s3Endpoint,
      multipartSigningKey
    }: {
      bucket: string;
      key: string;
      region: string;
      s3Endpoint: string;
      multipartSigningKey: string;
    }
  ) {
    this.setInitialisingUpload(false);
    runInAction(() => {
      this.uploads[item.id] = {
        state: "initialising",
        mediaItem: item
      };
    });

    if (!this.evaporate) {
      this.evaporate = await Evaporate.create({
        signerUrl: API_HOST + "/user-media/multipart-signer",
        aws_key: multipartSigningKey,
        awsRegion: region,
        bucket: bucket,
        aws_url: s3Endpoint,
        logging: true,
        computeContentMd5: true,
        maxConcurrentParts: 2,
        cryptoMd5Method: md5,
        cryptoHexEncodedHash256: sha256,
        partSize: 1024 * 1024 * 50,
        allowS3ExistenceOptimization: false,
        s3FileCacheHoursAgo: 0
      });
    }

    return this.evaporate.add({
      name: key,
      file: file,
      contentType: item.contentType,
      started: file_key => this.onStarted(item.id, file_key),
      paused: () => this.onPaused(item.id),
      resumed: () => this.onResumed(item.id),
      cancelled: () => this.onCancelled(item.id),
      complete: () => this.onComplete(item.id),
      progress: (_, stats) => this.onProgress(item.id, stats),
      error: (error: string) => this.onError(item.id, error),
      beforeSigner: this.onBeforeSigner
    });
  }

  public pause(id: string) {
    if (!this.evaporate || !this.uploads[id]?.fileKey) {
      return;
    }

    this.evaporate.pause(this.uploads[id].fileKey);
  }

  public resume(id: string) {
    if (!this.evaporate || !this.uploads[id]?.fileKey) {
      return;
    }

    this.evaporate.resume(this.uploads[id].fileKey);
  }

  public cancel(id: string) {
    if (!this.evaporate || !this.uploads[id]?.fileKey) {
      return;
    }

    this.evaporate.cancel(this.uploads[id].fileKey);
  }
}
