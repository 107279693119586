import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

type MediaStatusAlertProps = {
  title: string;
  desc: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: 15,
    borderStyle: "dashed",
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    borderWidth: 2
  },
  alertTitle: {
    // textAlign: "center",
    // color: theme.palette.text.primary
  },
  alertDesc: {
    // textAlign: "center",
    // color: theme.palette.text.primary
  }
}));

const MediaStatusAlert = ({ title, desc }: MediaStatusAlertProps) => {
  const classes = useStyles();
  return (
    <Alert className={classes.alert} icon={false}>
      <AlertTitle className={classes.alertTitle}>{title}</AlertTitle>
      <Typography variant="body2" className={classes.alertDesc}>
        {desc}
      </Typography>
    </Alert>
  );
};

export default MediaStatusAlert;
