import React, { useCallback, useEffect, useState } from "react";
import {
  DailySMSUsageDataProvider,
  SMSLimitsResponse
} from "../../providers/data/BasicCrudDataProviders";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Switch,
  Typography
} from "@material-ui/core";
import SmsIcon from "@material-ui/icons/Sms";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      color: "#B5D800",
      "&:hover": {
        cursor: "pointer"
      }
    },
    dialogTitle: {
      backgroundColor: "#B5D800"
    }
  })
);

const SetDailySMSLimitsButton = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [smsBlocked, setSMSBlocked] = useState<SMSLimitsResponse>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  // Get the current config numbers
  useEffect(() => {
    if (smsBlocked) {
      return;
    }

    const dataProvider = new DailySMSUsageDataProvider();
    dataProvider
      .getSMSBlockedStatus()
      .then(setSMSBlocked)
      .finally();
  }, [smsBlocked]);

  const onChangeBlockSMS = (e: any) => {
    setSMSBlocked({ smsBlocked: e.target.checked });
  };

  const onSend = () => {
    new DailySMSUsageDataProvider().updateSMSBlockedStatus({
      data: { smsBlocked: smsBlocked }
    });
    setOpen(false);
  };

  return (
    <div>
      <Tooltip
        className={classes.tooltip}
        placement="top"
        title="Block Trial SMS"
        aria-label="block-trial-sms"
        onClick={handleOpen}
      >
        <Button size="small" startIcon={<SmsIcon />}>
          TRIAL SMS: {smsBlocked?.smsBlocked ? "enabled" : "disabled"}
        </Button>
      </Tooltip>
      {open && (
        <Dialog
          open={true}
          fullWidth={true}
          maxWidth={"xs"}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
            Trial SMS Status
          </DialogTitle>
          <DialogContent>
            <Typography style={{ margin: "18px 0" }}>
              Do you wish to {smsBlocked?.smsBlocked ? "disable" : "enable"} ALL
              SMS&apos;s being sent for trial applications?
            </Typography>
            <Grid container spacing={2}>
              <Switch
                onChange={onChangeBlockSMS}
                checked={smsBlocked?.smsBlocked}
              />
            </Grid>
            <Typography style={{ marginTop: "18px" }}>
              Trial SMS&apos;s are currently{" "}
              <b>{smsBlocked?.smsBlocked ? "Enabled" : "Disabled"}</b>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={onSend} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default SetDailySMSLimitsButton;
