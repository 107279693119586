import Provider from "../Provider";

export type Role = "admin" | "user";

export default class AuthProvider extends Provider {
  // @ts-ignore
  async login({ idToken, accessToken }) {
    const { authToken, roles } = await this.axios
      .post("/auth/login/staff", { idToken, accessToken })
      .then(response => response.data);
    localStorage.setItem("jwt", authToken);
    localStorage.setItem("role", roles);
  }

  async logout() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("role");
    return Promise.resolve();
  }

  checkAuth() {
    return localStorage.getItem("jwt") ? Promise.resolve() : Promise.reject();
  }

  checkError(error: string | { response: { status: number } }) {
    let is401 = false;
    if (typeof error === "string" && error.indexOf("401") >= 0) {
      is401 = true;
    }

    if (
      typeof error !== "string" &&
      error.response &&
      error.response.status === 401
    ) {
      is401 = true;
    }

    if (!is401) {
      return Promise.resolve();
    }

    localStorage.removeItem("jwt");
    localStorage.removeItem("role");
    return Promise.reject();
  }

  async getPermissions() {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  }
}
