import React from "react";
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FormWithRedirect,
  List,
  NumberField,
  NumberInput,
  Pagination,
  SaveButton,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput
} from "react-admin";
import icon from "@material-ui/icons/ListSharp";
import {
  Box,
  Card,
  CardContent,
  Chip,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import { useField } from "react-final-form";
import {
  MEMBER_STATUS_STANDARD,
  MEMBER_STATUS_TRIAL,
  Playlist,
  PlaylistDisplayType,
  PlaylistResponse,
  PlaylistType,
  VideoSort
} from "@booyaltd/core";
import ThumbnailField from "../components/fields/ThumbnailField";
import { VideoDataGrid } from "./Video/VideoList";
import {
  SelectEqualsFilter,
  TextContainsFilter
} from "../components/filters/TextFilters";
import PlaylistMediaInputs from "../components/inputs/PlaylistMediaInputs";
import PlaylistMediaShow from "../components/fields/PlaylistMediaShow";
import TagCreateSelectInput from "../components/inputs/TagCreateSelectInput";
import PermissionGuard from "../components/guards/PermissionGuard";

const SORT_OPTIONS: { [key in VideoSort]: string | false } = {
  [VideoSort.PublishDateAsc]: "Publish Date (Asc)",
  [VideoSort.PublishDate]: "Publish Date (Desc)",
  [VideoSort.CaptureDateAsc]: "Capture Date (Asc)",
  [VideoSort.CaptureDate]: "Capture Date (Desc)",
  [VideoSort.VideoLength]: "Video Length (Acs)",
  [VideoSort.VideoLengthDesc]: "Video Length (Desc)",
  [VideoSort.Popularity]: "Popularity",
  [VideoSort.CustomOrder]: "Custom Order Field",
  [VideoSort.Relevance]: false,
  [VideoSort.FavouritedDate]: false,
  [VideoSort.FavouritedCount]: false,
  [VideoSort.WatchedDate]: false
};

const TYPE_OPTIONS = [
  { id: "standard", name: "Playlist" },
  { id: "series", name: "Series" }
];

const DISPLAY_TYPE_OPTIONS = [
  { id: "video-thumbs", name: "Video Thumbs" },
  { id: "horizontal-poster", name: "Poster (Horizontal)" },
  { id: "vertical-poster", name: "Poster (Vertical)" },
  { id: "single-video-poster", name: "Single Video Poster" }
];

const PlaylistFilter = (props: {}) => (
  <Filter {...props}>
    <TextContainsFilter label="Name" source="name" alwaysOn />
    <TextContainsFilter label="Video Tag" source="tag" alwaysOn />
    <SelectEqualsFilter source="type" choices={TYPE_OPTIONS} alwaysOn />
    <SelectEqualsFilter
      source="displayType"
      choices={DISPLAY_TYPE_OPTIONS}
      alwaysOn
    />
  </Filter>
);

export const PlaylistList = (props: {}) => (
  <List
    filters={<PlaylistFilter />}
    sort={{ field: "order", order: "ASC" }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField source="name" sortable={false} />
      <ThumbnailField
        source="thumbnailMediaUrl"
        sortable={false}
        label="Thumb"
      />
      <ThumbnailField
        source="thumbnailTransparentMediaUrl"
        sortable={false}
        label="Thumb (Transparent BG)"
      />
      <ThumbnailField
        source="posterHorizontalMediaUrlThumb"
        sortable={false}
        label="Poster (H)"
      />
      <ThumbnailField
        source="posterVerticalMediaUrlThumb"
        sortable={false}
        label="Poster (V)"
      />
      <SelectField source="type" sortable={false} choices={TYPE_OPTIONS} />
      <SelectField
        source="displayType"
        sortable={false}
        choices={DISPLAY_TYPE_OPTIONS}
      />
      <ChipField source="tag" sortable={false} label="Video Tag" />
      <NumberField sortable={true} source="order" />
      <BooleanField sortable={false} source="active" label="On Homescreen" />
      <PermissionGuard role="admin">
        <EditButton />
      </PermissionGuard>
      <ShowButton />
    </Datagrid>
  </List>
);

const PlaylistTitle = ({ record }: { record?: Playlist }) => {
  return (
    <span>
      Playlist: {record && record.name ? `${record.name}` : "Create Playlist"}
    </span>
  );
};

const useStyles = makeStyles({
  root: {
    flex: 1,
    margin: "1em"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginTop: "0.5em"
  }
});

const Summary = ({ record }: { record?: PlaylistResponse }) => {
  const classes = useStyles();

  if (!record) {
    return null;
  }

  const {
    name,
    type,
    displayType,
    description,
    tag,
    defaultVideoSort,
    order,
    active,
    showForMemberStatuses,
    posterHorizontalMediaUrl,
    posterVerticalMediaUrl,
    thumbnailMediaUrl,
    thumbnailTransparentMediaUrl
  } = record;

  const isSeries = type.toString() === PlaylistType.Series.toString();

  return (
    <Box>
      <Box display="flex">
        <Box flex={1}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                {isSeries ? "Series" : "Playlist"} Details
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Name
              </Typography>
              <Typography variant="body2" component="p">
                {name}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Description
              </Typography>
              <Typography variant="body2" component="p">
                {description}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Is Shown on Homescreen
              </Typography>
              <Typography variant="body2" component="p">
                <Chip
                  label={active ? "Yes" : "No"}
                  variant="outlined"
                  color={active ? "primary" : "secondary"}
                />
              </Typography>
            </CardContent>
          </Card>
        </Box>

        <Box flex={1}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Video Settings
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Show videos with tag
              </Typography>
              <Typography variant="body2" component="p">
                <Chip variant="outlined" label={tag} />
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Order videos by
              </Typography>
              <Typography variant="body2" component="p">
                {defaultVideoSort}
              </Typography>
            </CardContent>
          </Card>
        </Box>

        <Box flex={1}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Display Settings
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Display Type
              </Typography>
              <Typography variant="body2" component="p">
                {displayType
                  ? DISPLAY_TYPE_OPTIONS.find(o => o.id === displayType)?.name
                  : "Default"}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Order
              </Typography>
              <Typography variant="body2" component="p">
                {order}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Show for members
              </Typography>
              <Typography variant="body2" component="p">
                {showForMemberStatuses &&
                  showForMemberStatuses.length > 0 &&
                  showForMemberStatuses.map(seg => (
                    <Chip key={seg} variant="outlined" label={seg} />
                  ))}
                {(!showForMemberStatuses ||
                  showForMemberStatuses.length === 0) &&
                  "All"}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Show top of list for member with status
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>

      <PlaylistMediaShow
        thumbnailMediaUrl={thumbnailMediaUrl}
        thumbnailTransparentMediaUrl={thumbnailTransparentMediaUrl}
        posterHorizontalMediaUrl={posterHorizontalMediaUrl}
        posterVerticalMediaUrl={posterVerticalMediaUrl}
      />
    </Box>
  );
};

const VideoList = ({ record, ...props }: { record?: Playlist }) => {
  if (!record) {
    return null;
  }

  return (
    <List
      {...props}
      basePath="/video"
      resource="video"
      hasCreate={false}
      hasShow={true}
      hasEdit={false}
      perPage={1000}
      pagination={<Pagination />}
      exporter={false}
      filter={{
        playlistIds__contains: record.id
      }}
      sort={{ field: "masterReleaseDate", order: "desc" }}
    >
      <VideoDataGrid />
    </List>
  );
};

export const PlaylistShow = (props: {}) => (
  <Show {...props} title={<PlaylistTitle />}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <Summary />
      </Tab>

      <Tab label="Videos">
        <VideoList />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const PlaylistEdit = (props: {}) => (
  <Edit {...props} title={<PlaylistTitle />}>
    <PlaylistForm />
  </Edit>
);

export const PlaylistCreate = (props: {}) => (
  <Create {...props} title={<PlaylistTitle />}>
    <PlaylistForm />
  </Create>
);

const PlaylistForm = (props: any = {}) => (
  <FormWithRedirect
    {...props}
    render={(formProps: any = {}) => {
      const {
        input: { value: type }
      } = useField("type");

      const isSeries = type.toString() === PlaylistType.Series.toString();

      // @ts-ignore
      return (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h5" gutterBottom>
                  {isSeries ? "Series" : "Playlist"}
                </Typography>

                <Box display="flex" mb="1em">
                  <Box ml="0.5em" pt="0.75em">
                    <BooleanInput
                      label="On Homescreen"
                      source="active"
                      defaultValue={true}
                      fullWidth
                    />
                  </Box>

                  <Box flex={2} mr="0.5em">
                    <SelectInput
                      source="type"
                      defaultValue={PlaylistType.Standard.toString()}
                      sortable={false}
                      choices={TYPE_OPTIONS}
                      fullWidth={true}
                    />
                    <SelectInput
                      source="displayType"
                      defaultValue={PlaylistDisplayType.VideoThumbs.toString()}
                      sortable={false}
                      choices={DISPLAY_TYPE_OPTIONS}
                      fullWidth={true}
                    />
                  </Box>

                  <Box flex={2} mr="0.5em">
                    <TextInput label="Name" source="name" fullWidth />
                  </Box>
                  <Box flex={4} ml="0.5em">
                    <TextInput
                      label="Description"
                      source="description"
                      fullWidth
                    />
                  </Box>
                </Box>

                <Typography variant="h6" gutterBottom>
                  Video Settings
                </Typography>

                <Box display="flex" flex={1} mb="1em">
                  <TagCreateSelectInput />
                  <Box flex={1} mr="1em">
                    <SelectInput
                      source="defaultVideoSort"
                      label="Sort videos by"
                      choices={Object.keys(SORT_OPTIONS)
                        // @ts-ignore
                        .filter(opt => !!SORT_OPTIONS[opt.toString()])
                        .map(opt => ({
                          id: opt.toString(),
                          // @ts-ignore
                          name: SORT_OPTIONS[opt.toString()].toString()
                        }))}
                      fullWidth
                    />
                  </Box>
                </Box>

                <Typography variant="h6" gutterBottom>
                  Display Settings
                </Typography>

                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <NumberInput
                      label="Default order"
                      source="order"
                      fullWidth
                      defaultValue={0}
                    />
                  </Box>
                  <Box flex={1} mr="1em">
                    <SelectArrayInput
                      label="Show for members with status"
                      source="showForMemberStatuses"
                      choices={[
                        { id: MEMBER_STATUS_STANDARD, name: "Standard Member" },
                        { id: MEMBER_STATUS_TRIAL, name: "Trial Member" }
                      ]}
                      optionText="name"
                      allowEmpty
                      fullWidth
                      helperText={"Leave blank to show to all members"}
                    />
                  </Box>
                </Box>

                <Typography variant="h6" gutterBottom>
                  Display Settings
                </Typography>

                <PlaylistMediaInputs />
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                redirect="list"
              />
              <DeleteButton
                record={formProps.record}
                resource={formProps.resource}
              />
            </Box>
          </Toolbar>
        </form>
      );
    }}
  />
);

export const PlaylistIcon = icon;
