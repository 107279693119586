import React from "react";

import "./index.css";

import { Admin, Resource } from "react-admin";

import Dashboard from "./Dashboard";
import MyLayout from "./MyLayout.js";

import DataProviderWrapper from "./providers/data";
import {
  PlaylistCreate,
  PlaylistEdit,
  PlaylistIcon,
  PlaylistList,
  PlaylistShow
} from "./resources/PlaylistResource";
import {
  ChannelCreate,
  ChannelEdit,
  ChannelIcon,
  ChannelList,
  ChannelShow
} from "./resources/ChannelResource";
import {
  ProfileIcon,
  ProfileList,
  ProfileShow,
  ProfileCreate,
  ProfileEdit,
  DeviceEdit,
  EntitlementCreate,
  EntitlementEdit
} from "./resources/ProfileResource";
import AuthProvider, { Role } from "./providers/auth/AuthProvider";
import LoginPage from "./components/login/LoginPage";
import { createBrowserHistory } from "history";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  LandingPageCreate,
  LandingPageEdit,
  LandingPageIcon,
  LandingPageList
} from "./resources/LandingPageResource";
import {
  InboxNotificationIcon,
  InboxNotificationList
} from "./resources/InboxNotification/InboxNotificationList";
import { TagIcon, TagList } from "./resources/TagResource";
import {
  SubscriptionIcon,
  SubscriptionList,
  SubscriptionShow
} from "./resources/SubscriptionResource";
import {
  PaymentList,
  PaymentIcon,
  PaymentShow
} from "./resources/PaymentResource";
import {
  GiftcardIcon,
  GiftcardList,
  GiftcardCreate,
  GiftcardEdit
} from "./resources/GiftcardResource";
import {
  ProductIcon,
  ProductList,
  ProductEdit
} from "./resources/ProductResource";
import { CommsIcon, CommsShow } from "./resources/CommsResource";
import DailyUserActivityList from "./resources/UserActivity/DailyUserActivityList";
import { Timeline } from "@material-ui/icons";
import {
  NotificationLogIcon,
  NotificationLogList
} from "./resources/NotificationLog/NotificationLogList";
// @ts-ignore
import TreeMenu from "@bb-tech/ra-treemenu";
import ScheduledNotificationList from "./resources/ScheduledNotification/ScheduledNotificationList";
import {
  ScheduledNotificationCreate,
  ScheduledNotificationEdit
} from "./resources/ScheduledNotification/ScheduledNotificationForms";
import {
  ProfileSegmentIcon,
  ProfileSegmentList
} from "./resources/ProfileSegmentResource";
import {
  NotificationTemplateIcon,
  NotificationTemplateList
} from "./resources/NotificationTemplateResource";
import InboxNotificationCreate from "./resources/InboxNotification/InboxNotificationCreate";
import InboxNotificationEdit from "./resources/InboxNotification/InboxNotificationEdit";
import { NotificationPreferenceCreate } from "./resources/NotificationPreferenceResource";
import {
  GiftcardApplicationIcon,
  GiftcardApplicationList
} from "./resources/GiftcardApplication/GiftcardApplicationList";
import GiftcardApplicationCreate from "./resources/GiftcardApplication/GiftcardApplicationCreate";
import { VideoIcon } from "./resources/Video/styles";
import { VideoList } from "./resources/Video/VideoList";
import { VideoCreate, VideoEdit } from "./resources/Video/VideoCreateEdit";
import { VideoShow } from "./resources/Video/show/VideoShow";
import { BulkEditVideoList } from "./resources/Video/BulkEditVideoList";
import {
  SearchSynonymIcon,
  SearchSynonymList
} from "./resources/SearchSynonymResource";
import { SearchTermIcon, SearchTermList } from "./resources/SearchTermResource";
import {
  VideoSearchIcon,
  VideoSearchList
} from "./resources/VideoSearchResource";
import { SoftLinkIcon, SoftLinkList } from "./resources/SoftLinkResource";
import {
  PromoItemCreate,
  PromoItemEdit,
  PromoItemIcon,
  PromoItemList
} from "./resources/PromoItemResource";
import ScheduledNotificationShow from "./resources/ScheduledNotification/ScheduledNotificationShow";
import {
  DailySMSUsageIcon,
  DailySMSUsageList,
  DailySMSUsageShow,
  DailySMSUsageEdit
} from "./resources/SMSLimits/SMSUsageResource";

const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: { main: "#B5D800" },
    secondary: { main: "#3d3d3d" }
  }
});

const App: React.FC = () => {
  return (
    <Admin
      layout={MyLayout}
      theme={theme}
      menu={TreeMenu}
      dataProvider={new DataProviderWrapper()}
      authProvider={new AuthProvider()}
      loginPage={LoginPage}
      history={history}
      dashboard={Dashboard}
    >
      {(permissions: Role) => {
        const isAdmin = permissions.includes("admin");
        const isEditor = permissions.includes("editor");

        const resources = [];

        if (isAdmin) {
          resources.push(
            <Resource
              name={"member"}
              options={{ label: "Members" }}
              icon={ProfileIcon}
              list={ProfileList}
              show={ProfileShow}
              create={ProfileCreate}
              edit={ProfileEdit}
            />
          );
        }

        if (isAdmin || isEditor) {
          resources.push(
            <Resource
              key={"catalog"}
              name={"catalog"}
              options={{ label: "Catalog", isMenuParent: true }}
            />,
            <Resource
              key={"video"}
              name={"video"}
              options={{ label: "Video", menuParent: "catalog" }}
              icon={VideoIcon}
              show={VideoShow}
              list={VideoList}
              create={VideoCreate}
              edit={VideoEdit}
            />,
            <Resource
              key={"channel"}
              name={"channel"}
              options={{ label: "Channels", menuParent: "catalog" }}
              icon={ChannelIcon}
              list={ChannelList}
              show={ChannelShow}
              edit={isAdmin ? ChannelEdit : undefined}
              create={isAdmin ? ChannelCreate : undefined}
            />,
            <Resource
              key={"playlist"}
              name={"playlist"}
              options={{ label: "Series & Playlists", menuParent: "catalog" }}
              icon={PlaylistIcon}
              list={PlaylistList}
              show={PlaylistShow}
              edit={isAdmin ? PlaylistEdit : undefined}
              create={isAdmin ? PlaylistCreate : undefined}
            />
          );
        }

        if (isAdmin) {
          resources.push(
            <Resource
              key={"daily-user-activity"}
              name={"daily-user-activity"}
              options={{ label: "Daily User Activity" }}
              icon={Timeline}
              list={DailyUserActivityList}
            />,
            <Resource
              key={"giftcard"}
              name={"giftcard"}
              options={{ label: "Giftcards" }}
              icon={GiftcardIcon}
              list={GiftcardList}
              create={GiftcardCreate}
              edit={GiftcardEdit}
            />,
            <Resource
              key={"giftcard-application"}
              name={"giftcard-application"}
              options={{ label: "Giftcard Applications" }}
              icon={GiftcardApplicationIcon}
              list={GiftcardApplicationList}
              create={GiftcardApplicationCreate}
            />,
            <Resource key={"watch-history"} name={"watch-history"} />,
            <Resource key={"device"} name={"device"} edit={DeviceEdit} />,
            <Resource
              key={"member-status-change"}
              name={"member-status-change"}
              create={EntitlementCreate}
              edit={EntitlementEdit}
            />,
            <Resource
              key={"video-tag-entitlement"}
              name={"video-tag-entitlement"}
            />,
            <Resource
              key={"payment"}
              name={"payment"}
              options={{ label: "Payments" }}
              show={PaymentShow}
              icon={PaymentIcon}
              list={PaymentList}
            />,
            <Resource
              key={"notifications"}
              name={"notifications"}
              options={{ label: "Notifications", isMenuParent: true }}
            />,
            <Resource
              key={"SMS Usage"}
              name={"smsusage"}
              options={{ label: "SMS Usage", menuParent: "notifications" }}
              icon={DailySMSUsageIcon}
              list={DailySMSUsageList}
              show={DailySMSUsageShow}
              edit={DailySMSUsageEdit}
            />,
            <Resource
              key={"scheduled-notification"}
              name={"scheduled-notification"}
              options={{
                label: "Scheduled",
                menuParent: "notifications"
              }}
              icon={NotificationLogIcon}
              show={ScheduledNotificationShow}
              create={ScheduledNotificationCreate}
              edit={ScheduledNotificationEdit}
              list={ScheduledNotificationList}
            />,
            <Resource
              key={"notification-log"}
              name={"notification-log"}
              options={{ label: "Log", menuParent: "notifications" }}
              icon={NotificationLogIcon}
              list={NotificationLogList}
            />,
            <Resource
              key={"inbox-notification"}
              name={"inbox-notification"}
              options={{ label: "Inbox", menuParent: "notifications" }}
              icon={InboxNotificationIcon}
              list={InboxNotificationList}
              edit={InboxNotificationEdit}
              create={InboxNotificationCreate}
            />,
            <Resource
              key={"landing-page"}
              name={"landing-page"}
              options={{
                label: "App Landing Pages",
                menuParent: "notifications"
              }}
              icon={LandingPageIcon}
              list={LandingPageList}
              edit={LandingPageEdit}
              create={LandingPageCreate}
            />,
            <Resource
              key={"automated-comms"}
              name={"automated-comms"}
              options={{ label: "Templates", menuParent: "notifications" }}
              icon={CommsIcon}
              list={CommsShow}
            />
          );
        }

        if (isAdmin || isEditor) {
          resources.push(
            <Resource
              key={"search"}
              name={"search"}
              options={{ label: "Search", isMenuParent: true }}
            />,
            isAdmin ? (
              <Resource
                key={"video-search"}
                name={"video-search"}
                options={{ label: "Search History", menuParent: "search" }}
                icon={VideoSearchIcon}
                list={VideoSearchList}
              />
            ) : null,
            isAdmin ? (
              <Resource
                key={"bulk-video"}
                name={"bulk-video"}
                options={{
                  label: "Video Metadata Editor",
                  menuParent: "search"
                }}
                icon={VideoIcon}
                list={BulkEditVideoList}
              />
            ) : null,
            <Resource
              key={"search-term"}
              name={"search-term"}
              options={{ label: "Search Terms", menuParent: "search" }}
              icon={SearchTermIcon}
              list={SearchTermList}
            />,
            <Resource
              key={"soft-link"}
              name={"soft-link"}
              options={{ label: "Soft Links", menuParent: "search" }}
              icon={SoftLinkIcon}
              list={SoftLinkList}
            />,
            isAdmin ? (
              <Resource
                key={"search-synonym"}
                name={"search-synonym"}
                options={{ label: "Search Synonyms", menuParent: "search" }}
                icon={SearchSynonymIcon}
                list={SearchSynonymList}
              />
            ) : null
          );
        }

        if (isAdmin) {
          resources.push(
            <Resource
              key={"setup"}
              name={"setup"}
              options={{ label: "Setup", isMenuParent: true }}
            />,
            <Resource
              key={"product"}
              name={"product"}
              options={{ label: "Products", menuParent: "setup" }}
              icon={ProductIcon}
              list={ProductList}
              edit={ProductEdit}
            />,
            <Resource
              key={"promo-item"}
              name={"promo-item"}
              options={{ label: "Promo Items", menuParent: "setup" }}
              icon={PromoItemIcon}
              list={PromoItemList}
              create={PromoItemCreate}
              edit={PromoItemEdit}
            />,
            <Resource
              key={"member-status"}
              name={"member-status"}
              options={{ label: "Member Status", menuParent: "setup" }}
              icon={SubscriptionIcon}
              show={SubscriptionShow}
              list={SubscriptionList}
            />
          );
        }

        if (isEditor || isAdmin) {
          resources.push(
            <Resource
              key={"tag"}
              name={"tag"}
              options={{
                label: "Tags",
                menuParent: isAdmin ? "setup" : "search"
              }}
              icon={TagIcon}
              list={TagList}
            />
          );
        }

        if (isAdmin) {
          resources.push(
            <Resource
              key={"profile-segment"}
              name={"profile-segment"}
              options={{ label: "Profile Segments", menuParent: "setup" }}
              icon={ProfileSegmentIcon}
              list={ProfileSegmentList}
            />,
            <Resource
              key={"notification-template"}
              name={"notification-template"}
              options={{ label: "Templates", menuParent: "setup" }}
              icon={NotificationTemplateIcon}
              list={NotificationTemplateList}
            />,
            <Resource
              key={"notification-preference"}
              name={"notification-preference"}
              create={NotificationPreferenceCreate}
            />
          );
        }

        resources.push(<Resource key={"media-item"} name={"media-item"} />);

        return resources;
      }}
    </Admin>
  );
};

export default App;
