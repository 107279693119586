import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Videocam } from "@material-ui/icons";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    container: {
      padding: theme.spacing(2)
    },
    title: {
      fontSize: "1.5rem"
    },
    containerLink: {
      width: "100% !important"
    },
    sectionTitle: {
      fontSize: "1rem",
      color: "#3e3e3e",
      borderBottom: "1px solid #bbb",
      paddingBottom: "5px",
      marginBottom: "10px"
    },
    sectionDetail: {
      marginBottom: "12px",
      "& span": {
        color: "#3e3e3e"
      }
    },
    uploadItemsLink: {
      "&:hover": {
        textDecoration: "none"
      }
    },
    warningIcon: {
      fontSize: "3rem",
      fill: "#f7ad51"
    },
    uploadItemsAlert: {
      textAlign: "center"
    },
    videoTitleField: {
      "& .MuiFilledInput-root": {
        marginBottom: "4px"
      }
    },
    videoSlugLabel: {
      fontSize: "0.8rem",
      fontWeight: 500,
      marginTop: "8px",
      marginBottom: "12px",
      color: "#3e3e3e",
      "& span": {
        fontWeight: 600,
        color: "3e3e3e"
      }
    },
    inputTitle: {
      borderBottom: "1px solid #bbb",
      paddingBottom: "10px",
      marginBottom: "10px",
      color: "#3e3e3e",
      "& span": {
        fontSize: "1.2rem"
      }
    },
    inputSubTitle: {
      margin: "10px 0",
      fontSize: "0.9rem",
      color: "#3e3e3e"
    },
    inputGroup: {
      padding: "5px",
      borderRadius: "5px 5px 0 0",
      backgroundColor: "#f7f7f7",
      marginBottom: "10px",
      "& label": {
        width: "100%",
        "& .MuiCheckbox-root": {
          padding: "3px 9px"
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "0.9rem"
        }
      },
      "& .MuiFormHelperText-root": {
        display: "none"
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "#fff",
        marginBottom: "20px",
        "&:hover": {
          backgroundColor: "#fff"
        }
      }
    },
    checkboxGroup: {
      paddingLeft: "15px",
      "& label": {
        width: "100%",
        "& .MuiCheckbox-root": {
          padding: "3px 9px"
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "0.9rem"
        }
      },
      "& .MuiFormHelperText-root": {
        display: "none"
      }
    },
    zeroPad: {
      padding: 0
    },
    imageField: {
      maxWidth: "100px"
    },
    cardBottomMargin: {
      marginBottom: theme.spacing(2)
    }
  })
);

export const VideoIcon = Videocam;
