import React, { ChangeEvent } from "react";
import { useField, useForm } from "react-final-form";
import {
  getNotificationTitleAndChannelFromVideoName,
  slugify
} from "../../utils";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from "@material-ui/core";
import {
  AutocompleteArrayInput,
  Create,
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextInput
} from "react-admin";
import { Playlist, PlaylistType, VideoRegion } from "@booyaltd/core";
import { useStyles } from "./styles";
import DateInput from "../../components/inputs/DateInput";
import { MemberStatus } from "../../entities/Subscription";

export const VideoForm = () => {
  const classes = useStyles();
  const form = useForm();
  const {
    input: { value: id }
  } = useField("id");
  const {
    input: { value: name }
  } = useField("name");
  const {
    input: { value: tags }
  } = useField("tags");
  const {
    input: { onChange: onChangeRegion }
  } = useField("region");
  const {
    input: { onChange: onChangePreviewReleaseDate }
  } = useField("previewReleaseDate");

  const onNameChange = (name: string) => {
    form.change("name", name);
    form.change("slug", slugify(name));
  };

  const [, notificationName] = getNotificationTitleAndChannelFromVideoName(
    name || ""
  );

  return (
    <Grid container spacing={2} style={{ width: "100%" }}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.title}>
          {id ? "Edit Video" : "Create Video"}
        </Typography>
      </Grid>

      <Grid item xs={8}>
        {/* Title, Slug, Description  */}
        <Card className={classes.inputGroup}>
          <CardHeader title="Video Details" className={classes.inputTitle} />
          <CardContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <SelectInput
                    label="Type"
                    source="type"
                    fullWidth
                    className={classes.videoTitleField}
                    defaultValue="episode"
                    choices={[
                      { id: "episode", name: "Episode" },
                      { id: "other", name: "Other" }
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label="Title"
                    source="name"
                    fullWidth
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onNameChange(e.target.value)
                    }
                    className={classes.videoTitleField}
                  />
                  <Typography variant="body2">
                    <strong>For AgLife: </strong>AgLife - Mourne View Farm - Ep5
                    <br />
                    <strong>For Other: </strong>Stockyards - Ep30 - Severndale
                    Farm
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {notificationName ? (
                    <>
                      <Typography>Parsed Notification Title:</Typography>
                      <Typography>{notificationName}</Typography>
                    </>
                  ) : (
                    <Typography>
                      Title not suitable for notifications
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={9}>
                  <TextInput label="Slug" source="slug" fullWidth />
                </Grid>
                <Grid item xs={3}>
                  <TextInput source="episode" label="Episode No." fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Description"
                    source="description"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateInput
                    label="Capture Date"
                    source="captureDate"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateInput
                    label="Master Release Date"
                    source="masterReleaseDate"
                    onChangeAdditional={onChangePreviewReleaseDate} // Auto update preview date when master changes
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateInput
                    label="Preview Release Date"
                    source="previewReleaseDate"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>

        {/* Additional Data  */}
        <Card className={classes.inputGroup}>
          <CardHeader title="Additional Data" className={classes.inputTitle} />
          <CardContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    source="customOrder"
                    label="Custom Order"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInput source="videographer" fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    source="location"
                    label="Episode Location"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        {/* Tags Selection - to split into Channels / Playlists / Additional Tags */}
        <Card className={classes.inputGroup}>
          <CardHeader title="Linking" className={classes.inputTitle} />
          <CardContent className={classes.zeroPad}>
            <Box className={classes.checkboxGroup}>
              <SelectInput
                label="Region"
                source="region"
                fullWidth
                className={classes.videoTitleField}
                defaultValue="ni"
                choices={[
                  { id: VideoRegion.NI.toString(), name: "NI" },
                  { id: VideoRegion.ROI.toString(), name: "ROI" },
                  { id: VideoRegion.GB.toString(), name: "GB" },
                  { id: VideoRegion.USA.toString(), name: "USA" }
                ]}
                onChange={(e, ...args) => {
                  onChangeRegion(e, ...args);
                  if (!tags || !tags.includes(e.target.value)) {
                    console.log(e.target.value);
                    form.change("tags", [...(tags || []), e.target.value]);
                  }
                }}
              />
              <ReferenceInput reference="channel" source="channelId">
                <SelectInput fullWidth optionText="name" />
              </ReferenceInput>
              <ReferenceArrayInput
                label="Playlists & Series"
                reference="playlist"
                source="playlistIds"
                sort={{ field: "name", order: "asc" }}
              >
                <AutocompleteArrayInput
                  fullWidth
                  optionText={(playlist: Playlist) =>
                    playlist
                      ? `${playlist.name} (${
                          playlist.type.toString() ===
                          PlaylistType.Series.toString()
                            ? "Series"
                            : "Playlist"
                        })`
                      : null
                  }
                />
              </ReferenceArrayInput>

              <ReferenceArrayInput
                label="Restricted from member statuses"
                reference="member-status"
                source="restrictedFromMemberStatuses"
                sort={{ field: "name", order: "asc" }}
              >
                <AutocompleteArrayInput
                  fullWidth
                  optionText={(status: MemberStatus) => status?.name}
                />
              </ReferenceArrayInput>

              {/*<ReferenceInput*/}
              {/*  reference="video"*/}
              {/*  source="linkedVideoId"*/}
              {/*  label="Hard Linked Video (e.g. Part 1 / Part 2)"*/}
              {/*  sort={{ field: "name", order: "asc" }}*/}
              {/*>*/}
              {/*  <AutocompleteInput fullWidth optionText="name" />*/}
              {/*</ReferenceInput>*/}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const VideoEdit = (props: {}) => {
  return (
    <Edit {...props} title="Edit Video">
      <SimpleForm redirect="show">
        <VideoForm />
      </SimpleForm>
    </Edit>
  );
};

export const VideoCreate = (props: ResourceComponentProps) => {
  return (
    <Create {...props} title={"Create Video"}>
      <SimpleForm redirect="show">
        <VideoForm />
      </SimpleForm>
    </Create>
  );
};
