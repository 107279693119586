import React, { useCallback, useEffect, useMemo } from "react";
import { Video } from "../../../../../entities/Catalog";
import {
  useDataProvider,
  useGetOne,
  useLoading,
  useNotify,
  useRefresh
} from "react-admin";
import { IUserMediaItem } from "@booyaltd/core";
import { Box, Typography } from "@material-ui/core";
import VideoManagerMediaItemSummary from "./VideoManagerMediaItemSummary";
import { UserMediaItemDataProvider } from "../../../../../providers/data/BasicCrudDataProviders";
import VideoProviderManager from "./VideoProviderManager";
import VideoOriginalUploader from "./VideoOriginalUploader";
import MultipartUploadStore, {
  UploadEvent
} from "../../../../../mobx/MultipartUploadStore";
import { container } from "tsyringe";
import UploadProgress from "../../../../../components/upload/UploadProgress";
import { observer } from "mobx-react";

type VideoProviderSummaryProps = {
  video: Video;
  type: "master" | "preview";
};

const VideoManagerPanel = observer(
  ({ video, type }: VideoProviderSummaryProps) => {
    const store = container.resolve(MultipartUploadStore);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const loading = useLoading();
    const refresh = useRefresh();
    const [mediaItem, setMediaItem] = React.useState<
      (IUserMediaItem & { downloadUrl?: string }) | null
    >(null);

    const origMediaId = useMemo(
      () =>
        (type === "master"
          ? video.masterOrigMediaId
          : video.previewOrigMediaId) || undefined,
      [type, video.masterOrigMediaId, video.previewOrigMediaId]
    );

    const [editing, setEditing] = React.useState(!origMediaId);

    useGetOne<IUserMediaItem>("media-item", String(origMediaId), {
      enabled: !!origMediaId,
      onSuccess: ({ data }: { data: IUserMediaItem }) => {
        console.log("got media item", data);
        setMediaItem(data);
      }
    });

    const onFinishEvent = useCallback(() => {
      console.log("finished");
      refresh(false);
      setEditing(false);
    }, [refresh, setEditing]);

    const upload =
      mediaItem?.id || origMediaId
        ? store.uploads[String(mediaItem?.id || origMediaId)]
        : undefined;

    useEffect(() => {
      if (!mediaItem) return;

      store.subscribeToEvent(
        mediaItem.id,
        UploadEvent.UPLOAD_COMPLETED,
        onFinishEvent
      );

      store.subscribeToEvent(
        mediaItem.id,
        UploadEvent.UPLOAD_CANCELLED,
        onFinishEvent
      );

      store.subscribeToEvent(
        mediaItem.id,
        UploadEvent.UPLOAD_ERROR,
        onFinishEvent
      );

      return () => {
        store.unsubscribeFromEvent(
          mediaItem.id,
          UploadEvent.UPLOAD_COMPLETED,
          onFinishEvent
        );
        store.unsubscribeFromEvent(
          mediaItem.id,
          UploadEvent.UPLOAD_CANCELLED,
          onFinishEvent
        );
        store.unsubscribeFromEvent(
          mediaItem.id,
          UploadEvent.UPLOAD_ERROR,
          onFinishEvent
        );
      };
    }, [mediaItem?.id]);

    const onMediaChanged = (newMedia: IUserMediaItem) => {
      if (loading) {
        notify("Error changing videos media id, already updating", "error");
      }

      return dataProvider.update("video", {
        id: video.id,
        previousData: video,
        data:
          type === "master"
            ? {
                ...video,
                masterOrigMediaId: newMedia.id,
                masterProvider: null,
                masterProviderId: null,
                masterProviderReady: false,
                masterLengthSecs: null
              }
            : {
                ...video,
                previewOrigMediaId: newMedia.id,
                previewProvider: null,
                previewProviderId: null,
                previewProviderReady: false,
                previewLengthSecs: null
              }
      });
    };

    const onBeginUpload = () => {
      setEditing(true);
    };

    const onReprocess = (mediaId: string) => {
      if (!mediaItem || loading) {
        return;
      }

      const dataProvider = new UserMediaItemDataProvider();
      return dataProvider
        .reprocessMediaItem(mediaId)
        .then(() => {
          notify(
            "Reprocessing started - Please wait a few minutes for it to process"
          );
        })
        .catch(() => {
          notify("Error reprocessing video", "error");
        })
        .finally(() => {
          refresh();
        });
    };

    return (
      <Box width="100%" flex={1} display="flex" flexDirection="column">
        <Box mt={2}>
          <Typography variant="h5">
            {type === "master" ? "Master Upload" : "Preview Upload"}
          </Typography>
          {upload ? (
            <UploadProgress
              uploadState={upload.state}
              mediaItem={upload.mediaItem}
              stats={upload.stats}
            />
          ) : editing ? (
            <VideoOriginalUploader
              onMediaChanged={onMediaChanged}
              onCancel={mediaItem ? () => setEditing(false) : undefined}
            />
          ) : mediaItem ? (
            <VideoManagerMediaItemSummary
              mediaItem={mediaItem}
              downloadUrl={mediaItem.downloadUrl}
              onBeginUpload={onBeginUpload}
            />
          ) : null}
        </Box>

        <Box mt={2}>
          <VideoProviderManager
            video={video}
            type={type}
            onReprocess={onReprocess}
          />
        </Box>
      </Box>
    );
  }
);

export default VideoManagerPanel;
