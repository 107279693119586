import IDataProvider from "./IDataProvider";
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from "react-admin";
import Provider from "../Provider";
import { Video } from "../../entities/Catalog";
import { DailySMSLimits } from "../../entities/DailySMSUsage";
import { DailyUserActivity } from "../../entities/Profile";
import { NotificationPreference } from "../../entities/Notification";
import { AxiosResponse } from "axios";
import GiftcardApplication from "../../entities/Giftcard";
import { COUNTRY_CODES } from "../../constants";
import { chunkArray } from "../../utils";
import AdminAutoCrudDataProvider from "./AdminAutoCrudDataProvider";
import {
  DetailedVideoSearchResult,
  IUserMediaItem,
  NotificationLogStatus,
  NotificationLogType,
  ScheduledNotification,
  UserProfileFilters
} from "@booyaltd/core";

abstract class BasicCrudDataProvider<T> extends Provider
  implements IDataProvider<T> {
  protected constructor(
    protected basePath: string,
    protected canRead: boolean,
    protected canCreate: boolean,
    protected canUpdate: boolean,
    protected canDelete: boolean
  ) {
    super();
  }

  buildUrl(id?: string | number): string {
    return `${this.basePath}${id ? `/${id}` : ""}`;
  }

  getList(params: GetListParams): Promise<GetListResult<T>> {
    if (!this.canRead) {
      throw new Error("Not Implemented");
    }

    return this.axios
      .get(this.buildUrl(), {
        params: {
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          limit: params.pagination.perPage,
          sort: `${params.sort.field} ${params.sort.order}`,
          ...params.filter
        }
      })
      .then(response => ({
        data: response.data.results,
        total: response.data.total
      }));
  }

  getMany(params: GetManyParams): Promise<GetManyResult<T>> {
    if (!this.canRead) {
      throw new Error("Not Implemented");
    }
    return this.axios
      .get(this.buildUrl(), {
        params: {
          ids: params.ids
        }
      })
      .then(response => ({
        data: response.data.results,
        total: response.data.total
      }));
  }

  getOne(params: GetOneParams): Promise<GetOneResult<T>> {
    if (!this.canRead) {
      throw new Error("Not Implemented");
    }
    return this.axios
      .get(this.buildUrl(), {
        params: {
          id: params.id
        }
      })
      .then((response: AxiosResponse<{ results: T[] }>) => ({
        data: response.data.results[0]
      }));
  }

  update(params: UpdateParams<T>): Promise<UpdateResult<T>> {
    if (!this.canUpdate || !params.id) {
      throw new Error("Not Implemented");
    }
    return this.axios
      .put(this.buildUrl(params.id), params.data)
      .then(response => ({ data: response.data }));
  }

  create(params: CreateParams<T>): Promise<CreateResult<T>> {
    if (!this.canCreate) {
      throw new Error("Not Implemented");
    }
    return this.axios
      .post(this.buildUrl(), params.data)
      .then(response => ({ data: response.data }));
  }

  delete(params: DeleteParams): Promise<any> {
    if (!this.canDelete) {
      throw new Error("Not Implemented");
    }
    return this.axios.delete(this.buildUrl(params.id));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteMany(params: DeleteManyParams): Promise<DeleteManyResult> {
    throw new Error("Not Implemented");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateMany(params: UpdateManyParams): Promise<UpdateManyResult> {
    throw new Error("Not Implemented");
  }
}

export class VideoDataProvider extends BasicCrudDataProvider<Video> {
  constructor() {
    super("/videos", true, true, true, false);
  }

  generateThumbnailVtt(videoId: string): Promise<any> {
    return this.axios.post(`/admin/videos/generate-thumbnail-vtt/${videoId}`);
  }

  getOne(
    params: GetOneParams & { type?: "master" | "preview" }
  ): Promise<GetOneResult<Video>> {
    if (!this.canRead) {
      throw new Error("Not Implemented");
    }

    console.log("getOne", params);

    return this.axios
      .get<DetailedVideoSearchResult>(
        "/admin" +
          this.buildUrl(params.id) +
          (params.type ? `?type=${params.type}` : "")
      )
      .then((response: AxiosResponse<DetailedVideoSearchResult>) => ({
        data: response.data
      }));
  }
}

export class UserMediaItemDataProvider extends AdminAutoCrudDataProvider<
  IUserMediaItem
> {
  constructor() {
    super({
      path: "media-items",
      queryField: "filename",
      queryFilter: ""
    });
  }

  reprocessMediaItem(mediaItemId: string): Promise<any> {
    return this.axios.post(`/user-media/reprocess/${mediaItemId}`);
  }
}

export class DailyUserActivityDataProvider extends BasicCrudDataProvider<
  DailyUserActivity
> {
  constructor() {
    super("/profiles/daily-user-activity", true, false, false, false);
  }

  async getList(
    params: GetListParams
  ): Promise<GetListResult<DailyUserActivity>> {
    return super.getList(params).then(response => {
      return {
        data: response.data.map((res: DailyUserActivity) => {
          return { ...res, id: `${res.date}_${res.userId}` };
        }),
        total: response.total
      };
    });
  }
}

export class GiftcardApplicationDataProvider extends BasicCrudDataProvider<
  GiftcardApplication
> {
  constructor() {
    super("/giftcards/applications", true, true, true, false);
  }

  create(
    params: CreateParams<GiftcardApplication>
  ): Promise<CreateResult<GiftcardApplication>> {
    if (params.data.countryCode) {
      const matchedCountryCode = COUNTRY_CODES.find(
        c => c.code === params.data.countryCode
      );
      if (matchedCountryCode) {
        params.data.countryCode = matchedCountryCode.phone;
      }
    }

    return super.create(params);
  }

  async approveMany(
    giftcardApplicationIds: string[],
    productId: string,
    giftcardRedeemableBy: string,
    giftcardExpiryDate: Date | undefined
  ) {
    const results: Array<GiftcardApplication> = [];
    const idChunks = chunkArray(giftcardApplicationIds, 5);
    // Process in chunks of 5 (to avoid timeout issues as SMS's are sent)
    for (let i = 0; i < idChunks.length; i++) {
      const idChunk = idChunks[i];
      const resultsPart = await this.axios.post<GiftcardApplication[]>(
        "/giftcards/applications/approve",
        {
          giftcardApplicationIds: idChunk,
          productId,
          giftcardRedeemableBy,
          giftcardExpiryDate
        },
        { timeout: 60000 }
      );

      results.push(...resultsPart.data);
    }

    return results;
  }

  async denyMany(giftcardApplicationIds: string[], reason?: string) {
    return (
      await this.axios.post<GiftcardApplication[]>(
        "/giftcards/applications/deny",
        {
          giftcardApplicationIds,
          reason
        },
        { timeout: 60000 }
      )
    ).data;
  }
}

export class NotificationPreferenceDataProvider extends BasicCrudDataProvider<
  NotificationPreference
> {
  constructor() {
    super("/admin-notification-prefs", true, true, true, true);
  }

  async getList(
    params: GetListParams
  ): Promise<GetListResult<NotificationPreference>> {
    return super.getList(params).then(response => {
      return {
        data: response.data.map((res: NotificationPreference) => {
          return { ...res, id: `${res.userId}_${res.template}` };
        }),
        total: response.total
      };
    });
  }

  create({
    data: { userId, template, allowedEmail, allowedPush, allowedSms }
  }: CreateParams<NotificationPreference>): Promise<
    CreateResult<NotificationPreference>
  > {
    return this.axios
      .put(this.buildUrl(), {
        userId,
        template,
        allowed: {
          email: allowedEmail,
          push: allowedPush,
          sms: allowedSms
        }
      })
      .then(({ data }) => ({
        data: { id: `${data.userId}_${data.template}`, ...data }
      }));
  }

  delete(params: DeleteParams): Promise<any> {
    // Fake ID in format of `${userId}_${template}`
    const id = params.id.toString();
    const [userId, template] = id.split("_", 2);
    return this.axios.delete(
      this.buildUrl() +
        `?userId=${encodeURIComponent(userId)}&template=${encodeURIComponent(
          template
        )}`
    );
  }
}

export type ScheduledNotificationPreviewResponse = {
  filters: UserProfileFilters;
  total: number;
  optedOut: number;
};

export type StatsResponse = Array<{
  status: NotificationLogStatus;
  total: number;
}>;

export type SMSLimitsResponse = {
  smsBlocked: boolean;
};

export class ScheduledNotificationDataProvider extends AdminAutoCrudDataProvider<
  ScheduledNotification
> {
  constructor() {
    super({
      path: "scheduled-notifications",
      queryField: "templateData"
    });
  }

  preview(
    type: NotificationLogType,
    filters: Record<string, string>
  ): Promise<ScheduledNotificationPreviewResponse> {
    const cleanFilters = AdminAutoCrudDataProvider.cleanFilters(
      this.opts,
      filters
    );

    return this.axios
      .post<ScheduledNotificationPreviewResponse>(this.buildPath("preview"), {
        type,
        filters: Object.keys(cleanFilters).map(key => ({
          filter: key,
          value: cleanFilters[key]
        }))
      })
      .then(r => r.data);
  }

  getStats(id: string) {
    return this.axios
      .get<StatsResponse>(this.buildPath(`stats/${id}`))
      .then(r => r.data);
  }
}

export class DailySMSUsageDataProvider extends BasicCrudDataProvider<
  DailySMSLimits
> {
  constructor() {
    super("/admin-daily-sms-limits", true, true, true, true);
  }

  getSMSBlockedStatus(): Promise<SMSLimitsResponse> {
    return this.axios
      .get<SMSLimitsResponse>("/admin-daily-sms-limits")
      .then(r => r.data);
  }

  updateSMSBlockedStatus({
    data: { smsBlocked }
  }: CreateParams<DailySMSLimits>): Promise<CreateResult<DailySMSLimits>> {
    return this.axios.post("/admin-daily-sms-limits", {
      blocked: smsBlocked
    });
  }
}
