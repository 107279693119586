import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  text: { fontSize: "0.7rem", textTransform: "none" },
  dataGrid: {
    minHeight: "132px",

    "& .MuiDataGrid-dataContainer, & .MuiDataGrid-viewport": {
      minWidth: "auto!important"
    },

    "& .MuiDataGrid-viewport": {
      width: "fit-content",
      maxWidth: "none!important",
      minWidth: "100%!important"
    },

    "& .MuiDataGrid-viewport, & .MuiDataGrid-renderingZone, & .MuiDataGrid-row": {
      maxHeight: "fit-content!important"
    },

    "& .MuiDataGrid-renderingZone": {
      transform: "none!important",
      marginRight: "-16px"
    },

    "& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-cell": {
      textOverflow: "unset",
      whiteSpace: "normal",
      lineHeight: "1.2!important",
      maxHeight: "fit-content!important",
      minHeight: "auto!important",
      height: "auto",
      display: "flex",
      alignItems: "center",
      alignSelf: "stretch",

      "& > div": {
        maxHeight: "inherit",
        width: "100%",
        whiteSpace: "initial",
        lineHeight: "1"
      }
    },

    "& .MuiDataGrid-columnHeader > div": {
      height: "100%"
    },

    "& .MuiDataGrid-columnHeaderWrapper": {
      maxHeight: "none!important",
      flex: "1 0 auto"
    },

    "& .MuiDataGrid-row .MuiDataGrid-columnsContainer": {
      maxHeight: "none!important"
    },

    "& .MuiDataGrid-cell": {
      overflowWrap: "anywhere",
      padding: "0",

      "&--textRight div": {
        textAlign: "right",
        justifyContent: "flex-end"
      },

      "&:last-of-type > div": {
        paddingRight: theme.spacing(3)
      },

      "& > div": {
        padding: "0.75em",
        display: "flex",
        alignSelf: "stretch",
        alignItems: "center"
      }
    }
  }
}));
