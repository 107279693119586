/* eslint-disable react/display-name */
import React, { useCallback, useState } from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Typography
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { Tag } from "../../../entities/Catalog";
import { useStyles } from "./styles";

export const renderEditSoftLinks = (tags: Record<string, Tag>) => (
  params: GridRenderEditCellParams
) => {
  const classes = useStyles();
  const [filterText, setFilterText] = useState<string>();
  const [open, setOpen] = useState(false);
  const [softLinks, setSoftLinks] = useState<string[]>(
    ((params.value as unknown) as string[]) || []
  );
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const onSave = () => {
    params.api.setEditCellValue({
      id: params.id,
      field: "softLinks",
      value: softLinks
    });
    onClose();
  };

  const onClickTag = (tag: string) => {
    if (softLinks.includes(tag)) {
      setSoftLinks(softLinks.filter(t => t !== tag));
    } else {
      setSoftLinks([...softLinks, tag]);
    }
  };

  const TagView = useCallback(
    ({ tag }: { tag: string }) => {
      const isAdded = softLinks.includes(tag);
      return (
        <Chip
          style={{ width: "fit-content" }}
          label={tag}
          onClick={() => onClickTag(tag)}
          onDelete={() => onClickTag(tag)}
          deleteIcon={isAdded ? undefined : <AddCircleOutline />}
        />
      );
    },
    [softLinks]
  );

  return (
    <>
      <Button onClick={onOpen} variant="text">
        <Typography variant="body1" className={classes.text}>
          {softLinks && softLinks.length > 0 ? softLinks.join(", ") : "Add"}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Update Soft links</DialogTitle>
        <DialogContent>
          <Box display="flex" flex={1} flexDirection="column">
            <Box display="flex" flex={1} flexDirection="row">
              {softLinks.map(tag => (
                <TagView key={tag} tag={tag} />
              ))}
            </Box>

            <Input
              type="text"
              value={filterText}
              placeholder="Filter"
              onChange={event => setFilterText(event.target.value || undefined)}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignContent="flex-start"
              alignItems="flex-start"
            >
              {Object.values(tags)
                .filter(
                  t =>
                    !softLinks.includes(t.tag) &&
                    (!filterText ||
                      t.tag.toLowerCase().includes(filterText.toLowerCase()))
                )
                .map(tag => (
                  <TagView key={tag.id} tag={tag.tag} />
                ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={onSave}>
            Set Soft links
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
