import React, { useCallback, useEffect, useMemo, useRef } from "react";
import "videojs-http-source-selector";
import "videojs-contrib-quality-levels";
import "./OtherPlayer.scss";
import "videojs-vtt-thumbnails";
import throttle from "lodash/throttle";
import { PlayerProps } from "./types";

// @ts-ignore
import videojs from "video.js";
// eslint-disable-next-line @typescript-eslint/no-var-requires
require("@silvermine/videojs-chromecast")(videojs);
// eslint-disable-next-line @typescript-eslint/no-var-requires
require("@silvermine/videojs-airplay")(videojs);

const muxRgx = /^https:\/\/stream\.mux\.com\/([a-zA-Z0-9_-]{3,}).m3u8/i;

export const OtherPlayer = ({
  video: videoProp,
  onStart: onStartProp,
  onProgress: onProgressProp
}: PlayerProps) => {
  const [started, setStarted] = React.useState(false);
  const videoRef = useRef(null);
  const innerPlayerRef = useRef<videojs.Player>();
  const { id, sources, thumbnail, progress } = videoProp;
  const { hlsSource, vttSource } = useMemo(() => {
    const hlsSource = sources?.find(
      source => source.type.toLowerCase() === "application/x-mpegurl"
    );

    if (hlsSource?.src) {
      let vttSource: string | undefined = undefined;
      if (muxRgx.test(hlsSource.src)) {
        const hlsSourceMuxMatch = muxRgx.exec(hlsSource.src);
        console.log("mux match", hlsSourceMuxMatch);
        if (hlsSourceMuxMatch && hlsSourceMuxMatch.length > 1) {
          vttSource = `https://image.mux.com/${hlsSourceMuxMatch[1]}/storyboard.vtt`;
        }
      }

      return { hlsSource, vttSource };
    }

    return { hlsSource: undefined, vttSource: undefined };
  }, [sources]);

  const onLoaded = useCallback(() => {
    if (!innerPlayerRef.current || !progress) return;

    innerPlayerRef.current.currentTime(parseFloat(String(progress)));
  }, [innerPlayerRef, progress]);

  const onStart = useCallback(() => {
    if (!id || !onStartProp || started) {
      return;
    }

    setStarted(true);
    onStartProp();
  }, [id, onStartProp, started]);

  const onProgress = useCallback(
    throttle(
      (ref: any) => {
        if (!onProgressProp || !ref?.target?.player) return;

        const currentTime = ref?.target?.player?.currentTime?.();
        if (currentTime) {
          onProgressProp(Math.floor(currentTime));
          return;
        }
      },
      2000,
      { leading: true, trailing: true }
    ),
    [started, onProgressProp]
  );

  useEffect(() => {
    if (videoRef.current && hlsSource) {
      const video = videoRef.current;

      // @ts-ignore
      innerPlayerRef.current = videojs(video, {
        autoplay: false,
        controls: true,
        fluid: true,
        preload: "auto",
        userActions: {
          hotkeys: true
        },
        controlBar: {
          pictureInPictureToggle: true,
          playbackRateMenuButton: false,
          subtitlesButton: true
        },
        plugins: {
          httpSourceSelector: {
            default: "auto"
          }
        },
        poster: thumbnail,
        sources: [{ src: hlsSource.src, type: hlsSource.type }],
        techOrder: ["chromecast", "html5"]
      });

      // @ts-ignore
      innerPlayerRef.current.chromecast();
      // @ts-ignore
      innerPlayerRef.current.airPlay();

      if (vttSource) {
        // @ts-ignore
        innerPlayerRef.current?.vttThumbnails({
          src: vttSource
        });
      }

      innerPlayerRef.current.on("play", () => {
        innerPlayerRef.current?.bigPlayButton.hide();
      });

      innerPlayerRef.current.on("pause", () => {
        innerPlayerRef.current?.bigPlayButton.show();
      });

      innerPlayerRef.current?.on("timeupdate", onProgress);
      innerPlayerRef.current?.on("loadstart", onLoaded);
      innerPlayerRef.current?.on("play", onStart);
    }

    return () => {
      if (innerPlayerRef.current) {
        // @ts-ignore
        innerPlayerRef.current.dispose();
      }
    };
  }, [videoRef, hlsSource, thumbnail, vttSource]);

  return (
    <div className="player-container">
      <video ref={videoRef} className="video video-js vjs-16-9" />
    </div>
  );
};
