import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CardActionArea,
  Card,
  CardContent,
  Input
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { InboxNotification } from "../../entities/Notification";

const YOUTUBE_API_KEY = "AIzaSyA-dHfnE9HFQ3q2G_MAR_DfDajhk6rzVGY";
const YOUTUBE_URL_REGEX = /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;

type RawYoutubeData = {
  id: string;
  snippet: {
    title: string;
    description: string;
    thumbnails: { high: { url: string } };
  };
};

const InboxNotificationCreateYoutubeButton = ({
  setDefaultsInForm
}: {
  setDefaultsInForm: (notification: Partial<InboxNotification>) => void;
}) => {
  const [youtubeData, setYoutubeData] = useState<RawYoutubeData>();
  const [showDialog, setShowDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string>();

  useEffect(() => {
    setYoutubeData(undefined);

    if (!videoUrl) return;

    const match = videoUrl.match(YOUTUBE_URL_REGEX);
    if (!match) return;

    const [, videoId] = match;
    axios
      .create()
      .get<{ items: RawYoutubeData[] }>(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&fields=items(id,snippet(title,description,thumbnails))&part=snippet`
      )
      .then(r => setYoutubeData(r.data.items[0]));
  }, [videoUrl]);

  const handleUseVideo = () => {
    if (!youtubeData || !youtubeData.snippet) return;

    setDefaultsInForm({
      title: youtubeData.snippet.title,
      message: youtubeData.snippet.description,
      linkUrl: `https://youtu.be/${youtubeData.id}`
    });
  };

  return (
    <>
      <Button onClick={() => setShowDialog(true)}>
        Youtube Inbox Notification
      </Button>
      <Dialog
        open={showDialog}
        disableBackdropClick
        aria-label="Enter Youtube URL"
      >
        <DialogTitle>Enter Youtube URL</DialogTitle>

        <DialogContent>
          <Input onChange={e => setVideoUrl(e.target.value)} fullWidth />

          {youtubeData && (
            <Card>
              <CardActionArea>
                <img src={youtubeData.snippet?.thumbnails?.high?.url} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {youtubeData.snippet?.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {youtubeData.snippet?.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          {youtubeData && <Button onClick={handleUseVideo}>Use Video</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InboxNotificationCreateYoutubeButton;
