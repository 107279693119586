/* eslint-disable react/display-name */
import React, { useCallback, useState } from "react";
import {
  GridRenderCellParams,
  GridRenderEditCellParams
} from "@mui/x-data-grid";
import { PlaylistType } from "@booyaltd/core";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { Playlist } from "../../../entities/Catalog";
import { useStyles } from "./styles";

export const renderEditPlaylists = (playlists: Record<string, Playlist>) => (
  params: GridRenderEditCellParams
) => {
  const classes = useStyles();
  const [filterText, setFilterText] = useState<string>();
  const [open, setOpen] = useState(false);
  const [playlistIds, setPlaylistIds] = useState<string[]>(
    ((params.value as unknown) as string[]) || []
  );
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const onSave = () => {
    params.api.setEditCellValue({
      id: params.id,
      field: "playlistIds",
      value: playlistIds
    });
    onClose();
  };

  const onClickTag = (playlistId: string) => {
    if (playlistIds.includes(playlistId)) {
      setPlaylistIds(playlistIds.filter(p => p !== playlistId));
    } else {
      setPlaylistIds([...playlistIds, playlistId]);
    }
  };

  const PlaylistView = useCallback(
    ({ playlistId, name }: { playlistId: string; name?: string }) => {
      const isAdded = playlistIds.includes(playlistId);
      return (
        <Chip
          style={{ width: "fit-content" }}
          label={name}
          onClick={() => onClickTag(playlistId)}
          onDelete={() => onClickTag(playlistId)}
          deleteIcon={isAdded ? undefined : <AddCircleOutline />}
        />
      );
    },
    [playlistIds]
  );

  return (
    <>
      <Button onClick={onOpen} variant="text">
        <Typography variant="body1" className={classes.text}>
          {playlistIds && playlistIds.length > 0
            ? playlistIds
                .map(id => playlists[id]?.name)
                .filter(name => !!name)
                .join(", ")
            : "Add"}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Update Playlists</DialogTitle>
        <DialogContent>
          <Box display="flex" flex={1} flexDirection="column">
            <Box display="flex" flex={1} flexDirection="row">
              {playlistIds && playlistIds.length > 0
                ? playlistIds.map(id => (
                    <PlaylistView
                      key={id}
                      playlistId={id}
                      name={playlists[id]?.name}
                    />
                  ))
                : null}
            </Box>

            <Input
              type="text"
              value={filterText}
              placeholder="Filter"
              onChange={event => setFilterText(event.target.value || undefined)}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Playlists</TableCell>
                  <TableCell>Series</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      {Object.values(playlists)
                        .filter(
                          p =>
                            p.type.toString() ===
                              PlaylistType.Standard.toString() &&
                            !playlistIds.includes(p.id) &&
                            (!filterText ||
                              p.name
                                .toLowerCase()
                                .includes(filterText.toLowerCase()))
                        )
                        .map(p => (
                          <PlaylistView
                            key={p.id}
                            playlistId={p.id}
                            name={p.name}
                          />
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      {Object.values(playlists)
                        .filter(
                          p =>
                            p.type.toString() ===
                              PlaylistType.Series.toString() &&
                            !playlistIds.includes(p.id) &&
                            (!filterText ||
                              p.name
                                .toLowerCase()
                                .includes(filterText.toLowerCase()))
                        )
                        .map(p => (
                          <PlaylistView
                            key={p.id}
                            playlistId={p.id}
                            name={p.name}
                          />
                        ))}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={onSave}>
            Set Playlists
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const renderPlaylistView = (playlists: Record<string, Playlist>) => (
  params: GridRenderCellParams
) => {
  const ids: string[] = params.row["playlistIds"] || [];

  if (!ids || ids.length === 0) return null;

  return (
    <p style={{ wordWrap: "normal" }}>
      {ids
        .map(id => playlists[id]?.name)
        .filter(name => !!name)
        .join(", ")}
    </p>
  );
};
