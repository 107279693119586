import { useField } from "react-final-form";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone/dist";
import ImageDataProvider from "../../providers/data/ImageDataProvider";
import {
  IUserMediaContentType,
  IUserMediaItemType,
  IUserMediaStatus
} from "@booyaltd/core";
import { useLoading, useNotify, useUpdateLoading } from "react-admin";

const UserMediaUploaderField = ({
  type,
  defaultKey,
  defaultUrl,
  source,
  urlField,
  label
}: {
  type: IUserMediaItemType;
  defaultKey?: string;
  defaultUrl?: string;
  source: string;
  urlField: string;
  label: string;
  record?: { [key: string]: string };
}) => {
  const notify = useNotify();
  const loading = useLoading();
  const { startLoading, stopLoading } = useUpdateLoading();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    input: { onChange: onChangeKey }
  } = useField(source, { defaultValue: defaultKey });
  const {
    input: { onChange: onChangeUrl, value: url }
  } = useField(urlField, { defaultValue: defaultUrl });

  const onDrop = (files: File[]) => {
    if (!files || files.length !== 1) {
      return;
    }

    const file = files[0];

    const imageDataProvider = new ImageDataProvider();

    startLoading();
    imageDataProvider
      .createUserMediaItem({
        type: type,
        contentType: files[0].type as IUserMediaContentType,
        contentLength: files[0].size,
        filename: files[0].name
      })
      .then(({ uploadUrl, item: mediaItem }) => {
        return fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type
          }
        }).then(async r => {
          await new Promise(resolve => setTimeout(resolve, 1500));

          for (let i = 1; i <= 4; i++) {
            await new Promise(resolve => setTimeout(resolve, 1500 * i));
            const media = await imageDataProvider
              .getUserMediaItem(mediaItem.id)
              .catch(() => undefined);
            if (
              !media ||
              !media.item ||
              media.item.status !== IUserMediaStatus.Ready
            ) {
              continue;
            }

            if (media?.item?.id) {
              onChangeKey(media.item.id);
            }

            if (media?.sizeUrls?.thumb) {
              onChangeUrl(media.sizeUrls.thumb);
            }

            return r;
          }
        });
      })
      .then(() => {
        setEditing(false);
        notify("Uploaded Successfully", "success");
        console.log("Uploaded file");
      })
      .catch(e => {
        notify("Upload error", "error");
        console.error("Error uploading file", e);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onChangeClicked = () => {
    setEditing(true);
  };

  const onRemoveClicked = () => {
    onChangeKey(null);
    onChangeUrl(null);
  };

  const onCancelChangeClicked = () => {
    setEditing(false);
  };

  if (!editing && url) {
    return (
      <Card variant="outlined">
        <CardHeader title={label} titleTypographyProps={{ variant: "h6" }} />
        <CardContent
          style={{
            alignContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <img
            alt={label}
            src={url || label}
            style={{
              width: "auto",
              height: "auto",
              maxHeight: "300px",
              maxWidth: "100%"
            }}
          />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={onChangeClicked}>
            Change
          </Button>
          <Button size="small" onClick={onRemoveClicked}>
            Remove
          </Button>
        </CardActions>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardHeader title={label} />
      <CardContent>
        {loading && <CircularProgress />}
        <DropzoneArea
          onChange={onDrop}
          filesLimit={1}
          acceptedFiles={[
            IUserMediaContentType.PNG,
            IUserMediaContentType.JPEG
          ]}
        />
      </CardContent>
      {editing && (
        <CardActions>
          <Button size="small" onClick={onCancelChangeClicked}>
            Cancel
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default UserMediaUploaderField;
