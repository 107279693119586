import DataProviderBase from "./IDataProvider";
import { GetListParams, GetListResult } from "react-admin";
import AdminAutoCrudDataProvider from "./AdminAutoCrudDataProvider";
import { UserProfile } from "@booyaltd/core";

export default class ProfileDataProvider
  extends AdminAutoCrudDataProvider<UserProfile>
  implements DataProviderBase<UserProfile> {
  constructor() {
    super({
      path: "profiles",
      queryField: "email"
    });
  }

  /**
   * This is overridden to allow for customised paging for bulk exports
   *
   * The idea here was, when pagination params aren't specified (e.g. when exporting),
   * we pull the user data in pages of 4000 records, aggregate this list and then return the data
   *
   * @param params
   */
  async getList(params: GetListParams): Promise<GetListResult<UserProfile>> {
    if (params.pagination && params.pagination.perPage < 1000) {
      return super.getList(params);
    }

    const perPage = 500;
    let page = 1;
    let total = 0;
    const data = [];
    do {
      const { data: tmpData, total: tmpTotal } = await super.getList({
        ...params,
        pagination: { page: page, perPage }
      });

      if (tmpData && tmpTotal) {
        total = tmpTotal;
        page += 1;
        data.push(...tmpData);
      }
    } while (data.length < total);

    return { data, total };
  }

  resetPassword(email: string): Promise<any> {
    return this.axios
      .post("/auth/password-reset/generate-code", { email: email })
      .then(response => ({ data: response.data }));
  }

  cancelSubscription(
    userId: string,
    immediately = false,
    refundPaymentIds: string[] = []
  ): Promise<any> {
    return this.axios
      .post("/stripe/adminCancelSubscription", {
        userId,
        immediately,
        refundPaymentIds
      })
      .then(response => ({ data: response.data }));
  }
}
