import React, { useCallback, useMemo, useState } from "react";
import { Tag } from "../../../../../entities/Catalog";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { AddCircleOutline, Edit } from "@material-ui/icons";
import { useGetList, useUpdate } from "react-admin";
import { Video } from "@booyaltd/core";
import { isEqual } from "lodash";

type VideoSearchTermsEditorModalButtonProps = {
  video: Video;
};

const VideoSearchTermsEditorModalButton = ({
  video
}: VideoSearchTermsEditorModalButtonProps) => {
  const [filterText, setFilterText] = useState<string>();
  const [open, setOpen] = useState(false);
  const [searchTerms, setSearchTerms] = useState<string[]>(
    video.searchTerms || []
  );

  const { data: allSearchTerms } = useGetList<Tag>(
    "tag",
    { page: 1, perPage: 1000 },
    { field: "tag", order: "asc" },
    {
      type: "search-term"
    },
    {
      enabled: open
    }
  );

  const [flushSearchTerms, { loading: updating }] = useUpdate(
    "video",
    String(video?.id),
    { ...video, searchTerms },
    video,
    {
      mutationMode: "optimistic"
    }
  );

  const onOpen = () => setOpen(true);
  const onDone = () => {
    setOpen(false);

    if (!isEqual(video.searchTerms, searchTerms)) {
      flushSearchTerms();
    }
  };

  const tagGroups = useMemo(
    () =>
      Object.keys(allSearchTerms).reduce<Record<string, Tag[]>>(
        (carry, tagId: string): Record<string, Tag[]> => {
          const tag = allSearchTerms[tagId];
          const group =
            tag.group && tag.group.length > 0
              ? tag.group.toLowerCase()
              : "other";

          if (!carry[group]) {
            carry[group] = [];
          }

          if (
            !filterText ||
            tag.tag.toLowerCase().includes(filterText.toLowerCase())
          ) {
            carry[group].push(tag);
          }

          return carry;
        },
        {}
      ),
    [allSearchTerms, filterText]
  );

  const onClickTag = (tag: string) => {
    if (searchTerms.includes(tag)) {
      setSearchTerms(searchTerms.filter(t => t !== tag));
    } else {
      setSearchTerms([...searchTerms, tag]);
    }
  };

  const TagView = useCallback(
    ({ tag }: { tag: string }) => {
      const isAdded = searchTerms.includes(tag);
      return (
        <Chip
          style={{ width: "fit-content" }}
          label={tag}
          onClick={() => onClickTag(tag)}
          onDelete={() => onClickTag(tag)}
          deleteIcon={isAdded ? undefined : <AddCircleOutline />}
        />
      );
    },
    [searchTerms]
  );

  return (
    <>
      <Button
        onClick={onOpen}
        size="small"
        variant="outlined"
        color="secondary"
        disabled={updating}
        startIcon={searchTerms?.length > 0 ? <Edit /> : <AddCircleOutline />}
      >
        {searchTerms?.length > 0 ? "Edit" : "Add"}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Update Search Terms</DialogTitle>
        <DialogContent>
          <Box display="flex" flex={1} flexDirection="column">
            <Box display="flex" flex={1} flexDirection="row">
              {searchTerms.map(tag => (
                <TagView key={tag} tag={tag} />
              ))}
            </Box>

            <Input
              type="text"
              value={filterText}
              placeholder="Filter"
              onChange={event => setFilterText(event.target.value || undefined)}
            />
            <Table>
              <TableHead>
                {Object.keys(tagGroups).map(group => (
                  <TableCell key={group}>{group}</TableCell>
                ))}
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.keys(tagGroups).map(group => (
                    <TableCell
                      key={group}
                      valign="top"
                      style={{ verticalAlign: "top" }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignContent="flex-start"
                        alignItems="flex-start"
                      >
                        {tagGroups[group]
                          .filter(t => !searchTerms.includes(t.tag))
                          .map(tag => (
                            <TagView key={tag.id} tag={tag.tag} />
                          ))}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onDone}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VideoSearchTermsEditorModalButton;
