import { Video } from "../../../entities/Catalog";
import { Show, TabbedShowLayout, Tab } from "react-admin";
import React from "react";
import VideoShowMediaTab from "./tabs/media/VideoShowMediaTab";
import VideoShowAnalyticsTab from "./tabs/analytics/VideoShowAnalyticsTab";
import VideoShowSearchTab from "./tabs/search/VideoShowSearchTab";
import VideoShowInfoTab from "./tabs/info/VideoShowInfoTab";

const VideoTitle = ({ record }: { record?: Video }) => {
  return <span>Video: {record ? `${record.name}` : ""}</span>;
};

export const VideoShow = (props: {}) => (
  <Show {...props} title={<VideoTitle />} actions={<></>}>
    <TabbedShowLayout>
      <Tab label="info">
        <VideoShowInfoTab />
      </Tab>
      <Tab label="media" path="media">
        <VideoShowMediaTab />
      </Tab>
      <Tab label="search">
        <VideoShowSearchTab />
      </Tab>
      <Tab label="analytics" path="analytics">
        <VideoShowAnalyticsTab />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
