import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  CreateButton
} from "react-admin";
import { Tag } from "../entities/Catalog";
import icon from "@material-ui/icons/LocalOffer";
import { Route } from "react-router";
import { CardActions, Drawer } from "@material-ui/core";
import { TextContainsFilter } from "../components/filters/TextFilters";

const SoftLinkActions = ({ basePath }: { basePath?: string }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);
const SoftLinkFilter = (props: {}) => (
  <Filter {...props}>
    <TextContainsFilter label="Soft link" source="tag" alwaysOn />
  </Filter>
);

export const SoftLinkList = (props: {}) => {
  const handleClose = () => {
    window.history.back();
  };

  return (
    <Fragment>
      <List
        actions={<SoftLinkActions />}
        filters={<SoftLinkFilter />}
        filterDefaultValues={{ type: "soft-link" }}
        sort={{ field: "tag", order: "ASC" }}
        perPage={25}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <TextField source="tag" label="Soft link" sortable={true} />
          <TextField source="group" label="Group" sortable={true} />
          <EditButton />
        </Datagrid>
      </List>
      <Route
        path="/soft-link/create"
        render={() => (
          <Drawer open onClose={handleClose} anchor="right"></Drawer>
        )}
      />
      <Route
        path="/soft-link/:id"
        render={({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open onClose={handleClose} anchor="right">
              {isMatch ? (
                <SoftLinkEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <SoftLinkCreate {...props} />
              )}
            </Drawer>
          );
        }}
      />
    </Fragment>
  );
};

const SoftLinkTitle = ({ record }: { record?: Tag }) => {
  return <span>Soft link {record ? `${record.tag}` : ""}</span>;
};

export const SoftLinkEdit = (props: { id: string; onCancel: () => void }) => (
  <Edit {...props} title={<SoftLinkTitle />}>
    <SimpleForm>
      <TextInput label="Soft link" source="tag" />
      <TextInput label="Group" source="group" />
    </SimpleForm>
  </Edit>
);

export const SoftLinkCreate = (props: {}) => (
  <Create {...props} title={<SoftLinkTitle />}>
    <SimpleForm
      initialValues={{ type: "soft-link", active: true, additional: false }}
    >
      <TextInput label="Soft link" source="tag" autoFocus />
      <TextInput label="Group" source="group" />
    </SimpleForm>
  </Create>
);

export const SoftLinkIcon = icon;
