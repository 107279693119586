import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/styles";
import { useLogin, useNotify } from "react-admin";
import { COGNITO_LOGIN } from "../../env";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

export default function LoginPage({ theme }: { theme?: any }) {
  const classes = useStyles();
  const [redirected, setRedirected] = useState<boolean>(false);
  const [authResponse, setAuthResponse] = useState<{
    idToken: string;
    accessToken: string;
  }>();
  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    const urlParts = window.location.href.split("#", 2);
    if (urlParts.length === 2 && urlParts[1].includes("id_token")) {
      const urlParams = new URLSearchParams(urlParts[1]);
      setAuthResponse({
        idToken: urlParams.get("id_token") || "",
        accessToken: urlParams.get("access_token") || ""
      });
    } else {
      if (!redirected) {
        setRedirected(true);
        setTimeout(() => {
          const redirectUrl = new URL(COGNITO_LOGIN);
          redirectUrl.searchParams.set(
            "redirect_uri",
            window.location.protocol + "//" + window.location.host + "/login"
          );
          window.location.href = redirectUrl.toString();
        }, 2000);
      }
    }
  }, [redirected]);

  useEffect(() => {
    console.log("Auth Response", authResponse);

    if (!authResponse || !authResponse.idToken || !authResponse.accessToken)
      return;

    login(authResponse)
      .then(() => {
        return new Promise<void>(resolve => {
          setTimeout(() => {
            const cleanUrl =
              window.location.protocol + "//" + window.location.host;
            window.history.pushState({}, document.title, cleanUrl);
            resolve(undefined);
          }, 10);
        });
      })
      .catch(() => notify("Error logging in"));
  }, [authResponse, login, notify]);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              component="h1"
              variant="h3"
              align="center"
              gutterBottom={true}
            >
              Farmflix Admin
            </Typography>

            <Typography component="h2" variant="h5" align="center">
              {authResponse
                ? "Verifing Access Token"
                : "Redirecting to Auth Service"}
            </Typography>
          </Paper>
        </main>
      </React.Fragment>
    </ThemeProvider>
  );
}
