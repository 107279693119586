import React, { Fragment } from "react";
import { Alert } from "@material-ui/lab";
import GiftcardApplicationApproveButton from "./GiftcardApplicationApproveButton";
import GiftcardApplicationDenyButton from "./GiftcardApplicationDenyButton";

export const GiftcardApplicationBulkActions = (props: any) => {
  if (props?.filterValues?.status !== "pending") {
    return (
      <Alert color="error" variant="outlined" style={{ marginBottom: "5px" }}>
        Filter by Status = Pending to approve or deny applications
      </Alert>
    );
  }

  return (
    <Fragment>
      <GiftcardApplicationApproveButton {...props} />
      <GiftcardApplicationDenyButton {...props} />
    </Fragment>
  );
};
