import React from "react";
import { TransferStats } from "evaporate";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import { CloudUploadOutlined } from "@material-ui/icons";
import { IUserMediaItem } from "@booyaltd/core";
import { UploadState } from "../../mobx/MultipartUploadStore";

const secsToMinsAndSecsString = (secs: number) => {
  const mins = Math.floor(secs / 60);
  const secsRemainder = secs % 60;
  return `${mins}:${secsRemainder < 10 ? "0" : ""}${secsRemainder}`;
};

type UploadProgressProps = {
  uploadState: Omit<UploadState, "idle">;
  stats?: TransferStats;
  mediaItem: IUserMediaItem | null;
};

const uploadStateToString = (uploadState: Omit<UploadState, "idle">) => {
  switch (uploadState) {
    case "initializing":
      return "Starting Upload";
    case "uploading":
      return "Uploading";
    case "paused":
      return "Paused";
    case "cancelled":
      return "Cancelled";
    case "error":
      return "Error Occurred During Upload";
    case "done":
      return "Upload Complete";
    default:
      return uploadState;
  }
};

const UploadProgress = ({
  uploadState,
  mediaItem,
  stats
}: UploadProgressProps) => {
  const progressPercentage = stats
    ? Math.round((stats.totalUploaded / stats.fileSize) * 100)
    : 0;
  const secsRemaining = stats
    ? secsToMinsAndSecsString(stats.secondsLeft)
    : "n/a";

  const status = uploadStateToString(uploadState);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      p={2}
      border={1}
      borderColor="grey.300"
      bgcolor="grey.100"
      borderRadius={15}
    >
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <CloudUploadOutlined fontSize="large" />
        <Box display="flex" flexDirection="column" flex={1} ml={2}>
          <Typography variant="h6">
            {mediaItem?.filename || " . . . "}
          </Typography>
          <Typography variant="body2">{status}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" value={progressPercentage} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          {stats ? (
            <Typography variant="body2">{`${progressPercentage}%`}</Typography>
          ) : null}
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body2">{`${secsRemaining} remaining`}</Typography>
      </Box>
    </Box>
  );
};

export default UploadProgress;
