export type Giftcard = {
  id: string;
  giftcardCode: string;
  productId: string;
  status:
    | "Generated"
    | "Sent_To_Printer"
    | "Cancelled"
    | "Sold_Wholesale"
    | "Sold_Shopify"
    | "Redeemed";
  redeemedUserId?: number;
  redeemedOn?: Date;
  notes?: string;
  created: Date;
  updated: Date;
};

export enum GiftcardApplicationStatus {
  Pending = "pending",
  Approved = "approved",
  Denied = "denied"
}

export default interface GiftcardApplication {
  id: string;
  generatedGiftcardId?: string;
  status: GiftcardApplicationStatus;
  denyReason?: string;
  errorReason?: string;
  name: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  evidenceMediaId: string;
  evidenceMediaThumbUrl: string;
  evidenceMediaFullUrl: string;
  created: Date;
  updated: Date;
}
