import React, { Fragment } from "react";
import { Datagrid, List, TextField } from "react-admin";
import Assignment from "@material-ui/icons/Assignment";
import StringArrayChipsField from "../components/fields/StringArrayChipsField";

export const NotificationTemplateList = (props: {}) => {
  return (
    <Fragment>
      <List
        sort={{ field: "id", order: "ASC" }}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <TextField source="id" sortable={true} />
          <StringArrayChipsField source="types" label="Types" />
          <StringArrayChipsField
            source="templateDataFields"
            label="Template Data Fields"
            sortable={false}
          />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export const NotificationTemplateIcon = Assignment;
