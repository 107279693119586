import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import React from "react";
import {
  Datagrid,
  List,
  ListProps,
  NumberField,
  Filter,
  ReferenceField,
  FunctionField
} from "react-admin";
import { Profile } from "../../entities/Profile";
import DailyUserActivityListExpandedRow from "./DailyUserActivityListExpandedRow";
import {
  NumberGreaterEqualFilter,
  NumberLessEqualFilter
} from "../../components/filters/TextFilters";

dayjs.extend(LocalizedFormat);

const DailyUserActivityListFilter = (props: any) => (
  <Filter {...props}>
    <NumberGreaterEqualFilter
      source="videoSessionValidViewCount"
      label="View Count >="
      alwaysOn={false}
    />
    <NumberLessEqualFilter
      source="videoSessionValidViewCount"
      label="View Count <="
      alwaysOn={false}
    />
    <NumberGreaterEqualFilter
      source="videoSessionDeviceCount"
      label="Device Count >="
      alwaysOn={false}
    />
    <NumberLessEqualFilter
      source="videoSessionDeviceCount"
      label="Device Count <="
      alwaysOn={false}
    />
    <NumberGreaterEqualFilter
      source="videoSessionWatchedMinutes"
      label="Watched Minutes >="
      alwaysOn={false}
    />
    <NumberLessEqualFilter
      source="videoSessionWatchedMinutes"
      label="Watched Minutes <="
      alwaysOn={false}
    />
  </Filter>
);

const DailyUserActivityList = (props: ListProps) => {
  return (
    <List
      perPage={50}
      filters={<DailyUserActivityListFilter />}
      bulkActionButtons={false}
      sort={{ field: "date", order: "DESC" }}
      {...props}
    >
      <Datagrid expand={<DailyUserActivityListExpandedRow />}>
        <ReferenceField label="User" source="userId" reference="member">
          <FunctionField<Profile>
            render={(profile?: Profile) =>
              profile ? `${profile.firstName} ${profile.lastName}` : "ERR"
            }
          />
        </ReferenceField>
        <NumberField
          source="videoSessions"
          label="Video Sessions Started"
          sortable={true}
        />
        <NumberField
          source="videoSessionValidViewCount"
          label="Views (> 30 secs)"
          sortable={true}
        />
        <NumberField
          source="videoSessionDeviceCount"
          label="Device Count"
          sortable={true}
        />
        <NumberField
          source="videoSessionWatchedMinutes"
          label="Watched Minutes"
          sortable={true}
        />
      </Datagrid>
    </List>
  );
};

export default DailyUserActivityList;
