import { Profile } from "../../entities/Profile";
import {
  getResourceLinkPath,
  useListFilterContext,
  useMutation
} from "react-admin";
import React, { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  ScheduledNotificationDataProvider,
  ScheduledNotificationPreviewResponse
} from "../../providers/data/BasicCrudDataProviders";
import { NotificationLogType } from "@booyaltd/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField as MUITextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import SmsIcon from "@material-ui/icons/Sms";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button as RAButton } from "ra-ui-materialui/lib/button";
import { Link } from "react-router-dom";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      color: "#B5D800",
      "&:hover": {
        cursor: "pointer"
      }
    },
    dialogTitle: {
      backgroundColor: "#B5D800"
    }
  })
);

const smsTextLength = 160;
const getNextSmsTextBoundary = (length: number) =>
  (Math.floor(length / smsTextLength) + 1) * smsTextLength;

const SendSmsToProfileListButton = ({ record }: { record?: Profile }) => {
  const classes = useStyles();
  const listFilterContext = useListFilterContext();
  const [create, { loading, data: createResult }] = useMutation({
    type: "create",
    resource: "scheduled-notification",
    payload: {}
  });

  const [open, setOpen] = useState(false);
  const [scheduledTime, setScheduledTime] = useState<Dayjs>();
  const [queueTime, setQueueTime] = useState<Dayjs>();
  const [nameText, setNameText] = useState<string>("");
  const [smsText, setSMSText] = useState<string>("");
  const [previewLoading, setPreviewLoading] = useState(false);
  const [preview, setPreview] = useState<
    ScheduledNotificationPreviewResponse
  >();

  const canSendSms =
    preview &&
    listFilterContext.filterValues &&
    scheduledTime &&
    queueTime &&
    smsText &&
    nameText &&
    !loading &&
    !createResult;

  useEffect(() => {
    if (!open) {
      setPreview(undefined);
      setPreviewLoading(false);
      return;
    }

    if (!listFilterContext.filterValues) {
      return;
    }

    const dataProvider = new ScheduledNotificationDataProvider();
    setPreviewLoading(true);
    dataProvider
      .preview(NotificationLogType.Sms, listFilterContext.filterValues)
      .then(setPreview)
      .finally(() => setPreviewLoading(false));
  }, [listFilterContext, open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onChangeSMSText = (event: React.ChangeEvent<string> | any) => {
    setSMSText(event.target.value);
  };

  const onChangeNameText = (event: React.ChangeEvent<string> | any) => {
    setNameText(event.target.value);
  };

  const onChangeScheduledTime = (val: MaterialUiPickersDate) => {
    if (val) {
      const time = dayjs(val);
      setScheduledTime(time);
      setQueueTime(dayjs(time).subtract(15, "minutes"));
    } else {
      setScheduledTime(undefined);
      setQueueTime(undefined);
    }
  };

  const onSend = () => {
    if (!canSendSms) {
      return;
    }

    create({
      type: "create",
      resource: "scheduled-notification",
      payload: {
        data: {
          name: nameText,
          type: NotificationLogType.Sms,
          userProfileFilter: listFilterContext.filterValues,
          template: "generic",
          templateData: { message: smsText },
          queueTime: queueTime?.toISOString(),
          scheduledTime: scheduledTime?.toISOString()
        }
      }
    });
  };

  return (
    <div>
      <Tooltip
        className={classes.tooltip}
        placement="top"
        title="Send SMS"
        aria-label="send-sms"
        onClick={handleOpen}
      >
        <Button
          {...(record && !record.phoneNumber && { disabled: true })}
          size="small"
          startIcon={<SmsIcon />}
        >
          Send SMS
        </Button>
      </Tooltip>
      {open && (
        <Dialog
          open={true}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
            Send SMS
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {createResult ? (
                <Grid item xs={12}>
                  <Alert
                    severity="success"
                    action={
                      <RAButton
                        component={Link}
                        to={
                          getResourceLinkPath({
                            basePath: "/scheduled-notification",
                            link: "show",
                            reference: "scheduled-notification",
                            record: createResult,
                            resource: "scheduled-notification",
                            source: "id"
                          }) || ""
                        }
                        label="View"
                        onClick={e => e.stopPropagation()}
                      >
                        <ImageEye />
                      </RAButton>
                    }
                  >
                    <AlertTitle>Success</AlertTitle>
                    <Typography>
                      SMS has been scheduled for delivery.
                    </Typography>
                  </Alert>
                </Grid>
              ) : (
                <>
                  {previewLoading ? (
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                  ) : preview ? (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h5">Filters</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {JSON.stringify(
                            listFilterContext.filterValues,
                            null,
                            2
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Stats</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" align="center">
                          {preview.total}
                        </Typography>
                        <Typography variant="body1" align="center">
                          Total Users
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" align="center">
                          {preview.optedOut}
                        </Typography>
                        <Typography variant="body1" align="center">
                          SMS Opt-Out
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" align="center">
                          {preview.total - preview.optedOut}
                        </Typography>
                        <Typography variant="body1" align="center">
                          Total To Send
                        </Typography>
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={12}>
                    <Typography variant="h5">SMS</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MUITextField
                      label="Campaign Name"
                      variant="filled"
                      onChange={onChangeNameText}
                      fullWidth
                      inputProps={{
                        maxLength: 255,
                        placeholder: "e.g. Black Friday SMS Campaign"
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        clearable
                        label="Scheduled Time"
                        value={
                          scheduledTime ? scheduledTime.toISOString() : null
                        }
                        onChange={onChangeScheduledTime}
                        disablePast={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <MUITextField
                      label="Message"
                      variant="filled"
                      onChange={onChangeSMSText}
                      fullWidth
                      multiline
                      rows={4}
                      rowsMax={4}
                      inputProps={{
                        placeholder: ""
                      }}
                    />
                    <Typography variant="body2">
                      {smsText.length} /{" "}
                      {getNextSmsTextBoundary(smsText.length)}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={loading} color="primary">
              {createResult ? "Close" : "Cancel"}
            </Button>
            {!createResult ? (
              <Button onClick={onSend} disabled={!canSendSms} color="primary">
                Send
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default SendSmsToProfileListButton;
