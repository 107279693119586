export type LandingPage = {
  id: string;
  title: string;
  activeDate: Date;
  expiryDate: Date;
  embedUrl: string;
  showForMemberSegments: string[] | null;
};

export type InboxNotification = {
  id: string;
  title: string;
  message: string;
  activeDate: Date;
  expiryDate: Date;
  imageUrl: string | undefined;
  imageKey: string | undefined;
  linkUrl: string | undefined;
  tag: string | undefined;
  showForMemberSegments: string | undefined;
  videoId: string | undefined;
  videoReferenceId: string | undefined;
};

export enum NotificationLogType {
  Email = "email",
  Sms = "sms",
  Push = "push"
}

export enum NotificationLogStatus {
  Pending = "pending",
  Sent = "sent",
  Opened = "opened",
  Failed = "failed",
  Bounced = "bounced",
  Complaint = "complaint"
}

export type NotificationLogTemplateData = { [key: string]: string };
export interface NotificationLog {
  id: string;
  type: NotificationLogType;
  userId: number;
  providerId?: string;
  template: string;
  templateData: NotificationLogTemplateData;
  status: NotificationLogStatus;
  failureReason?: string;
  scheduledTime: Date;
  sentTime?: Date;
  created: Date;
  updated: Date;
}

export interface ScheduledNotification {
  id: string;
  type: NotificationLogType;
  userProfileSegmentId: number;
  template: string;
  templateData: NotificationLogTemplateData;
  status: NotificationLogStatus;
  failureReason?: string;
  queueTime: Date;
  scheduledTime: Date;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
}

export interface NotificationTemplate {
  id: string;
  types: NotificationLogType[];
  templateDataFields: string[];
}

export interface NotificationPreference {
  userId: number;
  template: string;
  allowedEmail: boolean;
  allowedSms: boolean;
  allowedPush: boolean;
  created: Date;
  updated: Date;
}
