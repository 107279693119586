import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  TextInput,
  DateField,
  Filter,
  FieldProps,
  ReferenceField
} from "react-admin";
import icon from "@material-ui/icons/Notifications";
import ThumbnailField from "../../components/fields/ThumbnailField";
import StringArrayChipsField from "../../components/fields/StringArrayChipsField";
import { InboxNotification } from "../../entities/Notification";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core/";
import moment from "moment";

const InboxNotificationFilter = (props: {}) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" alwaysOn />
  </Filter>
);

const CreateScheduledNotificationForInboxNotificationButton = (
  props: FieldProps<InboxNotification>
) => {
  const history = useHistory();

  if (!props.record) return null;

  const { title, message, activeDate } = props.record;

  const onClick = () => {
    const scheduledTime = moment(activeDate).toISOString();
    const queueTime = moment(scheduledTime)
      .subtract(15, "minutes")
      .toISOString();

    history.push(
      `/scheduled-notification/create?source=${JSON.stringify({
        type: "push",
        template: "youtube",
        templateData: {
          title: title
            .replace(" | FarmFLiX", "")
            .replace("&", "and")
            .replace(/[^a-zA-Z0-9 -\?\|]/gi, ""),
          message: message
            .replace(" | FarmFLiX", "")
            .replace("&", "and")
            .replace(/[^a-zA-Z0-9 -\?\|]/gi, ""),
          link: "farmflix://notifications"
        },
        scheduledTime,
        queueTime
      })}`
    );
  };

  return <Button onClick={onClick}>Schedule Push</Button>;
};

export const InboxNotificationList = (props: {}) => (
  <List
    filters={<InboxNotificationFilter />}
    sort={{ field: "activeDate", order: "DESC" }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <ThumbnailField source="imageUrl" label="Image" />
      <ReferenceField label="Embedded Video" source="videoId" reference="video">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Embedded Video Thumb"
        source="videoId"
        reference="video"
      >
        <ThumbnailField source="thumbnailThumb" label="Embedded Video Thumb" />
      </ReferenceField>
      <TextField source="title" />
      <TextField source="message" />
      <TextField source="clickEvent" />
      <DateField showTime={true} source="activeDate" />
      <DateField showTime={true} source="expiryDate" />
      <StringArrayChipsField
        label={"Member Statuses"}
        source={"showForMemberStatuses"}
        emptyValue={"All"}
      />
      <CreateScheduledNotificationForInboxNotificationButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const InboxNotificationIcon = icon;
