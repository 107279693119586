import React from "react";
import { required, TextInput, useGetMany } from "react-admin";
import { useFormState } from "react-final-form";
import { Box } from "@material-ui/core/";

const TemplateDataInput = () => {
  const {
    values: { template }
  } = useFormState();
  const { data, loading } = useGetMany("notification-template", [template], {
    enabled: !!template
  });

  if (loading || !data || !data[0]?.templateDataFields) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column">
      {data[0].templateDataFields.map((field: string) => (
        <TextInput
          key={field}
          validate={required()}
          source={`templateData.${field}`}
          label={field}
        />
      ))}
    </Box>
  );
};

export default TemplateDataInput;
