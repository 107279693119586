import React, { useEffect, useState } from "react";
import {
  TextInput,
  SelectInput,
  NumberInput,
  TextInputProps,
  InputProps,
  NumberInputProps,
  SelectInputProps,
  ChoicesInputProps,
  AutocompleteInputProps,
  AutocompleteInput,
  SelectArrayInput,
  SelectArrayInputProps
} from "react-admin";
import { PositiveFilters } from "@booyaltd/core";
import DateInput, { DateInputProps } from "../inputs/DateInput";
import { useField } from "react-final-form";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dayjs, { Dayjs } from "dayjs";
import { Box, Typography } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

type FilterInputProps<
  Props extends InputProps | ChoicesInputProps = InputProps
> = Props & {
  not?: boolean;
};

const buildSource = (
  { source, not }: Pick<FilterInputProps, "source" | "not">,
  filter: PositiveFilters
) => `${source}${not ? "__not" : ""}${filter}`;

export const TextEqualsFilter = (props: FilterInputProps<TextInputProps>) => (
  <TextInput {...props} source={buildSource(props, "")} />
);

export const TextContainsFilter = (props: FilterInputProps<TextInputProps>) => (
  <TextInput {...props} source={buildSource(props, "__contains")} />
);

export const TextStartsWithFilter = (
  props: FilterInputProps<TextInputProps>
) => <TextInput {...props} source={buildSource(props, "__startswith")} />;

export const TextEndsWithFilter = (props: FilterInputProps<TextInputProps>) => (
  <TextInput {...props} source={buildSource(props, "__endswith")} />
);

export const DateGreaterThanFilter = (
  props: FilterInputProps<DateInputProps>
) => <DateInput {...props} source={buildSource(props, "__gt")} />;

export const DateLessThanFilter = (props: FilterInputProps<DateInputProps>) => (
  <DateInput {...props} source={buildSource(props, "__lt")} />
);

export const DateBetweenFilter = (props: {
  source: string;
  label: string;
  not?: boolean;
}) => {
  const [lower, setLower] = useState<Dayjs>();
  const [upper, setUpper] = useState<Dayjs>();

  const {
    input: { onChange, value }
  } = useField<string>(buildSource(props, "__between"));

  const onChangeLower = (val: MaterialUiPickersDate) => {
    setLower(val ? dayjs(val.toISOString()) : undefined);
  };

  const onChangeUpper = (val: MaterialUiPickersDate) => {
    setUpper(val ? dayjs(val.toISOString()) : undefined);
  };

  useEffect(() => {
    if (!lower || !upper) {
      onChange(undefined);
    } else {
      onChange(`${lower.toISOString()},${upper.toISOString()}`);
    }
  }, [lower, upper]);

  useEffect(() => {
    if (value) {
      const [initLower, initUpper] = value.split(",").map(v => dayjs(v));
      if (initLower && initUpper && !lower && !upper) {
        setLower(initLower);
        setUpper(initUpper);
      }
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box display="flex" flexDirection="column">
        <Typography variant="caption">{props.label}</Typography>
        <Box display="flex" flexDirection="row">
          <DateTimePicker
            clearable
            label=""
            value={lower ? lower : null}
            emptyLabel="From"
            onChange={onChangeLower}
          />

          <DateTimePicker
            clearable
            label=""
            value={upper ? upper : null}
            emptyLabel="To"
            onChange={onChangeUpper}
          />
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export const NumberEqualsFilter = (
  props: FilterInputProps<NumberInputProps>
) => <NumberInput {...props} source={buildSource(props, "")} />;

export const NumberGreaterFilter = (
  props: FilterInputProps<NumberInputProps>
) => <NumberInput {...props} source={buildSource(props, "__gt")} />;

export const NumberLessFilter = (props: FilterInputProps<NumberInputProps>) => (
  <NumberInput {...props} source={buildSource(props, "__lt")} />
);

export const NumberGreaterEqualFilter = (
  props: FilterInputProps<NumberInputProps>
) => <NumberInput {...props} source={buildSource(props, "__gte")} />;

export const NumberLessEqualFilter = (
  props: FilterInputProps<NumberInputProps>
) => <NumberInput {...props} source={buildSource(props, "__lte")} />;

export const SelectEqualsFilter = (
  props: FilterInputProps<SelectInputProps & { source: string }>
) => <SelectInput {...props} source={buildSource(props, "")} />;

export const SelectContainsFilter = (
  props: FilterInputProps<SelectInputProps & { source: string }>
) => <SelectInput {...props} source={buildSource(props, "__contains")} />;

export const AutocompleteContainsFilter = (
  props: FilterInputProps<AutocompleteInputProps & { source: string }>
) => <AutocompleteInput {...props} source={buildSource(props, "__contains")} />;

export const SelectArrayInputInFilter = (
  props: FilterInputProps<SelectArrayInputProps & { source: string }>
) => <SelectArrayInput {...props} source={buildSource(props, "__in")} />;
