import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { ReferenceInput, SelectInput, TextInput } from "react-admin";

const TagCreateSelectInput = () => {
  const [customTag, setCustomTag] = useState<boolean>(false);
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="row"
      alignContent="center"
      alignItems="center"
    >
      {customTag ? (
        <TextInput source="tag" fullWidth={true} />
      ) : (
        <>
          <Box flex={1}>
            <ReferenceInput
              label="Display videos with tag"
              source="tag"
              reference="tag"
              filter={{ type: "video" }}
              style={{ width: "100%" }}
            >
              <SelectInput
                style={{ width: "100%" }}
                onCreate={(args: any) => {
                  console.log("onCreate", ...args);
                }}
                optionText="tag"
                optionValue="tag"
              />
            </ReferenceInput>
          </Box>
          <Box p={2} pt={0} flex={0}>
            <Button
              onClick={() => setCustomTag(true)}
              variant="outlined"
              size="small"
            >
              New Tag
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TagCreateSelectInput;
