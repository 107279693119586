import DataProviderBase from "./IDataProvider";
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteManyParams,
  DeleteManyResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  UpdateManyParams,
  UpdateManyResult,
  Identifier
} from "react-admin";
import { Giftcard } from "../../entities/Giftcard";
import Provider from "../Provider";

export default class GiftcardDataProvider extends Provider
  implements DataProviderBase<Giftcard> {
  buildUrl(id?: Identifier): string {
    return `/giftcards${id ? `/${id}` : ""}`;
  }

  async getList(params: GetListParams): Promise<GetListResult<Giftcard>> {
    return this.axios
      .get(this.buildUrl(), {
        params: {
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          limit: params.pagination.perPage,
          sort: `${params.sort.field} ${params.sort.order}`,
          ...params.filter
        }
      })
      .then(response => ({
        data: response.data.results,
        total: response.data.total
      }));
  }

  async getMany(params: GetManyParams): Promise<GetManyResult<Giftcard>> {
    return this.axios
      .get(this.buildUrl(), {
        params: {
          giftcard: params.ids
        }
      })
      .then(response => ({
        data: response.data.results,
        total: response.data.total
      }));
  }

  async updateMany(params: UpdateManyParams): Promise<UpdateManyResult> {
    return this.axios
      .get(this.buildUrl(), {
        params: {
          giftcard: params.ids
        }
      })
      .then(response => ({
        data: response.data.results,
        total: response.data.total
      }));
  }

  async deleteMany(params: DeleteManyParams): Promise<DeleteManyResult> {
    return this.axios
      .delete(this.buildUrl(), {
        params: {
          giftcard: params.ids
        }
      })
      .then(response => ({
        data: response.data.results,
        total: response.data.total
      }));
  }

  async getOne(params: GetOneParams): Promise<GetOneResult<Giftcard>> {
    return this.axios
      .get<{ results: Giftcard[] }>(this.buildUrl(), {
        params: {
          id: params.id
        }
      })
      .then(response => ({ data: response.data.results[0] }));
  }

  update(params: UpdateParams<Giftcard>): Promise<UpdateResult<Giftcard>> {
    return this.axios
      .put(this.buildUrl(params.id), params.data)
      .then(response => ({ data: response.data }));
  }

  create(params: CreateParams<Giftcard>): Promise<CreateResult<Giftcard>> {
    return this.axios
      .post(this.buildUrl(), params.data)
      .then(response => ({ data: response.data }));
  }

  delete(params: DeleteParams): Promise<any> {
    return this.axios.delete(this.buildUrl(params.id));
  }
}
