/* eslint-disable react/display-name */
import React from "react";
import {
  GridRenderCellParams,
  GridRenderEditCellParams
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";

// is valid date
const isValidDate = (date: MaterialUiPickersDate) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const renderDateEdit = (params: GridRenderEditCellParams) => {
  const field = params.field;

  const onChange = (date: MaterialUiPickersDate) => {
    if (!isValidDate(date)) {
      params.api.setEditCellValue({
        id: params.id,
        field,
        value: null
      });
      return;
    }

    const changedDateStr = dayjs(date)
      .startOf("day")
      .toDate()
      .toISOString();
    params.api.setEditCellValue({
      id: params.id,
      field,
      value: changedDateStr
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={params.row[field]}
        onChange={onChange}
        clearable={true}
        format="dd/MM/yyyy"
      />
    </MuiPickersUtilsProvider>
  );
};

export const renderDateView = (params: GridRenderCellParams) => {
  const dateStr = params.row[params.field];

  if (!dateStr) return "";

  return dayjs(dateStr).format("DD/MM/YYYY");
};
